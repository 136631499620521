export const brandPalette = {
  Primary: {
    50: "#e1f5ff",
    100: "#b2e5ff",
    200: "#7ed4ff",
    300: "#47c3ff",
    400: "#14b6ff",
    500: "#00a8ff",
    600: "#009af0",
    700: "#0087db",
    800: "#0076c7",
    900: "#0055a5",
  },
  Secondary: {
    50: "#e4e9ef",
    100: "#bbc7d8",
    200: "#90a2bd",
    300: "#667fa2",
    400: "#446591",
    500: "#1c4c82",
    600: "#134579",
    700: "#073c6e",
    800: "#003362",
    900: "#00234b",
  },
  Complementary: {
    50: "#f8eae6",
    100: "#f6d0b9",
    200: "#f0b28c",
    300: "#ea975d",
    400: "#e68338",
    500: "#e27207",
    600: "#d86b03",
    700: "#ca6400",
    800: "#bd5d00",
    900: "#a55000",
  },
  "Analogous-1": {
    50: "#dff2f3",
    100: "#b0dedf",
    200: "#7bcaca",
    300: "#42b5b4",
    400: "#00a5a2",
    500: "#009590",
    600: "#008883",
    700: "#007872",
    800: "#006862",
    900: "#004c45",
  },
  "Analogous-2": {
    50: "#ede5f8",
    100: "#d0c0ed",
    200: "#b096e1",
    300: "#906bd7",
    400: "#774ace",
    500: "#5c28c5",
    600: "#5223bf",
    700: "#421bb7",
    800: "#3114b0",
    900: "#0003a5",
  },
  "Triadic-1": {
    50: "#f5e5f7",
    100: "#e6beed",
    200: "#d792e2",
    300: "#c664d6",
    400: "#b93fcd",
    500: "#ab0cc3",
    600: "#9c0cbd",
    700: "#8707b6",
    800: "#7404af",
    900: "#5000a5",
  },
  "Triadic-2": {
    50: "#fbe3ec",
    100: "#f5b9d0",
    200: "#ef8bb0",
    300: "#e95c91",
    400: "#e43779",
    500: "#e10162",
    600: "#d0015f",
    700: "#ba025a",
    800: "#a50055",
    900: "#80004d",
  },
};

export const brand = {
  typography: {
    fontSize: 16,
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
    h1: {
      fontFamily: "poppins",
      fontWeight: "700",
      fontSize: "2rem",
      letterSpacing: "-0.16px",
      lineHeight: "1.09375",
      display: "block",
      marginTop: "0",
      marginBottom: "30px",
    },
    h2: {
      fontFamily: "poppins",
      fontWeight: "700",
      fontSize: "1.5rem",
      letterSpacing: "-0.16px",
      lineHeight: "1.04167",
      display: "block",
      marginTop: "0",
      marginBottom: "20px",
    },
    h3: {
      fontFamily: "poppins",
      fontWeight: "700",
      fontSize: "1.125rem",
      letterSpacing: "-0.16px",
      lineHeight: "1.11111",
      display: "block",
      marginTop: "0",
      marginBottom: "12px",
    },
    h4: { fontFamily: "poppins" },
    h5: {},
    h6: {},
    subtitle1: {},
    subtitle2: { fontFamily: "poppins" },
    body1: {},
    body2: {},
    button: { textTransform: "none" },
    caption: {},
    overline: {},
  },
  palette: {
    primary: brandPalette.Primary[900],
    primaryTint: brandPalette.Primary,
    secondaryTint: brandPalette.Secondary,
    complementaryTint: brandPalette.Complementary,
    secondary: "#d4af36",
    error: brandPalette["Triadic-2"][500],
    warning: brandPalette.Complementary[400],
    desaturated: brandPalette.Secondary[50],
    info: brandPalette.Primary[100],
    success: brandPalette["Analogous-1"][400],
  },
  image: {
    logoLarge: "url",
    logoSmall: "url",
    heroBanner: "url",
  },
  icon: {
    favIcon: "url",
    homescreenIcon: "url",
  },
  breakpoints: {
    lg: 1280,
  },
};
