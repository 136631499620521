export const createChannelPayload = (
  contactID,
  addressType,
  formattedFormData
) => ({
  recordtype: "ctpt_channels",
  data: [
    {
      name: "ctpt_methodtype",
      type: "picklist",
      value: addressType, // Address type id
    },
    {
      name: "ctpt_CustomerId_contact",
      value: contactID,
      type: "lookup",
      relatedentity: "contacts",
    },
    {
      name: "ctpt_mapaddressto",
      type: "picklist",
      value: addressType === 727530001 ? 727530000 : 727530003,
    },
    {
      name: "ctpt_addresstype",
      value: addressType,
      type: "picklist",
    },
    ...formattedFormData,
  ],
});
