import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    "& > *": {
      margin: theme.spacing(1),
    },
    "& .statusTblheader": {
      backgroundColor: "rgba(255, 7, 0, 0.55)",
    },
  },
  container: {
    minHeight: "60rem",
    padding: "3.5rem 2rem",

    "& div[class^=MuiTabs-flexContainer]": {
      margin: "0 5rem 3rem 1rem",
    },
    [theme.breakpoints.down("xs")]: {
      padding: "3rem 10px",
      "& div[class^=MuiTabs-flexContainer]": {
        margin: 0,
      },
    },
  },
  background1: {
    backgroundColor: "#0055a5",
    position: "absolute",
    left: 0,
    top: 0,
    width: "100%",
    height: "15rem",
    zIndex: 0,
    borderBottom: "3px solid",
    borderColor: theme.palette.primary.dark,
  },
  snackbarSuccess: {
    backgroundColor: theme.palette.success.main,
  },
  statusTblheader: {
    backgroundColor: "rgba(255, 7, 0, 0.55)",
  },
  snackbarError: {
    backgroundColor: theme.palette.error.main,
  },
  listSection: {
    marginTop: "20px",
  },
  formList: {
    marginTop: "20px",
  },
  formLink: {
    textDecoration: "none",
    color: theme.palette.primary.dark,
  },
  h2heading: {
    fontSize: "15px",
    fontWeight: "bold",
  },
  primaryBtn: {
    display: "inline-block",
    border: "1px solid #000",
    marginRight: "1rem",
  },
  listContent: {
    paddingBottom: "50px",
  },
}));
