import React, { useEffect, useState } from "react";
import { Grid } from "@material-ui/core";
import { useSelector, useDispatch } from "react-redux";
import { contactSchema } from "app/components/mvp/formWidget/model/contactJson";
import { contactUiSchema } from "app/components/uiSchema/contactUiSchema";
import { ContactPayload } from "app/services/payloadSubmit/contactPayload";
import {
    getlistPayload
  } from "data/payload/contactInfoPayload";
import { config } from "utilities/config";
import { post } from "app/services/api";
import ListComponent from "app/components/mvp/listWidget";
import { TELEPHONE_TYPE_MAP } from "./model/constants";
import { Button } from "@material-ui/core";

function ContactInformation(props) {
  const userEntitlement = useSelector((state) => state.entitleMents);
  const userRole = useSelector((state) => state.role);
  let permissions = props.permissions;
  const contactid = useSelector((state) => state.contactid);
  const [contactFormData, setContactFormData] = useState({});
  const dispatch = useDispatch();


  //const classes = useStyles();
  const getcontactId = useSelector((state) => state.contactid);
  //const history = useHistory();

  const updateDetails = (params) => {
    window.location.href = "/updatecontact?id=" + params.row.ctpt_channelid;
  };
 
  const columns = [
    // { field: "_ctpt_contactid_value", flex: 1, headerName: " ", hide: true },
    // // NOTE: KeyVault field to disable edit. if ctpt_read_only is true then evidence's cant't be added or removed.
    // { field: "ctpt_read_only", flex: 1, headerName: " ", hide: true },
    // { field: "ctpt_memberdeclarationid", flex: 1, headerName: " ", hide: true },
    // { field: "ctpt_selectedforreview", flex: 1, headerName: " ", hide: true },
    { field: "ctpt_channelid", flex: 1, headerName: " ", hide: true },
    {
        field: "telephoneType",
        flex: 1,
        headerName: "Type",
        renderCell: (params) => (
          <p>{TELEPHONE_TYPE_MAP[params.row.ctpt_telephonetype][params.row.ctpt_maptelephoneto]}</p>
        ),
      },
    { field: "ctpt_telephone", flex: 2, headerName: "Telephone Number" },
    {
        field: "action",
        flex: 1,
        headerName: <p>Action</p>,
        excludeFromSearch: true,
        renderCell: (params) => (
          <div>
            <Button
              variant="outlined"
              color="primary"
              className="primaryBtn"
              onClick={() => {
                updateDetails(params);
              }}
            >
              Update Details
            </Button>
          </div>
        ),
      },
    
    
    // {
    //   field: "action",
    //   headerName: <p class={classes.paidListHeader}>Action</p>,
    //   flex: 1,
    //   renderCell: (params) => {
    //     return (
    //       <div class={classes.actionsButtons}>
    //         <Button
    //           variant="outlined"
    //           color="primary"
    //           className="primaryBtn"
    //           //disabled={false}
    //           disabled={params.row && params.row.ctpt_declarationperiodstartdate && params.row.ctpt_declarationperiodenddate && (new Date(params.row.ctpt_declarationperiodstartdate) < new Date(startDate)) || (new Date(params.row.ctpt_declarationperiodenddate) > new Date(endDate))}
    //           //disabled={(new Date(params.row.ctpt_declarationperiodenddate) > new Date(endDate))}
    //           onClick={() => {
    //             onClickViewDetails(params);
    //           }}
    //         >
    //           View Details
    //         </Button>
    //       </div>
    //     );
    //   },
    // },
  ];

  const options = {
    apiURL: config.teleportal.file.list,
    apiUrlDel: config.teleportal.file.delete,
    columns: columns,
    searchPayload: "searchFilter",
    getlistPayload: getlistPayload(contactid),
    deleteListPayload: {},
    listHeading: "Contact Information",
    formWidget: {},
    nonRecord: false,
    rows: "",
    localDataStore: false,
    hideDelete: true,
    hasAggregate: false,
    staticTable: false,
    staticRow: [],
    hiddenColumn: {},
    contactId: getcontactId,
    filteredValue: "",
    hasFilter: false,
    filterField: "",
    filterValue: [],
    aggregateField: "",
    hideSearch: true,
    pageSize: 6,
    hasPayloadfilter: true,
    hideAdd: true,
    controlLists: false,
  };


  

  return (
    <Grid container spacing={0} justify="center">
      <Grid item xs={12} md={9}>
        <ListComponent
          options={options}
        //   updateFormData={updateFormData}
        //   successResponse={getResponse}
        />
      </Grid>
    </Grid>
  );
}

export default ContactInformation;
