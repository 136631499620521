//Import React Library
import React, { useState } from "react";

import { settings } from "constants/settings";

async function loadComponent() {
  return import("./CustomButton." + settings.UI + ".js");
}

function CustomButton(props) {
  const { children, data, variants, noMethod, btnOptions, onBtnupdate } = props;

  const [component, setComponent] = useState(null);

  loadComponent().then((x) => setComponent(x));

  if (!component) return null;
  return (
    <component.default
      data={data}
      variants={variants}
      noMethod={noMethod}
      btnOptions={btnOptions}
      onBtnupdate={onBtnupdate}
    >
      {children}
    </component.default>
  );
}

export default CustomButton;
