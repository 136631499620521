import React from "react";
import { Grid } from "@material-ui/core";
import { ThemeProvider } from "@material-ui/core/styles";
import { globalTheme } from "style/globalTheme";
import styled from "styled-components";
import { brandPalette } from "brand/theme/brandTheme";

const StartPage = styled.section`
  ${globalTheme.breakpoints.up("sm")} {
    padding: 2rem 1rem 1rem 1rem;
  }
  ${globalTheme.breakpoints.down("xs")} {
    padding: 1rem;
  }
  height: 65rem;
`;

const Heading = styled.div`
  height: 15rem;
  padding: 1rem 1.2rem;
  background-image: linear-gradient(
      to right bottom,
      ${brandPalette["Complementary"][200]},
      ${brandPalette["Analogous-1"][600]}
    ),
    url(brand/slide4.jpeg);
  //background-blend-mode: screen;
  clip-path: polygon(0 0, 100% 0, 100% 80%, 0% 100%);

  h2 {
    display: inline-block;
    justify-content: flex-start;
    margin: 5.7rem 15.6rem;
    font-size: 2rem;
    letter-spacing: 5px !important;
    text-transform: uppercase;
    font-weight: 700;
    letter-spacing: 0.07rem;
    padding: 0.5rem;
    border-radius: 8px;
    background-image: linear-gradient(
      to right,
      rgba(60, 60, 60, 0.5),
      rgba(0, 28, 48, 0.5)
    );
    //-webkit-background-clip: text;
    color: white;
  }

  ${globalTheme.breakpoints.down("sm")} {
    position: relative;
    width: 100%;
    h2 {
      margin: 0;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
    }
  }

  ${globalTheme.breakpoints.down("xs")} {
    display: inline-block;
    justify-content: center;
    margin: 0;
    padding: 0rem;
    position: relative;
    width: 100%;

    h2 {
      margin: 0;
      padding: 0.5rem;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
    }
  }
}
`;

function Notifications() {
  return (
    <ThemeProvider theme={globalTheme}>
      <Heading>
        <h2>Notifications</h2>
      </Heading>
      <StartPage>
        <Grid container spacing={0}>
          <Grid item xs={12} md={12}>
            <h3>The notification widget needs to be created</h3>
          </Grid>
        </Grid>
      </StartPage>
    </ThemeProvider>
  );
}

export default Notifications;
