import React from "react";
import { BrowserRouter as Router } from "react-router-dom";
import { ThemeProvider } from "@material-ui/core/styles";
import { globalTheme } from "./style/globalTheme";
import "theme/css-vars.css";
import PageLayout from "./app/pages/pagelayout";
import Pages from "./router";
import PageProperties from "utilities/pageProperties";
import CookieComponent from "app/components/atoms/cookie";
import PreLoader from "app/components/atoms/PreLoader";
import { UserProvider } from "./utilities/UserContext";



function App() {
  
  return (
    <UserProvider>
      <Router>
        <ThemeProvider theme={globalTheme}>
          <PageProperties />
          <CookieComponent />
          <PreLoader />
          <PageLayout>
            <Pages />
          </PageLayout>
        </ThemeProvider>
      </Router>
    </UserProvider>
  );
}

export default App;
