export const getStudentSchema = (getcontactId)=>({
  title: "Service Request ",
  logicalname: "Contact2",
  type: "object",
  properties: {
    ctpt_casetypeid: {
      title: "Type of Request",
      type: "string",
      schemaname: "ctpt_casetypeid",
      datatype: "string",
      isDynamic: true,
      helpText: "",
      apiURL: "/api/GetAllAsync",
      payload: {
        properties: ["ctpt_name", "ctpt_casetypeid"],
        filter: {
          globalfilter: "",
          globalAndOperator: false,
          fieldAndOperator: false,
          filterfields: [],
        },
        sortfield: {
          field: "ctpt_name",
          sorttype: "asc"
      },
        Aggregate: {
          AggregateFields: [],
        },
        recordtype: "ctpt_casetypes",
        idfield: "ctpt_casetypeid",
        continuationToken: "",
        pazesize: "1000",
      },
      enum: [],
      enumNames: [],
    },
    ctpt_title: {
      title: "Title",
      type: "string",
      schemaname: "ctpt_title",
      datatype: "string",
    },
    ctpt_details: {
      title: "Details",
      type: "string",
      schemaname: "ctpt_details",
      datatype: "string",
      ismultiline: true,
      multilineRow: 4,
    },
    ctpt_documenturl: {
      title: "Attachment",
      type: "string",
      schemaname: "ctpt_documenturl",
      datatype: "upload",
      helpText: "",
      tootltipheading: "",
      requiredCheck: false,
      format: "string",
      containerName: "channels",
      fileName: "studentRegister",
      acceptExtension: [
        ".pdf",
        ".doc",
        ".docx",
        ".png",
        ".jpeg",
        ".xlsx",
        ".xls",
        ".csv",
      ],
      acceptType: [
        "application/pdf",
        "application/msword",
        "image/png",
        "image/jpeg",
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        "application/vnd.ms-excel",
        "application/csv",
      ],
      recordPath:`${getcontactId}/`,
      requireBoburl: true,
    },
  },
});

export const getDocumentSchema = {
  title: " ",
  logicalname: "Contact2",
  type: "object",
  properties: {
    ctpt_name: {
      title: "Name",
      type: "string",
      schemaname: "ctpt_customerid",
      datatype: "string",
      isDynamic: false,
    },
    ctpt_documenttype: {
      title: "Document Type",
      type: "string",
      schemaname: "ctpt_documenttype",
      datatype: "string",
    },
    ctpt_case: {
      title: "Case",
      type: "string",
      schemaname: "ctpt_case",
      datatype: "string",
    },
    ctpt_documenturl: {
      title: "Student attendance register",
      type: "string",
      schemaname: "ctpt_documenturl",
      datatype: "string",
      requireBoburl: true,
    },
  },
};
