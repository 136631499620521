export const getlistPayload =(contactid)=> ({
    properties: ["ctpt_telephone",
    "ctpt_telephonetype",
    "ctpt_maptelephoneto",
    "ctpt_channelid"],
    formattedFields: [  
    ],
    recordType: "ctpt_channels",
    // filters: [
    //   {
    //     field: "_ctpt_contactid_value",
    //     searchvalue: contactid,
    //     type: "guid"
    //   }
    // ],
    formattedFields: ["ctpt_methodtype"],
    filter: {
        globalfilter: "",
        globalAndOperator: false,
        fieldAndOperator: true,
        filterfields: [
            {
                field: "ctpt_methodtype",
                searchvalue: "727530002",
                type: "guid"
            },
            {
                field: "_ctpt_customerid_value",
                searchvalue: contactid,
                type: "guid"
            }
            
        ]
    },
    Aggregate: {
        AggregateFields: []
    },
    idfield: "ctpt_channelid",
    continuationToken: "",
    pagesize: "10"
});

export const getrecordPayload = {
    properties: [
      "ctpt_telephone"
    ],
    formattedFields: [],
    recordType: "ctpt_channels",
    id: "",
  };

export const updateContactPayload = (JsonData) => {
    const details = JsonData.data;
    var payload;
    payload = {
      id: JsonData.recordId,
      recordtype: JsonData.recordtype,
      data: [
        {
          name: "ctpt_telephone",
          value: details[0].value,
          type: "string",
        },
      ],
    };
    return payload;
  };

  export const getIdentityListPayload =(contactid)=> ({
    properties: ["ctpt_name",
    "ctpt_startdate",
    "ctpt_enddate",
    "ctpt_identitynumber",
    "ctpt_identityid",
    "ctpt_type"
  ],
    formattedFields: [  
    ],
    recordType: "ctpt_identities",
    // filters: [
    //   {
    //     field: "_ctpt_contactid_value",
    //     searchvalue: contactid,
    //     type: "guid"
    //   }
    // ],
    formattedFields: [],
    filter: {
        globalfilter: "",
        globalAndOperator: false,
        fieldAndOperator: true,
        filterfields: [
            
            {
                field: "_ctpt_contactid_value",
                searchvalue: contactid,
                type: "guid"
            }
            
        ]
    },
    Aggregate: {
        AggregateFields: []
    },
    idfield: "ctpt_identityid",
    continuationToken: "",
    pagesize: "10"
});

export const getidentityrecordPayload = {
  properties: [
    "ctpt_type",
    "ctpt_identitynumber"
  ],
  formattedFields: [],
  recordType: "ctpt_identities",
  id: "",
};

export const updateIdentityPayload = (JsonData) => {
  const details = JsonData.data;
  var payload;
  payload = {
    id: JsonData.recordId,
    recordtype: JsonData.recordtype,
    data: [
      {
        name: "ctpt_identitynumber",
        value: details[1].value,
        type: "string",
      },
    ],
  };
  return payload;
};