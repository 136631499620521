import { post } from "app/services/api";
import { config } from "utilities/config";
import {
  getContactDetailsAPIPayload,
  getConfig,
} from "./model/contactDetailsPayload";
import { MEMBERSHIP_TYPE_MAP } from "./model/constants";
import moment from "moment";

const fetchContactDetailsAPI = config.teleportal.submitForm.getId;

const formatResponseObject = (response) => {
  const formattedResponse = {};

  response ?.forEach(({ name, value }) => {
    if (name) {
      formattedResponse[name] = value;
    }
  });

  return formattedResponse;
};

export const getContactDetails = async (contactId) => {
  try {
    const { data: response } = await post(
      fetchContactDetailsAPI,
      getContactDetailsAPIPayload(contactId)
    );
    const contactDetails = formatResponseObject(response ?.data);

    return {
      email: contactDetails.emailaddress1,
      mobile: contactDetails.mobilephone,
      postalAddress: contactDetails.address1_composite,
      membershipType: MEMBERSHIP_TYPE_MAP[contactDetails.ctpt_membershipclass],
      memberSince:
        contactDetails.ctpt_lastapplicationapprovedon &&
        moment(contactDetails.ctpt_lastapplicationapprovedon).format(
          "MMM Do YYYY"
        ),
      renewalDue: `31st Dec ${new Date().getFullYear()}`,
      memberRenewalDue: `31st Dec ${new Date().getFullYear()}`,
      accountStatus: contactDetails.statecode === "1" ? "Inactive" : "Active",
      profileupdateDate: contactDetails.ctpt_profiledetailsconfirmedaccurateon,
      membershipId:contactDetails.ctpt_membershipnumber,
      fullName:contactDetails.fullname
    };
  } catch (err) {
    console.log(err);
    return {};
  }
};

export const getConigitems = () => {
  let value;
  post(config.teleportal.submitForm.getId, getConfig)
    .then((res) => {
      value = res.data.data[0].value;
      console.log("value--" + res.data.data[0].value);
    })
    .catch(console.log);

  return value;
};
