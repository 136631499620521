import React, { useEffect, useRef, useState } from "react";
import {
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
} from "@material-ui/core";
import { useSelector, useDispatch } from "react-redux";
import { ContactPayload } from "app/services/payloadSubmit/contactPayload";
import { config } from "utilities/config";
import { post } from "app/services/api";
import FormWidget from "app/components/mvp/formWidget";
import { useLocation } from "react-router-dom";
import { studentSchema } from "./model/studentSchema";
import { studentUiSchema } from "./model/UiSchema";
import { getSupportingDocPayload } from "app/components/molecules/profile/model/payload";
import { formatData } from "utilities/datatable";
import "./styles.css";
import { Button } from "@material-ui/core";
import { updateStudentPlacementStatus } from "./model/payload";
import { usePreLoader } from "app/services/hooks/usePreLoader";

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

function PlacementDetails(props) {
  const [contactFormData, setContactFormData] = useState({});
  const dispatch = useDispatch();
  const query = useQuery();
  const [studentID, setStudentID] = useState("");
  const [placementID, setPlacementID] = useState("");
  const [status, setStatus] = useState(0);
  const actualStatus = useRef(null);
  const { enableLoader } = usePreLoader();
  const getcontactId = useSelector((state) => state.contactid);

  useEffect(() => {
    if (window.location.href.indexOf("?") > -1) {
      setStudentID(query.get("id"));
      setPlacementID(query.get("placement"));
      const statusCode = localStorage.getItem("studentPlacementStatus");
      if (statusCode) {
        setStatus(parseInt(statusCode));
        actualStatus.current = parseInt(statusCode);
      }
    }
  }, []);

  const contact = {
    widgetType: "updateContact",
    formSchema: studentSchema(getcontactId),
    uiSchema: studentUiSchema,
    formData: contactFormData,
    apiURL: config.teleportal.submitForm.Update,
    payloadSchema: {},
    entityType: "contacts",
    formUischema: "",
    button1Label: "",
    button2Label: "",
    payloadSubmit: ContactPayload,
    contactId: studentID,
    goBackPage: "/profile",
    button2GoBack: true,
    historyUrl: "",
  };

  const [contactOptions, setContactOptions] = useState(contact);

  useEffect(() => {
    if (studentID.length > 0) {
      getContactDetails();
    }
  }, [studentID]);

  const updateFormData = (entityType, formData) => {
    contact.formData = formData;
    contact.initialFormData = formData;
    setContactOptions(contact);
    setContactFormData(formData);
  };

  const getContactDetails = () => {
    let api;
    let payloads;
    const formData = {};
    api = config.teleportal.submitForm.getId;

    payloads = {
      properties: [
        "contactid",
        "firstname",
        "lastname",
        "fullname",
        "birthdate",
      ],
      formattedFields: ["gendercode"],
      recordType: "contacts",
      id: studentID,
    };
    const showErrorPopup = () => {
      enableLoader(false);
      dispatch({
        type: "SET_STATUS",
        payload: {
          message: "Unable to fetch data, Please try again later",
          code: "",
          display: "show",
          severity: "error",
        },
      });
    };

    setTimeout(() => {
      enableLoader(true);
      post(api, payloads)
        .then((res) => {
          res.data.data.forEach((x) => {
            formData[x.name] = x.value;
          });
          post(config.teleportal.file.list, getSupportingDocPayload(studentID))
            .then((response) => {
              if (response.data) {
                const cvDoc = formatData(response.data)?.[0];
                if (cvDoc) {
                  formData["cv"] = cvDoc.ctpt_documenturl;
                }
              }
            })
            .catch((err) => {
              console.log("Supporting Document fetch failed:", err);
            })
            .finally(() => {
              updateFormData({}, formData);
              enableLoader(false);
            });
        })
        .catch(showErrorPopup);
    }, 0);
  };

  const getResponse = (res) => {
    if (res.data.id) {
      dispatch({
        type: "SET_STATUS",
        payload: {
          message: "Record updated successfully",
          code: "",
          display: "show",
          severity: "success",
        },
      });
    } else {
      dispatch({
        type: "SET_STATUS",
        payload: {
          message: "Record update failed",
          code: "",
          display: "show",
          severity: "error",
        },
      });
    }
  };

  const updatePlacementStatus = (pStatus, pID) => {
    enableLoader(true);
    if (placementID) {
      post(
        config.teleportal.submitForm.Update,
        updateStudentPlacementStatus(pID, pStatus)
      )
        .then(() => {
          actualStatus.current = pStatus;
          localStorage.setItem("studentPlacementStatus", pStatus + "");
          dispatch({
            type: "SET_STATUS",
            payload: {
              message: "Status updated successfully",
              code: "",
              display: "show",
              severity: "success",
            },
          });
        })
        .catch((err) => {
          console.log(err);
          dispatch({
            type: "SET_STATUS",
            payload: {
              message: "Status update failed",
              code: "",
              display: "show",
              severity: "error",
            },
          });
        })
        .finally(() => {
          enableLoader(false);
        });
    }
  };
  return (
    <Grid container spacing={0} justify="center">
      <Grid item xs={12} md={9}>
        <FormWidget
          options={contactOptions}
          updateFormData={updateFormData}
          successResponse={getResponse}
        />
        <div style={{ display: "flex", alignItems: "flex-end" }}>
          <FormControl>
            <InputLabel
              style={{ marginLeft: "1rem" }}
              id="demo-simple-select-label"
            >
              Status
            </InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={status}
              label="Age"
              onChange={(e) => {
                setStatus(e.target.value);
              }}
              style={{
                marginLeft: "1rem",
                maxWidth: "10rem",
                marginRight: "2rem",
              }}
            >
              <MenuItem disabled value={2}>
                Inactive
              </MenuItem>
              <MenuItem value={533910001}>Placed</MenuItem>
              <MenuItem value={533910000}>Shortlisted</MenuItem>
              <MenuItem value={533910002}>Unplaced</MenuItem>
            </Select>
          </FormControl>

          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              updatePlacementStatus(status, placementID);
            }}
            disabled={actualStatus.current && actualStatus.current === status}
          >
            Update Status
          </Button>
        </div>
      </Grid>
    </Grid>
  );
}

export default PlacementDetails;
