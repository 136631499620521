import React, { useEffect } from "react";
import { AuthenticatedTemplate } from "@azure/msal-react";
import { ThemeProvider } from "@material-ui/core/styles";
import { globalTheme } from "style/globalTheme";
import { useSelector } from "react-redux";
import ListWidget from "app/components/mvp/listWidget";
import { getlistPayload } from "./model/payload";
import { config } from "utilities/config";
import { Grid } from "@material-ui/core";
import { resultCodes } from "utilities/stateCodes";
import { Button } from "@material-ui/core";
import Examreport from "brand/components/examreport";

import { useStyles } from "./style";

function ExamResult() {
  const classes = useStyles();
  const role = useSelector((state) => state.role);
  const getcontactId = useSelector((state) => state.contactid);
  const getcontactName = useSelector((state) => state.contactDet);
  const downloadFunc = React.useRef(null);
  const renderContactName = (params) => {
    return <div>{getcontactName.fullName || ""}</div>;
  };

  const examColumns = [
    { field: "ctpt_attempt", flex:0.4,headerName: "Attempt" },
    {
      field: "ctpt_name",
      flex:0.3,
      headerName: "Name",
      cellClassName: 'super-app-theme--cell',
      resizable: true 
    },
    {
      field: "ctpt_candidatenumber",
      flex:0.5,
      headerName: "Candidate Number",
      cellClassName: 'super-app-theme--cell',
      resizable: true 
    },
    {
      field: "ctpt_ismockexam",
      flex:0.5,
      headerName: "Is Mock Exam?",
      cellClassName: 'super-app-theme--cell',
      renderCell: (params) => <div>{params.row.ctpt_ismockexam == false ? "No" : "Yes"}</div>,
      resizable: true 
    },
    {
      field: "ctpt_contactid",
      flex:0.3,
      headerName: "Contact",
      resizable: true,
      cellClassName: 'super-app-theme--cell',
      renderCell: renderContactName,
    },
    {
      field: "_ctpt_examid_value",
      flex:0.3,
      headerName: "Exam",
      cellClassName: 'super-app-theme--cell',
      renderCell: (params) => <div>{params.row._ctpt_examid_value}</div>,
      resizable: true 
    },

    {
      field: "ctpt_isshortlisted",
      flex:0.4,
      headerName: "Short Listed",
      cellClassName: 'super-app-theme--cell',
      renderCell: (params) => <div>{params.row.ctpt_isshortlisted == false ? "No" : "Yes"}</div>,
      resizable: true 
    },
    {
      field: "ctpt_mark",
      flex:0.3,
      headerName: "Mark",
      cellClassName: 'super-app-theme--cell',
      resizable: true 
    },

    {
      field: "ctpt_officecode",
      flex:0.3,
      headerName: "Office",
      resizable: true,
      cellClassName: 'super-app-theme--cell',
    },
    {
      field: "ctpt_result",
      flex:0.7,
      headerName: "Result",
      resizable: true,
      cellClassName: 'super-app-theme--cell',
      renderCell: (params) => (
        <div>
          {params.row.ctpt_result !== null
            ? resultCodes[params.row.ctpt_result]
            : ""}
        </div>
      ),
    },
  ];

  const configuration = {
    apiURL: config.teleportal.file.list,
    apiUrlDel: config.teleportal.file.delete,
    columns: examColumns,
    searchPayload: "searchFilter",
    getlistPayload: getlistPayload,
    deleteListPayload: {},
    listHeading: "Exam Statement & Reports",
    formwidgetOptions: {},
    nonRecord: true,
    role: role,
    rows: "",
    localDataStore: false,
    hideControls: true,
    hasAggregate: false,
    staticTable: false,
    staticRow: [],
    hiddenColumn: {},
    contactId: getcontactId,
    filteredValue: "",
    hasFilter: false,
    filterField: "_ctpt_contactid_value",
    filterValue: [],
    aggregateColumns: [],
    aggregateField: "",
    hasPayloadfilter: true,
    hideDelete: true,
    hideSearch: true,
    hideAdd: true,
    pageSize: 10,
  };

  const openPdf = (params) => {
    downloadFunc.current()
    
  };

  return (
    <ThemeProvider theme={globalTheme}>
      <AuthenticatedTemplate>
        

    
        <Grid container spacing={0}>
          <Grid item xs={12} md={12}>
            <div className={classes.container}>
            <div className={classes.buttonContainer}>
        <Button
            variant="outlined"
            color="primary"
            className="primaryBtn"
            onClick={() => {
              openPdf();
            }}
            //disabled={params.row._ctpt_sonicwebformsubmission_value == null}
          >
            Download Exam Report
          </Button>
          <div> 
         
            <Examreport
              payDetails={null}
              label="Download Invoice"
              downloadFunc={downloadFunc}
            />
          
          </div>
        
        </div>
        <div id="examReport">
              <ListWidget options={configuration}/>
              </div>
            </div>
          </Grid>
        </Grid>
       
      </AuthenticatedTemplate>
    </ThemeProvider>
  );
}

export default ExamResult;
