export const getEvidenceSchema = (getcontactId) => ({
  title: "Evidence Details",
  logicalname: "ctpt_supportingdocuments",
  type: "object",
  properties: {
    ctpt_name: {
      title: "Name",
      type: "string",
      schemaname: "ctpt_name",
      datatype: "string",
      requiredCheck: true,
      isReadonly: true,
      disabled: true,
    },
    ctpt_descriptionofdevelopmentlearningactivity: {
      title: "Description of development/learning activity",
      type: "string",
      schemaname: "ctpt_descriptionofdevelopmentlearningactivity",
      datatype: "string",
      ismultiline: true,
      multilineRow: 4,
      isReadonly: true,
      disabled: true,
    },
    ctpt_whydidyouchoosethisactivityandhowisitre: {
      title: "Why did you choose this activity and how is it relevant to you?",
      type: "string",
      schemaname: "ctpt_whydidyouchoosethisactivityandhowisitre",
      datatype: "string",
      requiredCheck: true,
      isReadonly: true,
      disabled: true,
    },
    ctpt_whendidthisactivitytakeplace: {
      title: "When did this activity take place?",
      type: "string",
      schemaname: "ctpt_whendidthisactivitytakeplace",
      datatype: "string",
      requiredCheck: true,
      disableFuture: true,
      tootltipheading: "",
      tootltipText: "",
      helpText: "(DD/MM/YYYY)",
      defaultValue: "",
      pattern: "",
      message: "",
      isReadonly: true,
      disabled: true,
    },
    ctpt_howmanyunitsareyourclaimingforthisactivit: {
      title: "How many units are your claiming for this activity?",
      type: "number",
      schemaname: "ctpt_howmanyunitsareyourclaimingforthisactivit",
      datatype: "string",
      requiredCheck: true,
      pattern: "^[0-9]*$",
      message: "Only numbers allowed",
      isReadonly: true,
      disabled: true,
    },
    ctpt_howmanyoftheseunitscouldyouverifywithsup: {
      title:
        "How many of these units could you verify with supporting evidence?",
      type: "number",
      schemaname: "ctpt_howmanyoftheseunitscouldyouverifywithsup",
      datatype: "string",
      requiredCheck: true,
      pattern: "^[0-9]*$",
      message: "Only numbers allowed",
      isReadonly: true,
      disabled: true,
    },
    ctpt_whatdidyoulearnandhowdidwillyouapplyit: {
      title: "What did you learn and how did/will you apply it?",
      type: "string",
      schemaname: "ctpt_whatdidyoulearnandhowdidwillyouapplyit",
      datatype: "string",
      requiredCheck: true,
      multilineRow: 4,
      isReadonly: true,
      disabled: true,
    },
    ctpt_cpdhours: {
      title: "CPD Hours",
      type: "number",
      schemaname: "ctpt_cpdhours",
      datatype: "decimal",
      requiredCheck: true,
      pattern: "^[0-9]*(\.[0-9]{0,2})?$",
      message: "Only numbers allowed",
      isReadonly: true,
      disabled: true,
    },
    ctpt_supportingevidence: {
      title: "Supporting Evidence",
      type: "string",
      schemaname: "ctpt_supportingevidence",
      datatype: "string",
      ismultiline: true,
      multilineRow: 4,
      isReadonly: true,
      disabled: true,
    },
    ctpt_documenturl: {
      title: "Attach document",
      type: "string",
      schemaname: "ctpt_documenturl",
      datatype: "upload",
      helpText: "",
      tootltipheading: "",
      requiredCheck: true,
      format: "string",
      containerName: "channels",
      fileName: "cpdEvidence",
      acceptExtension: [
        ".pdf",
        ".doc",
        ".docx",
        ".png",
        ".jpeg",
        ".xlsx",
        ".xls",
        ".csv",
      ],
      acceptType: [
        "application/pdf",
        "application/msword",
        "image/png",
        "image/jpeg",
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        "application/vnd.ms-excel",
        "application/csv",
      ],
      recordPath:`${getcontactId}/`,
      requireBoburl: true,
      isReadonly: true,
      disabled: true,
    },
  },
});

export const getDocumentSchema = {
  title: " ",
  logicalname: "Contact2",
  type: "object",
  properties: {
    ctpt_name: {
      title: "Name",
      type: "string",
      schemaname: "ctpt_customerid",
      datatype: "string",
      isDynamic: false,
    },
    ctpt_documenttype: {
      title: "Document Type",
      type: "string",
      schemaname: "ctpt_documenttype",
      datatype: "string",
    },
    ctpt_case: {
      title: "Case",
      type: "string",
      schemaname: "ctpt_case",
      datatype: "string",
    },
    ctpt_documenturl: {
      title: "Student attendance register",
      type: "string",
      schemaname: "ctpt_documenturl",
      datatype: "string",
      requireBoburl: true,
    },
  },
};
