export const getContactDetailsAPIPayload = (contactId) => ({
  properties: [
    "contactid",
    "emailaddress1",
    "ctpt_membershipclass",
    "mobilephone",
    "ctpt_lastapplicationapprovedon",
    "address1_composite",
    "statecode",
    "ctpt_profiledetailsconfirmedaccurateon",
    "fullname",
    "ctpt_membershipnumber"
  ],
  recordType: "contacts",
  id: contactId,
});


export const getConfig = {
  "properties": [
      "ctpt_value"
  ],
  "formattedFields": [],
  "recordType": "ctpt_configurationitems",
  "filters": [
      {
          "field": "ctpt_name",
          "searchvalue": "ProfileExpiryDuration",
          "type": "string"
      },
  ],
  "FieldAndOperator": "false",
  "idfield": "ctpt_configurationitemid"
}
