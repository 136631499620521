export const transition = {
  textSlideInOutOpacityIndent: 'text-indent 0.5s ease-in-out, opacity 0.4s ease-out',
};

export const breakpoints = {
  mobile: 767,
  desktop: 1200,
};

export const weight = {
  regular: 400,
  bold: 700,
  black: 900,
};

export const palette = {
  paper: 'paper',
  action: 'action',
  text: 'text',
  disabled: 'disabled',
  structure: 'structure',
  warning: 'warning',
  hover: 'hover',
  focus: 'focus',
};

export const typography = {
  caption: {
    size: 0.75,
    weight: weight.regular,
    element: 'p',
  },
  body: {
    size: 1,
    weight: weight.regular,
    element: 'p',
  },
  bodyL: {
    size: 1.25,
    weight: weight.regular,
    element: 'p',
  },
  h5: {
    size: 1.5,
    weight: weight.black,
    element: 'h5',
  },
  h4: {
    size: 2,
    weight: weight.black,
    element: 'h4',
  },
  h3: {
    size: 2.5,
    weight: weight.black,
    element: 'h3',
  },
  h2: {
    size: 3,
    weight: weight.black,
    element: 'h2',
  },
  h1: {
    size: 3.5,
    weight: weight.black,
    element: 'h1',
  },
  mega: {
    size: 4,
    weight: weight.black,
    element: 'div',
  },
  jumbo: {
    size: 4.5,
    weight: weight.black,
    element: 'div',
  },
};

export const globalSizes = {
  none: '0rem',
  smallest: 'var(--s-5)',
  tiny: 'var(--s-4)',
  xxs: 'var(--s-3)',
  xs: 'var(--s-2)',
  small: 'var(--s-1)',
  base: 'var(--s0)',
  medium: 'var(--s1)',
  large: 'var(--s2)',
  xl: 'var(--s3)',
  xxl: 'var(--s4)',
  largest: 'var(--s5)',
  measure: 'var(--measure)',
};

export const justifyMap = {
  start: 'flex-start',
  end: 'flex-end',
  center: 'center',
  between: 'space-between',
  around: 'space-around',
  evenly: 'space-evenly',
  stretch: 'stretch',
};

export const alignMap = {
  start: 'flex-start',
  end: 'flex-end',
  center: 'center',
  stretch: 'stretch',
  baseline: 'baseline',
};

export const alignContentMap = {
  start: 'flex-start',
  end: 'flex-end',
  center: 'center',
  stretch: 'stretch',
  between: 'space-between',
  around: 'space-around',
};

export const formAlign = {
  left: 'left',
  right: 'right',
};

export const formDisplayType = {
  default: 'default',
  justified: 'justified',
};
