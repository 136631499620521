import CustomTextBox from "app/components/atoms/customTextBox";
import Upload from "app/components/atoms/upload";
import CustomDate from "app/components/atoms/customDate";
import CustomCheckBox from "app/components/atoms/customCheckBox";
import CustomRadio from "app/components/atoms/customRadio";

const componentType = {
  upload: {
    "ui:widget": (props) => {
      return (
        <Upload
          recordType={props.schema.containerName}
          recordPath={props.schema.recordPath}
          getProps={props}
        />
      );
    },
  },
};

export const getComplianceUiSchema = {
  "ui:title": "",
  ctpt_cpdcomplied: {
    "ui:widget": (props) => {
      return <CustomRadio getProps={props} />;
    },
  },
  ctpt_cpdnoncomplied: {
    "ui:widget": (props) => {
      return <CustomCheckBox getProps={props} />;
    },
  },
  ctpt_compliedbicacodeofethics: {
    "ui:widget": (props) => {
      return <CustomRadio getProps={props} />;
    },
  },
  ctpt_notcompliedbicacodeofethics: {
    "ui:widget": (props) => {
      return <CustomCheckBox getProps={props} />;
    },
  },
  ctpt_undertakencpdbybica: {
    "ui:widget": (props) => {
      return <CustomRadio getProps={props} />;
    },
  },
  ctpt_undertakencpdbyifac: {
    "ui:widget": (props) => {
      return <CustomCheckBox getProps={props} />;
    },
  },
  ctpt_certifiedauditor: {
    "ui:widget": (props) => {
      return <CustomCheckBox getProps={props} />;
    },
  },
  ctpt_responsibleforfinancialstatements: {
    "ui:widget": (props) => {
      return <CustomCheckBox getProps={props} />;
    },
  },
  ctpt_responsibleforcompanysecretarialwork: {
    "ui:widget": (props) => {
      return <CustomCheckBox getProps={props} />;
    },
  },
  ctpt_anaccountingofficerofacompany: {
    "ui:widget": (props) => {
      return <CustomCheckBox getProps={props} />;
    },
  },
  ctpt_otherspecify: {
    "ui:widget": (props) => {
      return (
        <div style={{ marginBottom: "2rem" }}>
          <CustomTextBox getProps={props} />
        </div>
      );
    },
  },
  ctpt_ownorandworkfornonauditingmemberfirm: {
    "ui:widget": (props) => {
      return <CustomCheckBox getProps={props} />;
    },
  },
  // ctpt_pleadedguilty: {
  //   "ui:widget": (props) => {
  //     return <CustomCheckBox getProps={props} />;
  //   },
  // },
  pleadConditional: {
    pleadRadio: {
      "ui:widget": (props) => {
        return <CustomRadio getProps={props} />;
      },
    },
    ctpt_documenturl: componentType.upload,
  },
  ctpt_submittedkyc: {
    "ui:widget": (props) => {
      return <CustomCheckBox getProps={props} />;
    },
  },
};
