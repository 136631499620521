import Upload from "app/components/atoms/upload";
import CustomCheckBox from "app/components/atoms/customCheckBox";
import MultipleSelect from "app/components/atoms/multiDropdown";
import CustomRadio from "app/components/atoms/customRadio";

const CustomUpload = ({ getProps }) => {
  return (
    <Upload
      recordType={getProps.schema.containerName}
      recordPath={getProps.schema.recordPath}
      getProps={getProps}
    />
  );
};

export const uiSchema = {
  "ui:title": "",
  reasonForcertificate: {
    "ui:widget": (props) => {
      return <MultipleSelect getProps={props} />;
    },
  },

  reasonConditional: {
    reasonRadio: {
      "ui:widget": (props) => {
        return <CustomRadio getProps={props} />;
      },
    },
    policeAffidavit: {
      "ui:widget": (props) => {
        return <CustomUpload getProps={props} />;
      },
    },
    marriageCertificate: {
      "ui:widget": (props) => {
        return <CustomUpload getProps={props} />;
      },
    },
    duplicateCheckBox: {
      "ui:widget": (props) => {
        return <CustomCheckBox getProps={props} />;
      },
    }
  }
}

