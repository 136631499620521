//Import React Library
import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { AuthenticatedTemplate } from "@azure/msal-react";

//Import Material-UI Library
import { ThemeProvider } from "@material-ui/core/styles";

//Loading Data and Style..
import { globalTheme } from "style/globalTheme";
import { useStyles } from "./style";
//Loading components
import { Button } from "@material-ui/core";
import { config } from "utilities/config";

import ListComponent from "app/components/mvp/listWidget";
import {
  getEmployerDetailsPayload,
  getlistPayload,
  getStudentDetailsPayload,
  getStudentPlacementDetailsPayload,
} from "./model/payload";
import { post } from "app/services/api";
import { formatData } from "utilities/datatable";
import { usePreLoader } from "app/services/hooks/usePreLoader";

const { log } = console;
function StudentPlacement() {
  const classes = useStyles();
  const role = useSelector((state) => state.role);
  const contactID = useSelector((state) => state.contactid);
  const dispatch = useDispatch();
  const { enableLoader } = usePreLoader();

  const statusMap = {
    2: "Inactive",
    533910001: "Placed",
    533910000: "Shortlisted",
    533910002: "Unplaced",
  };

  const [studentDetails, setStudentDetails] = useState([]);

  useEffect(() => {
    const showErrorPopup = (err) => {
      enableLoader(false);
      console.log(err);
      dispatch({
        type: "SET_STATUS",
        payload: {
          message: "Oops something went wrong!",
          code: "",
          display: "show",
          severity: "error",
        },
      });
    };

    const getStateCodeMap = (res) => {
      let stateCodeMap = {};
      formatData(res.data).forEach((x) => {
        if (x._ctpt_studentid_value) {
          stateCodeMap[x._ctpt_studentid_value] = {
            status: x.statuscode,
            placementID: x.id,
          };
        }
      });

      return stateCodeMap;
    };
    const getContactDetails = (ids, stateCodeMap) => {
      const filteredStudentIDs = ids.filter((id) => !!id._ctpt_studentid_value);
      if (filteredStudentIDs.length < 1) {
        enableLoader(false);
        return;
      }
      post(
        config.teleportal.submitForm.get,
        getStudentDetailsPayload(filteredStudentIDs)
      )
        .then((res) => {
          const formattedStudentDetails = formatData(res.data).map((x) => ({
            id: x.id,
            studentName: x.fullname,
            status: stateCodeMap[x.id]?.status,
            placementID: stateCodeMap[x.id]?.placementID,
          }));
          setStudentDetails(formattedStudentDetails);
          enableLoader(false);
          log("Form:", formattedStudentDetails);
        })
        .catch(showErrorPopup);
    };

    const getPlacementDetails = (res) => {
      const employerIDs = formatData(res.data).map(
        (employer) => employer._ctpt_employerid_value
      );
      const employerIdsFiltered = employerIDs.filter((id) => !!id); // INFO : filtering null values
      if (employerIdsFiltered.length < 1) {
        enableLoader(false);
        return;
      }
      post(
        config.teleportal.file.list,
        getStudentPlacementDetailsPayload(employerIdsFiltered)
      )
        .then((res) => {
          const stateCodeMap = getStateCodeMap(res);
          log("MAP:", stateCodeMap);
          getContactDetails(formatData(res.data), stateCodeMap);
        })
        .catch(showErrorPopup);
    };
    if (contactID) {
      setTimeout(() => {
        enableLoader(true);
        post(config.teleportal.file.list, getEmployerDetailsPayload(contactID))
          .then((res) => {
            if (res.data) {
              getPlacementDetails(res);
            }
          })
          .catch(showErrorPopup);
      }, 0);
    }
  }, [contactID, enableLoader, dispatch]);

  const continueClick = (params) => {
    localStorage.setItem("studentPlacementStatus", params.row.status + "");
    let path =
      window.location.protocol +
      "//" +
      window.location.host +
      "/placement-details" +
      "?id=" +
      params.row.id +
      "&placement=" +
      params.row.placementID;
    window.location.href = path;
  };

  const columns = [
    { field: "id", flex: 1, headerName: " ", hide: true },
    {
      field: "studentName",
      flex: 1,
      headerName: "Student Name",
    },
    {
      field: "status",
      flex: 1,
      headerName: "Status",
      renderCell: (params) => <p>{statusMap[params.row.status]}</p>,
    },
    {
      field: "placementID",
      flex: 1,
      headerName: "",
      hide: true,
    },
    {
      field: "action",
      headerName: " ",
      flex: 1,
      renderCell: (params) => (
        <div>
          <Button
            variant="contained"
            color="primary"
            className={classes.primaryBtn}
            onClick={() => {
              continueClick(params);
            }}
            disabled={false}
          >
            View Details
          </Button>
        </div>
      ),
    },
  ];

  const progressOptions = {
    apiURL: config.teleportal.file.list,
    apiUrlDel: config.teleportal.file.delete,
    columns: columns,
    searchPayload: "searchFilter",
    getlistPayload: getlistPayload,
    deleteListPayload: {},
    listHeading: "Student Placement",
    // listDescription:
    //   "The applications below are not complete and cannot be processed. Please complete them using the resume buttons below.​",
    formWidget: {},
    nonRecord: false,
    role: role,
    rows: "",
    localDataStore: false,
    hideDelete: true,
    hasAggregate: false,
    staticTable: true,
    staticRow: studentDetails,
    formatStaticRow: false,
    hiddenColumn: {},
    contactId: contactID,
    filteredValue: "",
    hasFilter: true,
    //hasPayloadfilter: true,
    filterField: "swfStatus",
    filterValue: [100000000],
    aggregateColumns: [],
    aggregateField: "",
    isCustomFetch: false,
    pageSize: 10,
    hideSearch: false,
    enableLocalSearch: true,
    hideAdd: true,
    controlLists: false,
    payloadModifyForbidden: true,
  };

  return (
    <ThemeProvider theme={globalTheme}>
      <AuthenticatedTemplate>
        <div id="cTTP_applicationList">
          <div className={classes.listSection}>
            {contactID !== "" ? (
              <ListComponent options={progressOptions} />
            ) : (
              ""
            )}
          </div>
        </div>
      </AuthenticatedTemplate>
    </ThemeProvider>
  );
}

export default StudentPlacement;
