export const style = {
  root: {
    width: "100%",
    maxWidth: 360,
  },
  textBox: {
    margin: "20px",
  },
  RadioGroup: {
    flexDirection: "column",
  },
  ".makeStyles-paper-235": {
    backgroundColor: "red",
  },
  ".MuiTypography-root": {
    marginBottom: "1rem",
  },
  
};
