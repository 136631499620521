import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    "& > *": {
      margin: theme.spacing(1),
    },
    "& .statusTblheader": {
      backgroundColor: "rgba(255, 7, 0, 0.55)",
    },
  },
  snackbarSuccess: {
    backgroundColor: theme.palette.success.main,
  },
  statusTblheader: {
    backgroundColor: "rgba(255, 7, 0, 0.55)",
  },
  snackbarError: {
    backgroundColor: theme.palette.error.main,
  },
  listSection: { minHeight: "50rem", marginTop: "20px" },
  formList: {
    marginTop: "20px",
  },
  formLink: {
    textDecoration: "none",
    color: theme.palette.primary.dark,
  },
  h2heading: {
    fontSize: "15px",
    fontWeight: "bold",
  },
  primaryBtn: {
    display: "inline-block",
    border: "1px solid #000",
    marginRight: "1rem",
  },
  listContent: {
    paddingBottom: "50px",
  },
}));
