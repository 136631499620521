export const contactSchema = {
  title: "Update Email",
  logicalname: "Contact",
  type: "object",
  properties: {
    emailaddress1: {
      title: "Email Address",
      type: "string",
      schemaname: "emailaddress1",
      datatype: "string",
      requiredCheck: true,
      pattern: "^[a-zA-Z0-9.\\-_]+(\\.[a-zA-Z0-9\\-_]+)*@[a-z0-9]+(\\-[a-z0-9]+)*(\\.[a-z0-9]+(\\-[a-z0-9]+)*)*\\.[a-z]{2,4}$",
      message: "Invalid Email id"
    },
  },
};
