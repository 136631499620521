import React from "react";
import PropTypes from "prop-types";
import styled, { css } from "styled-components";
import FormControl from "@material-ui/core/FormControl";
import { globalSizes } from "theme/units";

const Field = styled(FormControl)((props) => {
  const $fieldWidth =
    props.fieldWidth !== "auto" &&
    css`
      width: calc(${props.$fieldWidth}rem);
    `;

  return css`
    display: flex;
    flex-direction: column;

    > * + * {
      margin-top: ${globalSizes[props.gap]};
    }

    label {
      position: static;
      transform: none;
      font-weight: bold;
    }
    
    legend{font-weight:bold;}
	
	> div:not(.fieldHelp) {
  ${$fieldWidth}
  `;
});

export default function SWFField({ children, fieldWidth = "auto", ...rest }) {
  return (
    <Field className="SWF-Field" gap="small" $fieldWidth={fieldWidth} {...rest}>
      {children}
    </Field>
  );
}

SWFField.propTypes = {
  /** number of characters - to estimate field width - this only affects the width of the field itself - the label and help text remain full width of the form*/
  fieldWidth: PropTypes.string,
};
