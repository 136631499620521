import React from "react";
import PropTypes from "prop-types";

import { Card, Box, Typography } from "@material-ui/core";
import Frame from "layout/Frame";
import styled, { css } from "styled-components";
import Cluster from "layout/Cluster";

const ImgCard = styled(Card)(
  () => css`
    position: relative;
    height: ${(props) => props.$height};
    width: ${(props) => props.$width};
    padding: 10px;
    box-shadow: 0px 2px 2px -1px rgb(0 0 0 / 20%), 0px 1px 2px 2px rgb(0 0 0 / 14%), 0px 1px 3px 2px rgb(0 0 0 / 12%)
  `
);

const CardContent = styled(Cluster)`
  position: relative;
  z-index: 1;
  margin-top: 1rem;
`;
const CardHeader = styled(Typography)`
  position: absolute;
  top: 0px;
  left: 0px;
  z-index: 2;
  background: rgba(255, 255, 255, 0.5);
  display: block;
  padding: 1rem;
  font-size: 2.1rem;
`;
const CardBox = styled(Box)(
  () => css`
    width: ${(props) => props.$width}%;
    padding: 2.5rem 0.5rem 0.5rem 0.5rem;
    height: 100%;
  `
);
const ImgBox = styled(Box)(
  () => css`
    width: ${(props) => props.$width}%;
  `
);

const ImageCard = ({
  image,
  children,
  header,
  width,
  height,
  imgRatio = "1:1",
  lrRatio = "60:40",
}) => {
  const contentSplit = lrRatio.split(":");
  return (
    <ImgCard $width={width} $height={height}>
      <CardContent justify="between" alignContent="start" align="end">
        <CardBox $width={contentSplit[0]}>{children}</CardBox>
        <ImgBox $width={contentSplit[1]}>
          <Frame ratio={imgRatio}>{image}</Frame>
        </ImgBox>
      </CardContent>
      <CardHeader variant="h4" component="h3">
        {header}
      </CardHeader>
    </ImgCard>
  );
};

ImageCard.propTypes = {
  /** The image for the card - pass in a whole HTML `img` */
  image: PropTypes.element,
  /**  The header text for the card*/
  header: PropTypes.string,
  /**  The aspect ratio for the image (it is rendered by a Frame - see layout primitives -> Frame)*/
  imgRatio: PropTypes.string,
  /** Ratio of content to image eg: "70:30" will have the left hand (content) side be 70% of the width of the card, the image 30% */
  lrRatio: PropTypes.string,
};

export default ImageCard;
