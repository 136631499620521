import Upload from "app/components/atoms/upload";
import CustomTextBox from "app/components/atoms/customTextBox";
import CustomCheckBox from "app/components/atoms/customCheckBox";
import CustomRadio from "app/components/atoms/customRadio";
import CustomDate from "app/components/atoms/customDate";
import CustomSelect from "app/components/atoms/customSelect";

const CustomUpload = ({ getProps }) => {
  return (
    <Upload
      recordType={getProps.schema.containerName}
      recordPath={getProps.schema.recordPath}
      getProps={getProps}
    />
  );
};

export const uiSchema = {
  "ui:title": "",
  upgradeType:{
    "ui:widget": "hidden"
  },
  upgradeClass:{
    "ui:widget": (props) => {
      return <CustomSelect getProps={props} />;
    },
  },
  firstname: {
    "ui:widget": (props) => {
      return <CustomTextBox getProps={props} />;
    },
  },
  lastname: {
    "ui:widget": (props) => {
      return <CustomTextBox getProps={props} />;
    },
  },
  idnumber: {
    "ui:widget": (props) => {
      return <CustomTextBox getProps={props} />;
    },
  },
  expirydate: {
    "ui:widget": (props) => {
      return <CustomDate getProps={props} />;
    },
  },
  bicaNumber: {
    "ui:widget": (props) => {
      return <CustomTextBox getProps={props} />;
    },
  },
  membershipStatus: {
    "ui:widget": (props) => {
      return <CustomRadio getProps={props} />;
    },
  },
  organisation: {
    "ui:widget": (props) => {
      return <CustomTextBox getProps={props} />;
    },
  },
  jobTitle: {
    "ui:widget": (props) => {
      return <CustomTextBox getProps={props} />;
    },
  },
  physicalAddress: {
    plotNumber: {
      "ui:widget": (props) => {
        return <CustomTextBox getProps={props} />;
      },
    },
    city: {
      "ui:widget": (props) => {
        return <CustomTextBox getProps={props} />;
      },
    },
    district: {
      "ui:widget": (props) => {
        return <CustomTextBox getProps={props} />;
      },
    },
  },
  contactInformation: {
    telephone: {
      "ui:widget": (props) => {
        return <CustomTextBox getProps={props} />;
      },
    },
    emailAddress: {
      "ui:widget": (props) => {
        return <CustomTextBox getProps={props} />;
      },
    },
    mobile: {
      "ui:widget": (props) => {
        return <CustomTextBox getProps={props} />;
      },
    }
  },
  qualificationGained: {
    "ui:widget": (props) => {
      return <CustomTextBox getProps={props} />;
    },
  },
  reasonForupgrade: {
    "ui:widget": (props) => {
      return <CustomTextBox getProps={props} />;
    },
  },
  bicaCertificate: {
    "ui:widget": (props) => {
      return <CustomUpload getProps={props} />;
    },
  },
  supportingCertificate: {
    "ui:widget": (props) => {
      return <CustomUpload getProps={props} />;
    },
  },
  letterOfGood: {
    "ui:widget": (props) => {
      return <CustomUpload getProps={props} />;
    },
  },
  documentaryCheckBox: {
    "ui:widget": (props) => {
      return <CustomCheckBox getProps={props} />;
    },
  },
  bicaTrainingContractCheckBox: {
    "ui:widget": (props) => {
      return <CustomCheckBox getProps={props} />;
    },
  },
};
