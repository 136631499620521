import CustomTextBox from "app/components/atoms/customTextBox";

export const contactUiSchema = {
  "ui:title": "",
  emailaddress1: {
    "ui:widget": (props) => {
      return <CustomTextBox getProps={props} />;
    },
  },
};
