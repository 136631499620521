import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(theme => ({
  root: {
   
    
  },
  headingTxt:{
    color:theme.palette.warning.light
  },
  desc:{
    background:theme.palette.midlightBg.main,
    padding:'10px'
  }

}));