//Import React Library
import React, { useRef } from "react";
import { useSelector } from "react-redux";
import { AuthenticatedTemplate } from "@azure/msal-react";

//Import Material-UI Library
import { ThemeProvider } from "@material-ui/core/styles";

//Loading Data and Style..
import { globalTheme } from "style/globalTheme";
import { useProfileStyles } from "./style";
//Loading components
import { Button } from "@material-ui/core";
import { config } from "utilities/config";

import ListComponent from "app/components/mvp/listWidget";
import { getemploymentlistPayload } from "./model/payload";
import { useHistory } from "react-router-dom";
//import { DECLARATION_STATUS_MAP } from "./model/constants";
import { addNewEmploymentHistoryDetailsSchema } from "./model/schema";
import { addNewEmploymentHistoryDetailsUiSchema } from "./model/viewemploymenthistorydetailsuiSchema";
import { addNewEmploymentDetailsPayload } from "./model/payload";

function EmploymentHistory() {
  const classes = useProfileStyles();
  const getcontactId = useSelector((state) => state.contactid);
  const getcontactName = useSelector((state) => state.user);
  const history = useHistory();
  const userRole = useSelector((state) => state.role);

  const onClickViewDetails = (params) => {
    window.location.href = "/profile/viewemploymenthistorydetails?id=" + params.row.ctpt_professionalhistoryid;
  };
 
  const columns = [
    { field: "ctpt_professionalbody", flex: 1, headerName: "Organisation Name", hide: false },
    {
      field: "action",
      headerName: <p className={classes.paidListHeader}>Action</p>,
      flex: 1,
      renderCell: (params) => {
        return (
          <div className={classes.actionsButtons}>
            <Button
              variant="outlined"
              color="primary"
              className="primaryBtn"
              onClick={() => {
                onClickViewDetails(params);
              }}
              disabled={false}
            >
              {userRole && userRole.name && !userRole.name.includes('Individual') ? "View & Update" : "View"}
            </Button>
          </div>
        );
      },
    },
  ];

  const options = {
    apiURL: config.teleportal.events.getAllEventList,
    apiUrlDel: config.teleportal.file.delete,
    columns: columns,
    searchPayload: "searchFilter",
    getlistPayload: getemploymentlistPayload(getcontactId),
    deleteListPayload: {},
    listHeading: "Employment History",
    formwidgetOptions: {
      widgetType: "add",
      formSchema: addNewEmploymentHistoryDetailsSchema,
      uiSchema: addNewEmploymentHistoryDetailsUiSchema,
      apiURL: config.teleportal.submitForm.Create,
      entityType: "ctpt_professionalhistories",
      formUischema: "",
      button1Label: "Create",
      button2Label: "Cancel",
      button3Label: "Clear",
      payloadSubmit: addNewEmploymentDetailsPayload(getcontactId),
      columns: [],
      contactId: getcontactId,
      contactName: getcontactName.name,
      historyUrl: "",
      hasMultiplecall: true,
      hasModel: true,
    },
    nonRecord: false,
    rows: "",
    localDataStore: false,
    hideDelete: true,
    hasAggregate: false,
    staticTable: false,
    staticRow: [],
    hiddenColumn: {},
    contactId: getcontactId,
    filteredValue: "",
    hasFilter: false,
    filterField: "",
    filterValue: [],
    aggregateField: "",
    hideSearch: true,
    pageSize: 6,
    hasPayloadfilter: false,
    hideAdd: false,
    controlLists: false,
  };

  return (
    <ThemeProvider theme={globalTheme}>
      <AuthenticatedTemplate>
        {/* <div className={classes.background1}></div> */}
        <div id="cTTP_applicationList" className={classes.container}>
          <div className={classes.listSection}>
            <Button
              size="medium"
              variant="outlined"
              color="primary"
              onClick={() => {
                history.push('/profile');
              }}
              style={{ marginLeft: "1.5rem" }}
            >
              Go Back
            </Button>
            <ListComponent options={options} />
          </div>

          <div></div>
        </div>
      </AuthenticatedTemplate>
    </ThemeProvider>
  );
}

export default EmploymentHistory;
