import Upload from "app/components/atoms/upload";
import CustomTextBox from "app/components/atoms/customTextBox";
import CustomCheckBox from "app/components/atoms/customCheckBox";
import CustomDate from "app/components/atoms/customDate";
import MultipleSelect from "app/components/atoms/multiDropdown";

const CustomUpload = ({ getProps }) => {
  return (
    <Upload
      recordType={getProps.schema.containerName}
      recordPath={getProps.schema.recordPath}
      getProps={getProps}
    />
  );
};

export const uiSchema = {
  "ui:title": "",
  modules: {
    "ui:widget": (props) => {
      return <MultipleSelect getProps={props} />;
    },
  },
  name: {
    "ui:widget": (props) => {
      return <CustomTextBox getProps={props} />;
    },
  },
  address:{
  plotnumber: {
    "ui:widget": (props) => {
      return <CustomTextBox getProps={props} />;
    },
  },
  city: {
    "ui:widget": (props) => {
      return <CustomTextBox getProps={props} />;
    },
  },
  district: {
    "ui:widget": (props) => {
      return <CustomTextBox getProps={props} />;
    },
  },
  country: {
    "ui:widget": (props) => {
      return <CustomTextBox getProps={props} />;
    },
  },
},
  telephone: {
    "ui:widget": (props) => {
      return <CustomTextBox getProps={props} />;
    },
  },
  email: {
    "ui:widget": (props) => {
      return <CustomTextBox getProps={props} />;
    },
  },
  birthdate: {
    "ui:widget": (props) => {
      return <CustomDate getProps={props} />;
    },
  },
  university: {
    "ui:widget": (props) => {
      return <CustomTextBox getProps={props} />;
    },
  },
  degreetitle: {
    "ui:widget": (props) => {
      return <CustomTextBox getProps={props} />;
    },
  },
  qualification: {
    "ui:widget": (props) => {
      return <CustomTextBox getProps={props} />;
    },
  },
  depositeslip: {
    "ui:widget": (props) => {
      return <CustomUpload getProps={props} />;
    },
  },
  memberevicdence: {
    "ui:widget": (props) => {
      return <CustomUpload getProps={props} />;
    },
  },
  examdetails: {
    "ui:widget": (props) => {
      return <CustomUpload getProps={props} />;
    },
  },
  passed: {
    "ui:widget": (props) => {
      return <CustomCheckBox getProps={props} />;
    },
  },
  responsibility: {
    "ui:widget": (props) => {
      return <CustomCheckBox getProps={props} />;
    },
  },
  noprevexam: {
    "ui:widget": (props) => {
      return <CustomCheckBox getProps={props} />;
    },
  },
  regstudent: {
    "ui:widget": (props) => {
      return <CustomCheckBox getProps={props} />;
    },
  },
};
