import React, { useState } from "react";
import { AuthenticatedTemplate } from "@azure/msal-react";
import { ThemeProvider } from "@material-ui/core/styles";
import { globalTheme } from "style/globalTheme";
import styled from "styled-components";
import { useStyles } from "./style";
import { Box, Typography } from "@material-ui/core";
import CustomButton from "app/components/atoms/customButton";
import { config } from "utilities/config";
import { useSelector } from "react-redux";

const StartPage = styled.section`
  ${globalTheme.breakpoints.up("sm")} {
    padding: 4rem 4rem 4rem 300px;
  }
  ${globalTheme.breakpoints.down("xs")} {
    padding: 1rem;
  }
`;

function LetterOfGood() {
  const classes = useStyles();
  const [btnMsg, setbtnMsg] = useState("");
  const getcontactId = useSelector((state) => state.contactid);

  const onBtnupdate = (msg) => {
    setbtnMsg(msg);
  };
  const btnOptions = {
    noMethod: true,
    apiUrl: config.teleportal.letter.create,
    payload: {
      contactId: getcontactId,
      CaseTypeName: "Letter of Good Standing",
    },
    responseMsg: "Your request has been sent",
  };

  return (
    <ThemeProvider theme={globalTheme}>
      <AuthenticatedTemplate>
        <StartPage>
          <h1 className={classes.headingTxt}>Letter of Good Standing</h1>
          <Box sx={{ p: 2 }}>
            <CustomButton
              variants="outlined"
              noMethod={true}
              btnOptions={btnOptions}
              onBtnupdate={onBtnupdate}
            >
              {" "}
              Letter of good standing Application
            </CustomButton>
            <Typography style={{ marginTop: "20px" }} variant="body1">
              {btnMsg}
            </Typography>
          </Box>
        </StartPage>
      </AuthenticatedTemplate>
    </ThemeProvider>
  );
}

export default LetterOfGood;
