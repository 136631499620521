import React from "react";
import PropTypes from "prop-types";
import styled, { css } from "styled-components";
import { globalSizes } from "../../theme/units";

const BaseBox = styled.div((props) => {
  return css`
    display: block;
    padding: ${globalSizes[props.padding]};
    width: ${props.boxWidth};
    height: ${props.boxHeight};
  `;
});

/**
 * ### Layout primitive component
 *
 * The `LayoutBox` component is a fixed size container which is used to create dynamic, wrapping layouts.
 *
 * LayoutBox is used as the base component for all layout components (`Stack`, `Cluster`, `Frame`, `Row`, `Switcher`)
 *
 * are all `LayoutBox`es underneath - this is so they can share the same layout primitives from the Theme.
 *
 *
 */
const LayoutBox = React.forwardRef(function Box(
  {
    children,
    className,
    element = "div",
    padding = "none",
    width = "auto",
    height = "auto",
    ...rest
  },
  ref
) {
  return (
    <BaseBox
      as={element}
      boxWidth={width}
      boxHeight={height}
      className={className}
      padding={padding}
      ref={ref}
      {...rest}
    >
      {children}
    </BaseBox>
  );
});

LayoutBox.propTypes = {
  /** The contents of the `Box` */
  children: PropTypes.node,
  /** To be used to override or extend the styles on this component. */
  className: PropTypes.string,
  /** the HTML Element to be rendered by the `Box` */
  element: PropTypes.oneOf([
    "article",
    "aside",
    "details",
    "div",
    "dd",
    "dl",
    "dt",
    "fieldset",
    "footer",
    "header",
    "label",
    "legend",
    "nav",
    "ol",
    "section",
    "ul",
    "li",
    "span",
    "form",
  ]),
  /** Analogous to CSS padding, **padding** applies a gap of the specified width between the outer border of the `Box` element and its contents.
   *
   * This string can take the following forms:
   *
   * * (preferably) A string value from the `globalSizes` constants which applies to all four sides of the `Box`.
   * * A single value (eg`1rem`) which is applied to the top, bottom, left and right sides of the `Box`.
   *
   */
  padding: PropTypes.oneOf(Object.keys(globalSizes)),
  /** the height of the `Box` can either be a CSS value (eg `5rem`) or a friendly size from the list of friendly sizes */
  height: PropTypes.string,
  /** the width of the `Box` */
  width: PropTypes.string,
};

export default LayoutBox;
