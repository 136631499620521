export const getlistPayload = {
  properties: ["ctpt_typeofemployment"],
  formattedFields: [],
  filter: {
    globalfilter: "",
    globalAndOperator: false,
    fieldAndOperator: false,
    filterfields: [
      {
        field: "ctpt_employeeid",
        searchvalue: "",
        type: "guid",
      },
      {
        field: "ctpt_employerid",
        searchvalue: "533910003",
        type: "guid",
      },
    ],
  },
  Aggregate: {
    AggregateFields: [],
  },
  recordtype: "ctpt_professionalhistory",
  idfield: "ctpt_employeeid",
  continuationToken: "",
  pazesize: "10",
};

export const getEmployerDetailsPayload = (contactID) => ({
  properties: [
    "ctpt_professionalhistoryid",
    "_ctpt_employeeid_value",
    "ctpt_typeofemployment",
    "_ctpt_employerid_value",
  ],
  formattedFields: [],
  filter: {
    globalfilter: "",
    globalAndOperator: false,
    fieldAndOperator: true,
    filterfields: [
      {
        field: "_ctpt_employeeid_value",
        searchvalue: contactID,
        type: "lookup",
        relatedentity: "contacts",
      },
      {
        field: "ctpt_typeofemployment",
        searchvalue: 533910003,
        type: "picklist",
      },
    ],
  },
  Aggregate: {
    AggregateFields: [],
  },
  recordtype: "ctpt_professionalhistories",
  idfield: "ctpt_professionalhistoryid",
  continuationToken: "",
  pazesize: "10",
});

export const getStudentPlacementDetailsPayload = (employerIDs) => ({
  properties: [
    "ctpt_studentplacementid",
    "_ctpt_company_value",
    "_ctpt_studentid_value",
    "statuscode",
  ],
  formattedFields: [],
  filter: {
    globalfilter: "",
    globalAndOperator: false,
    fieldOrOperator: true,
    filterfields: employerIDs.map((id) => ({
      field: "_ctpt_company_value",
      searchvalue: id,
      type: "lookup",
      relatedentity: "contacts",
    })),
  },
  Aggregate: {
    AggregateFields: [],
  },
  recordtype: "ctpt_studentplacements",
  idfield: "ctpt_studentplacementid",
  continuationToken: "",
  pazesize: "10",
});

export const getStudentDetailsPayload = (studentIDs) => ({
  properties: ["contactid", "fullname"],
  formattedFields: [],
  filter: {
    globalfilter: "",
    globalAndOperator: false,
    fieldOrOperator: true,
    filterfields: studentIDs.map((x) => ({
      field: "contactid",
      searchvalue: x._ctpt_studentid_value,
      type: "guid",
    })),
  },
  Aggregate: {
    AggregateFields: [],
  },
  recordtype: "contacts",
  idfield: "contactid",
  continuationToken: "",
  pazesize: "10",
});

// ({
//   properties: [
//     "contactid",
//     "salutation",
//     "firstname",
//     "lastname",
//     "fullname",
//     "ctpt_othername",
//     "birthdate",
//     "fax",
//     "ctpt_isresidentofbotswana",
//   ],
//   formattedFields: ["gendercode"],
//   recordType: "contacts",
//   id: id,
// });
