import React from "react";
import PropTypes from "prop-types";
import styled, { css } from "styled-components";
import Box from "../LayoutBox";
import { globalSizes, justifyMap, alignMap } from "../../theme/units";

const Container = styled(Box)((props) => {
  const $justify =
    props.justify === "stretch" &&
    css`
      > * {
        flex-grow: 1;
      }
    `;
  const $splitAfter =
    props.splitAfter &&
    css`
      & > :nth-child(${props.splitAfter}) {
        margin-bottom: auto;
      }
    `;

  return css`
    display: flex;
    flex-direction: column;
    justify-content: ${justifyMap[props.justify]};
    align-items: ${alignMap[props.align]};

    ${$justify}

    > * + * {
      margin-top: ${globalSizes[props.gap]};
    }

    ${$splitAfter}
  `;
});

/**
 * ### Layout primitive component
 *
 * The **Stack** is a container component that displays its children along a single (vertical) dimension.
 *
 * The `align` and `justify` props can be used to position child contents relative to the **Stack** direction.
 *
 * the `splitAfter` prop allows the stack to be broken into two sections (top and bottom) with a variable space
 *
 * divider.
 */

const Stack = React.forwardRef(function Stack(
  {
    children,
    align = "stretch",
    className,
    element = "div",
    gap = "none",
    height = "auto",
    justify = "start",
    padding = "none",
    splitAfter = null,
    width = "auto",
    ...rest
  },
  ref
) {
  return (
    <Container
      align={align}
      className={className}
      element={element}
      gap={gap}
      height={height}
      justify={justify}
      padding={padding}
      splitAfter={splitAfter}
      width={width}
      ref={ref}
      {...rest}
    >
      {children}
    </Container>
  );
});

Stack.propTypes = {
  /** Any components to subscribe to the flex styling set by Stack. */
  children: PropTypes.node,
  /** Will align the contents on the axis perpendicular to the direction.
   *
   *  choose from : [  start | end |center | stretch ] */
  align: PropTypes.oneOf(Object.keys(alignMap)),
  /** To be used to override or extend the styles on this component. */
  className: PropTypes.string,
  /** Defines the type of container element rendered to the dom. */
  element: PropTypes.string,
  /** gap between elements should take one of our globalSize constants
   *
   *  choose from : [ none | smallest | tiny | xxs | xs | small | base | medium | large | xl | xxl | largest ] */
  gap: PropTypes.oneOf(Object.keys(globalSizes)),
  /** height of the Stack (arbitrary value ie: 'auto', '100%', '40rem' etc) */
  height: PropTypes.string,
  /** Will justify the contents along the axis of the direction.
   *
   * choose from : [  start | end | center  | between | around | evenly ]*/
  justify: PropTypes.oneOf(Object.keys(justifyMap)),
  /** gap between elements should take one of our globalSize constants
   *
   *  choose from : [ none | smallest | tiny | xxs | xs | small | base | medium | large | xl | xxl | largest ] */
  padding: PropTypes.oneOf(Object.keys(globalSizes)),
  /** Break the stack into two sections after this child (number) This action is provided by adding `margin-bottom:auto` to the element referenced by the `splitAfter` prop*/
  splitAfter: PropTypes.string,
  /** width of the Stack (arbitrary value ie: 'auto', '100%', '40rem' etc) */
  width: PropTypes.string,
};

export default Stack;
