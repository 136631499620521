import React from "react";
import { AuthenticatedTemplate } from "@azure/msal-react";
import { ThemeProvider } from "@material-ui/core/styles";
import { globalTheme } from "style/globalTheme";
import styled from "styled-components";
import ErrorIcon from "@material-ui/icons/Warning";
import { useHistory } from "react-router-dom";
import { useStyles } from "./style";
import { Button, Box } from "@material-ui/core";
import { ReactComponent as ErrorSVG } from "../../../assets/error.svg";
import { footerItems } from "app/components/molecules/navbar/model/sideNavData";

const StartPage = styled.section`
  ${globalTheme.breakpoints.up("sm")} {
    padding: 4rem 4rem 4rem 300px;
  }
  ${globalTheme.breakpoints.down("xs")} {
    padding: 1rem;
  }
`;

function NoAccess(props) {
  const classes = useStyles();
  let history = useHistory();
  return (
    <ThemeProvider theme={globalTheme}>
      <AuthenticatedTemplate>
        <StartPage>
          <h1 className={classes.headingTxt}>
            Sorry, you do not have permissions to view this page.​
          </h1>

          <div className={classes.desc}>
            <p></p>
            <ul>
              <li>Your are not allowed to view this page.​</li>
              <li>
                Try refreshing your browser, if the problem persists please{" "}
                <a
                  href={footerItems.verticalSections[1].items[3].linkUrl}
                  target="_blank"
                  rel="noreferrer"
                >
                  contact us.
                </a>
                ​
              </li>
            </ul>
          </div>

          <Box sx={{ p: 5 }}>
            <Button
              color="primary"
              variant="outlined"
              onClick={() => history.push("/")}
            >
              {" "}
              Go Back to Homepage​
            </Button>
          </Box>
        </StartPage>
      </AuthenticatedTemplate>
    </ThemeProvider>
  );
}

export default NoAccess;
