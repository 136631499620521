import { makeStyles } from "@material-ui/core/styles";
import { brandPalette } from "brand/theme/brandTheme";

export const useStyles = makeStyles((theme) => ({
  detailsSection: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    //justifyContent: "center",
    alignItems: "center",
    marginTop: "0rem",
    overflow: "visible",
    margin: "0 auto",
    "& .header": {
      fontSize: "1.9rem",
    },
  },
  documentSection: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
    marginTop: "0rem",
    //justifyContent: "center",
    alignItems: "center",
    //justifyContent: "center",
    "& .header": {
      fontSize: "1.9rem",
    },
  },
  speakerSection: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
    marginTop: "0rem",
    //justifyContent: "center",
    alignItems: "center",
    //justifyContent: "center",
    "& .header": {
      fontSize: "1.9rem",
    },
    "& h2": {
      margin: "0 0 0 0",
      padding: "1.8rem 1.5rem",
      fontSize: "1.6rem",
      overflow: "visible",
    },
    "& .card": {
      margin: "0.5rem",
      paddingLeft: "1rem",
      display: "flex",
      minWidth: "100%",
      //justifyContent: "center",
      alignItems: "center",
      position:"relative",
      backgroundColor: "rgba(196, 196, 196, 0.08)",

      "& .card-heading": {
        fontSize: "1rem",
        fontWeight:"bold",
        paddingLeft: "1rem",
        margin: 0,
      },
      "& .card-data": {
        margin: 0,
        paddingLeft: "1rem",
        maxWidth:"370px"
      },
      "& .card-data-div": {
        height: "70px",
        overflow:"hidden",
        textOverflow:"ellipsis"
      },
      "& div[class^=MuiAvatar-root]": {
        marginLeft: "0rem",
        position:"absolute",
        top:"0",
        left:"0px"
      },
      "& .card-content": {
        //textAlign: "start",
        marginLeft: "0.3rem",
      },
    },
  },
  registerBtnTop: {
    margin: "0 0 2rem 30rem",
    width: "8rem",
    minHeight: "1.5rem",
    fontSize: "0.8rem",
  },
  registerBtn: {
    marginTop: "1rem",
    width: "12rem",
    minHeight: "3rem",
    fontSize: "1.1rem",
  },
  detailTable: {
    listStyle: "none",
    margin: "0rem auto",
    display: "flex",
    flexDirection: "column",
  },
  listItem: {
    //width: "100%",
    padding: ".6rem 0rem",
    display: "flex",
    alignItems: "left",
    "& .key": {
      minWidth: "10rem",
      display: "inline-block",
      fontWeight: "bold",
    },
    "& .value": {
      minWidth: "8rem",
    },
  },
  itemStyle: {
    [theme.breakpoints.down("sm")]: {
      order: 2,
    },
  },
  middleSection: {
    margin: "0rem 0 3rem 0",
    //minHeight: "40vh",
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
    //alignItems: "center",
  },
  modalClose: {
    float: "right",
  },
  paper: {
    position: "absolute",
    width: "800px",
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    borderRadius: "3px",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    maxHeight: "90vh",
    overflowY: "scroll",

    [theme.breakpoints.down("sm")]: {
      width: "550px",
    },

    [theme.breakpoints.down("xs")]: {
      width: "400px",
    },
  },
  sessionList: {
    listStyle: "none",
    padding: "1rem",
    margin: "1rem",
    "& li": {
      padding: "1rem 0",
    },
    "& li .key": {
      fontWeight: "bold",
      display: "inline-block",
      minWidth: "6rem",
    },
    "& > div > footer": {
      display: "none",
    },
    "& > div ": {
      maxHeight: "22rem",
      minWidth: "17rem",
    },
    "& h3[class^='MuiTypography-root']": {
      padding: "4rem",
    },
    "& div[class*=MuiPaper-outlined]": {
      border: "none",
    },
  },
  sessionContainer: {
    marginTop: "0rem",
    padding: "0rem 0 0 0",
    paddingBottom: "2rem",
    // backgroundImage: `linear-gradient(to right, ${brandPalette["Primary"][100]}, ${brandPalette["Primary"][400]})`,
    //clipPath: "polygon(0 20%, 100% 0, 100% 80%, 0 100%)",
  },
  sessionHeader: {
    textAlign: "center",
    marginBottom: "0.5rem",
    fontSize: "1.9rem",
  },
  sessionItems: {
    display: "flex",
    justifyContent: "center",
    flexWrap: "wrap",
  },
  center: {
    textAlign: "center",
  },
  docList: {
    listStyle: "none",
    margin: "1.2rem 0",
    padding: 0,
    border: "1px solid rgba(0, 0, 0, 0.1)",
    width: "50%",

    "& .doc-item": {
      display: "flex",
      alignItems: "center",
      height: "max-content",
      margin: "1rem 0",
      justifyContent: "center",
    },
  },

  docLink: {
    "&:link, &:visited": {
      fontSize: "1rem",
      color: brandPalette.Primary[500],
      display: "inline-block",
      textDecoration: "none",
      borderRadius: "0rem",
      borderBottom: `1px solid ${brandPalette.Primary[500]}`,
      padding: "3px",
      transition: "all 0.2s",
    },
    "&:hover": {
      backgroundColor: brandPalette.Primary[600],
      color: "white",
      boxShadow: "0 1rem 2rem rgba(0,0,0, 0.15)",
      borderRadius: "100px",
      paddingRight: "1rem",
      paddingLeft: "1rem",
      paddingTop: "3px",
      paddingBottom: "3px",
    },

    "&:active": {
      boxShadow: "0 0.5rem 1rem rgba(0,0,0, 0.15)",
      transform: "translateY(0)",
    },
  },
  speakers: {
    backgroundColor: brandPalette["Primary"][900],
    height: "100%",
    boxSizing: "border-box",
    color: "rgba(255, 255, 255, 0.8)",
    textAlign: "left",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    paddingBottom: "0rem",
    overflow: "hidden",
    "& h2": {
      margin: "0 0 0 0",
      padding: "1.8rem 1.5rem",
      fontSize: "1.6rem",
      overflow: "visible",
    },
    "& .card": {
      padding: "0.5rem",
      paddingLeft: "0rem",
      display: "flex",
      minWidth: "100%",
      //justifyContent: "center",
      alignItems: "left",
      backgroundColor: "rgba(196, 196, 196, 0.08)",

      "& .card-heading": {
        fontSize: "1rem",
        padding: 0,
        margin: 0,
      },
      "& .card-data": {
        margin: 0,
        padding: 0,
      },
      "& div[class^=MuiAvatar-root]": {
        marginLeft: "0rem",
      },
      "& .card-content": {
        //textAlign: "start",
        marginLeft: "0rem",
      },
    },
  },
  avatar: {
    width: "2rem",
    height: "2rem",
  },
}));
