import Upload from "app/components/atoms/upload";
import CustomTextBox from "app/components/atoms/customTextBox";
import CustomCheckBox from "app/components/atoms/customCheckBox";
import CustomRadio from "app/components/atoms/customRadio";
import CustomDate from "app/components/atoms/customDate";
import CustomSelect from "app/components/atoms/customSelect";

const CustomUpload = ({ getProps }) => {
  return (
    <Upload
      recordType={getProps.schema.containerName}
      recordPath={getProps.schema.recordPath}
      getProps={getProps}
    />
  );
};

export const uiSchema = {
  "ui:title": "",
  title: {
    "ui:widget": (props) => {
      return <CustomTextBox getProps={props} />;
    },
  },
  firstname: {
    "ui:widget": (props) => {
      return <CustomTextBox getProps={props} />;
    },
  },
  lastname: {
    "ui:widget": (props) => {
      return <CustomTextBox getProps={props} />;
    },
  },
  address: {
    line1: {
      "ui:widget": (props) => {
        return <CustomTextBox getProps={props} />;
      },
    },
    line2: {
      "ui:widget": (props) => {
        return <CustomTextBox getProps={props} />;
      },
    },
    city: {
      "ui:widget": (props) => {
        return <CustomTextBox getProps={props} />;
      },
    },
    district: {
      "ui:widget": (props) => {
        return <CustomSelect getProps={props} />;
      },
    },
    postalCode: {
      "ui:widget": (props) => {
        return <CustomTextBox getProps={props} />;
      },
    },
    country: {
      "ui:widget": (props) => {
        return <CustomTextBox getProps={props} />;
      },
    },
  },
  telephonenumber: {
    "ui:widget": (props) => {
      return <CustomTextBox getProps={props} />;
    },
    "ui:options": {
      prefix: "+267"
    }
  },
  emailaddress: {
    "ui:widget": (props) => {
      return <CustomTextBox getProps={props} />;
    },
  },
  birthdate: {
    "ui:widget": (props) => {
      return <CustomDate getProps={props} />;
    },
  },
  studentphoto: {
    "ui:widget": (props) => {
      return <CustomUpload getProps={props} />;
    },
  },
  nationality: {
    "ui:widget": (props) => {
      return <CustomSelect getProps={props} />;
    },
  },
  addAnotherNationality: {
    items: {
      nationality: {
        "ui:widget": (props) => {
          return <CustomSelect getProps={props} />;
        },
      },
    },
  },
  tuitionProvider: {
    accountName: {
      "ui:widget": (props) => {
        return <CustomTextBox getProps={props} />;
      },
    },
    membership: {
      "ui:widget": (props) => {
        return <CustomTextBox getProps={props} />;
      },
    },
  },
  bicaNumberConditional: {
    BicaNumber: {
      "ui:widget": (props) => {
        return <CustomTextBox getProps={props} />;
      },
    },
  },
  secondaryeducation: {
    level: {
      "ui:widget": (props) => {
        return <CustomSelect getProps={props} />;
      },
    },
    secondaryEducationTitle: {
      "ui:widget": (props) => {
        return <CustomTextBox getProps={props} />;
      },
    },
    grade: {
      "ui:widget": (props) => {
        return <CustomTextBox getProps={props} />;
      },
    },
    yearofaward: {
      "ui:widget": (props) => {
        return <CustomDate getProps={props} />;
      },
    },
    educationCertificate:{
      "ui:widget": (props) => {
        return <CustomUpload getProps={props} />;
      },
    },
    country: {
      "ui:widget": (props) => {
        return <CustomTextBox getProps={props} />;
      },
    },
  },
  addAnotherSecondaryEducation: {
    items: {
      level: {
        "ui:widget": (props) => {
          return <CustomSelect getProps={props} />;
        },
      },
      secondaryEducationTitle: {
        "ui:widget": (props) => {
          return <CustomTextBox getProps={props} />;
        },
      },
      grade: {
        "ui:widget": (props) => {
          return <CustomTextBox getProps={props} />;
        },
      },
      yearofaward: {
        "ui:widget": (props) => {
          return <CustomDate getProps={props} />;
        },
      },
      country: {
        "ui:widget": (props) => {
          return <CustomTextBox getProps={props} />;
        },
      },
    },
  },
  qualification: {
    qualificationType: {
      "ui:widget": (props) => {
        return <CustomSelect getProps={props} />;
      },
    },
    classgrade: {
      "ui:widget": (props) => {
        return <CustomTextBox getProps={props} />;
      },
    },
    yearofaward: {
      "ui:widget": (props) => {
        return <CustomDate getProps={props} />;
      },
    },
    qualificationCertificate:{
      "ui:widget": (props) => {
        return <CustomUpload getProps={props} />;
      },
    },
    universityName: {
      "ui:widget": (props) => {
        return <CustomTextBox getProps={props} />;
      },
    },
    courseType: {
      "ui:widget": (props) => {
        return <CustomSelect getProps={props} />;
      },
    },
    country: {
      "ui:widget": (props) => {
        return <CustomTextBox getProps={props} />;
      },
    },
  },
  addAnotherQualification: {
    items: {
      qualificationType: {
        "ui:widget": (props) => {
          return <CustomSelect getProps={props} />;
        },
      },
      classgrade: {
        "ui:widget": (props) => {
          return <CustomTextBox getProps={props} />;
        },
      },
      yearofaward: {
        "ui:widget": (props) => {
          return <CustomDate getProps={props} />;
        },
      },
    
      universityName: {
        "ui:widget": (props) => {
          return <CustomTextBox getProps={props} />;
        },
      },
      courseType: {
        "ui:widget": (props) => {
          return <CustomSelect getProps={props} />;
        },
      },
      country: {
        "ui:widget": (props) => {
          return <CustomTextBox getProps={props} />;
        },
      },
    },
  },
  professionalQualification: {
    professionalBody: {
      "ui:widget": (props) => {
        return <CustomTextBox getProps={props} />;
      },
    },
    status: {
      "ui:widget": (props) => {
        return <CustomTextBox getProps={props} />;
      },
    },
    stagePassed: {
      "ui:widget": (props) => {
        return <CustomTextBox getProps={props} />;
      },
    },
    country: {
      "ui:widget": (props) => {
        return <CustomTextBox getProps={props} />;
      },
    },
  },
  addAnotherProfessionalQualification: {
    items: {
      professionalBody: {
        "ui:widget": (props) => {
          return <CustomTextBox getProps={props} />;
        },
      },
      status: {
        "ui:widget": (props) => {
          return <CustomTextBox getProps={props} />;
        },
      },
      stagePassed: {
        "ui:widget": (props) => {
          return <CustomTextBox getProps={props} />;
        },
      },
      country: {
        "ui:widget": (props) => {
          return <CustomTextBox getProps={props} />;
        },
      },
    },
  },
  disabilityInformationConditional: {
    disabilityInformationRadio1: {
      "ui:widget": (props) => {
        return <CustomRadio getProps={props} />;
      },
    },
    disabilityInformationRadio2: {
      "ui:widget": (props) => {
        return <CustomRadio getProps={props} />;
      },
    },
  },
  documentaryCheckBox: {
    "ui:widget": (props) => {
      return <CustomCheckBox getProps={props} />;
    },
  },
  bicaTrainingContractCheckBox: {
    "ui:widget": (props) => {
      return <CustomCheckBox getProps={props} />;
    },
  },
};
