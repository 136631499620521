import React, { useEffect, useState } from "react";
import { AuthenticatedTemplate } from "@azure/msal-react";
import { ThemeProvider } from "@material-ui/core/styles";
import { globalTheme } from "style/globalTheme";
import { config } from "utilities/config";
import {
  getFormschemapayload,
  createFormsubmitpayload,
  getFormsubmitpayload,
} from "data/payload";
import Sonicwebform from "app/components/mvp/sonicwebform";
import { uiSchema } from "./model/uiSchema";
import styled from "styled-components";
import { Button, Grid } from "@material-ui/core";
import CreateOrder from "brand/components/order";
import { break_addressbar } from "utilities/splitUrl";

const StartPage = styled.section`
  ${globalTheme.breakpoints.up("sm")} {
    padding: 4rem 4rem 4rem 300px;
  }
  ${globalTheme.breakpoints.down("xs")} {
    padding: 1rem;
  }
`;

function ExemptionCertificate(props) {
  const [isStarted, setStartApplication] = useState(0);
  const [isNew, setisNew] = useState(true);
  const [urlParam, seturlParam] = useState({
    formName: "exemptionCertificate",
    subId: "",
  });
  const options = {
    formTitle: "Exemption Certificate Application",
    getFormschemaURL: config.sonicwebform.getFormschema,
    createFormsubmitURL: config.sonicwebform.createFormsubmit,
    getFormsubmitURL: config.sonicwebform.getFormsubmit,
    getFormschemapayload: getFormschemapayload,
    createFormsubmitpayload: createFormsubmitpayload,
    getFormsubmitpayload: getFormsubmitpayload,
    WebFormName: urlParam.formName,
    uiSchema: uiSchema,
    submissionId: urlParam.subId,
    stepCount: 0,
    formschema: {},
    swfdata: {},
    swfId: "",
    createForm: isNew,
    unAuthenticate: false,
    handleChange: props.handleChange,
    emptyOrder: false,
    hasPayment: false,
    applicationtype: "exemptionCertificate",
  };

  const startApp = () => {
    setStartApplication(1);
  };
  useEffect(() => {
    localStorage.setItem("oid", "");
    if (window.location.href.indexOf("?") > -1) {
      seturlParam(break_addressbar(window.location.href));
      console.log(break_addressbar(window.location.href));
      setisNew(false);
    } else {
      setisNew(true);
    }
  }, []);

  return (
    <ThemeProvider theme={globalTheme}>
      <AuthenticatedTemplate>
        <div id="cTTP_sonicForm">
          <Grid container spacing={0}>
            <Grid item xs={12} md={12}>
              {/* <Sonicwebform options={options} /> */}
              {isStarted === 0 ? (
                <StartPage>
                  <h1>Learning Credit(Exemption) Application</h1>
                  <h3>For those with qualifications listed in CPL Directory</h3>
                  <p>How to apply:</p>
                  <ul>
                    <li>
                      Make sure you are registered as an independent student or
                      are BICA student with a training agreement.
                    </li>
                    <li>
                      Your submitted application will be reviewed by BICA and
                      you will be notified on the credit(s) awarded and the
                      payment process.
                    </li>
                  </ul>
                  <p>
                    Note: The award of credit is entirely at the discretion of
                    BICA.
                  </p>

                  <br />
                  <br />
                  <Button
                    color="primary"
                    variant="contained"
                    onClick={startApp}
                  >
                    {isNew ? "Start Application" : "Resume Application"}
                  </Button>
                </StartPage>
              ) : (
                <div>
                  <Sonicwebform options={options} />
                </div>
              )}
            </Grid>
          </Grid>
        </div>
      </AuthenticatedTemplate>
    </ThemeProvider>
  );
}

export default CreateOrder(ExemptionCertificate);
