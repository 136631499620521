import { makeStyles } from "@material-ui/core/styles";
import { brandPalette } from "brand/theme/brandTheme";

export const useStyles = makeStyles((theme) => ({
  root: {},
  headingTxt: {
    color: theme.palette.warning.light,
  },
  errorIcon: {
    color: "red",
  },
  desc: {
    background: theme.palette.midlightBg.main,
    padding: "10px",
    maxWidth: "45rem",
  },
  alert: {
    padding: theme.spacing(3),
    margin: theme.spacing(2.5),
    marginTop: theme.spacing(10),
    marginLeft: 0,
    border: `1px solid ${brandPalette["Analogous-1"]["600"]}`,
    borderWidth: "1px",
    display: "flex",
  },
  errorAlert: {
    border: `1px solid ${brandPalette["Triadic-2"]["300"]}`,
  },
}));
