//Please note new menu structure MUST BE ONLY ONE LEVEL!

export const appMenuItems = [
  {
    name: "Applications",
    items: [
      { name: "Manage Existing Applications", link: "/applications" },
      {
        name: "New Individual Application​",
        link: "/application/individualMembership",
      },
      {
        name: "New Firm Application (Professional)​",
        link: "/application/firmMembership_nonaudit",
      },
      {
        name: "New Firm Application (ACCTECH)​",
        link: "/application/firmMembership_nonaat",
      },
      {
        name: "New Student Application​",
        link: "/application/studentApplication",
      },
      {
        name: "New Tuition Provider Application​",
        link: "/application/tutionProvider",
      },

      {
        name: "Apply for learning credit (exemption)​",
        link: "/application/exemptionCertificate",
      },
    ],
  },
  {
    name: "Membership",
    items: [
      { name: "Upgrade Now", link: "/members/upgrade" },
      {
        name: "Request a Duplicate Certificate​",
        link: "/application/duplicateCertificate",
      },
      { name: "Request a Letter of Good Standing​", link: "/members/letter" },
      {
        name: "Apply for Clearance",
        link: "/application/clearanceCertificate",
      },
      // {
      //   name: "Request a Support Service​",
      //   link: "/membership/service-requests",
      // }
    ],
  },
  {
    name: "Apprenticeship",
    items: [
      {
        name: "New Training Employer Application​",
        link: "/application/trainingemployer",
      },
      {
        name: "Training Agreement",
        link: "/membership/student-training-agreement",
      },
      {
        name: "Student Placement",
        link: "/student-placement",
      },
    ],
  },

  {
    name: "Account",
    items: [
      {
        name: "Invoices & Payments",
        link: "/invoices",
      },
    ],
  },
  {
    name: "Registration",
    items: [
      {
        name: "Event Registration​",
        link: "/event/registration",
      },
      { name: "Exam Registration​", link: "/exam/register" },
      {
        name: "Exam Reports​",
        link: "/exam/reports",
      },
      {
        name: "Exam Cerificates",
        link: "/exam/certificates",
      },
    ],
  },
  {
    name: "Development (CPD)​",
    items: [{ name: "Manage Declarations​", link: "/cpd/declaration" }],
  },
];
