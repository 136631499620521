import React, { useEffect, useState } from "react";
import { AuthenticatedTemplate } from "@azure/msal-react";
import { ThemeProvider } from "@material-ui/core/styles";
import { globalTheme } from "style/globalTheme";
import { config } from "utilities/config";
import {
  getFormschemapayload,
  createFormsubmitpayload,
  getFormsubmitpayload,
} from "data/payload";
import Sonicwebform from "app/components/mvp/sonicwebform";
import { uiSchema } from "./model/uiSchema";
import styled from "styled-components";
import { Button, Grid } from "@material-ui/core";
import CreateOrder from "brand/components/order";
import { break_addressbar } from "utilities/splitUrl";

const StartPage = styled.section`
  ${globalTheme.breakpoints.up("sm")} {
    padding: 4rem 4rem 4rem 10rem;
    max-width: 65%;
  }
  ${globalTheme.breakpoints.down("xs")} {
    padding: 1rem;
    max-width: 100%;
  }
`;

function UpgradeFellow(props) {
  const [isStarted, setStartApplication] = useState(0);
  const [isNew, setisNew] = useState(true);
  const [urlParam, seturlParam] = useState({
    formName: "upgradeFellow",
    subId: "",
  });
  const options = {
    formTitle: "Membership Upgrade Application",
    getFormschemaURL: config.sonicwebform.getFormschema,
    createFormsubmitURL: config.sonicwebform.createFormsubmit,
    getFormsubmitURL: config.sonicwebform.getFormsubmit,
    getFormschemapayload: getFormschemapayload,
    createFormsubmitpayload: createFormsubmitpayload,
    getFormsubmitpayload: getFormsubmitpayload,
    WebFormName: urlParam.formName,
    uiSchema: uiSchema,
    submissionId: urlParam.subId,
    stepCount: 0,
    formschema: {},
    swfdata: {},
    swfId: "",
    createForm: isNew,
    unAuthenticate: false,
    handleChange: props.handleChange,
    emptyOrder: false,
    hasPayment: true,
    applicationtype: "membershipUpgrade",
  };

  const startApp = () => {
    setStartApplication(1);
  };
  useEffect(() => {
    localStorage.setItem("oid", "");
    console.log("onload " + window.location.href.indexOf("?"));
    if (window.location.href.indexOf("?") > -1) {
      seturlParam(break_addressbar(window.location.href));
      console.log(break_addressbar(window.location.href));
      setisNew(false);
    } else {
      setisNew(true);
    }
  }, []);

  useEffect(() => {
    console.log("url parameters" + JSON.stringify(urlParam));
  }, [urlParam]);

  return (
    <ThemeProvider theme={globalTheme}>
      <AuthenticatedTemplate>
        <div id="cTTP_sonicForm">
          <Grid container spacing={0}>
            <Grid item xs={12} md={12}>
              {/* <Sonicwebform options={options} /> */}
              {isStarted === 0 ? (
                <StartPage>
                  <h1>
                    Membership application upgrade from Accounting Technician to
                    Fellow
                  </h1>
                  <p
                    style={{ maxWidth: "70%", fontWeight: 600, marginTop: "2rem" }}
                  >
                    Kindly NOTE that you will be required to attach the following
                    documents during the application.
              </p>
                  <ul style={{ maxWidth: "70%", paddingLeft: "1rem" }}>
                    <li style={{ margin: 0, padding: 0 }}>
                      Certified copy of membership certificate of your parent body.
                      (date of certification must not exceed 12 months)
                </li>
                    <li>
                      Letter of good standing from your parent body (the date of the
                      letter must not exceed 6 months)
                </li>
                  </ul>

                  <br />
                  <br />
                  <div style={{ marginLeft: "1.8rem" }}>
                    <Button
                      color="primary"
                      variant="contained"
                      onClick={startApp}
                    >
                      {isNew ? "Start Application" : "Resume Application"}
                    </Button>
                  </div>
                </StartPage>
              ) : (
                <div>
                  <Sonicwebform options={options} />
                </div>
              )}
            </Grid>
          </Grid>
        </div>
      </AuthenticatedTemplate>
    </ThemeProvider>
  );
}

export default CreateOrder(UpgradeFellow);
