import Upload from "app/components/atoms/upload";
import CustomTextBox from "app/components/atoms/customTextBox";
import CustomCheckBox from "app/components/atoms/customCheckBox";
import CustomDate from "app/components/atoms/customDate";
import CustomSelect from "app/components/atoms/customSelect";

const CustomUpload = ({ getProps }) => {
  return (
    <Upload
      recordType={getProps.schema.containerName}
      recordPath={getProps.schema.recordPath}
      getProps={getProps}
    />
  );
};

export const uiSchema = {
  "ui:title": "",
  nameOfOrganisation: {
    "ui:widget": (props) => {
      return <CustomTextBox getProps={props} />;
    },
  },
  nameOfContact: {
    "ui:widget": (props) => {
      return <CustomTextBox getProps={props} />;
    },
  },
  jobTitle: {
    "ui:widget": (props) => {
      return <CustomTextBox getProps={props} />;
    },
  },
  postalAdress: {
    "ui:widget": (props) => {
      return <CustomTextBox getProps={props} />;
    },
  },
  physicalAdress: {
    "ui:widget": (props) => {
      return <CustomTextBox getProps={props} />;
    },
  },
  telephone: {
    "ui:widget": (props) => {
      return <CustomTextBox getProps={props} />;
    },
  },
  cellphone: {
    "ui:widget": (props) => {
      return <CustomTextBox getProps={props} />;
    },
  },
  email: {
    "ui:widget": (props) => {
      return <CustomTextBox getProps={props} />;
    },
  },
  applicantName: {
    "ui:widget": (props) => {
      return <CustomTextBox getProps={props} />;
    },
  },
  passportNum: {
    "ui:widget": (props) => {
      return <CustomTextBox getProps={props} />;
    },
  },
  expiryDate: {
    "ui:widget": (props) => {
      return <CustomDate getProps={props} />;
    },
  },
  nationality: {
    "ui:widget": (props) => {
      return <CustomSelect getProps={props} />;
    },
  },
  professionalExam: {
    "ui:widget": (props) => {
      return <CustomTextBox getProps={props} />;
    },
  },
  ifacREcognised: {
    "ui:widget": (props) => {
      return <CustomTextBox getProps={props} />;
    },
  },
  explanation: {
    "ui:widget": (props) => {
      return <CustomTextBox getProps={props} />;
    },
  },
  coverLetter: {
    "ui:widget": (props) => {
      return <CustomUpload getProps={props} />;
    },
  },
  resume: {
    "ui:widget": (props) => {
      return <CustomUpload getProps={props} />;
    },
  },
  examCertificate: {
    "ui:widget": (props) => {
      return <CustomUpload getProps={props} />;
    },
  },
  memberCertificate: {
    "ui:widget": (props) => {
      return <CustomUpload getProps={props} />;
    },
  },
  letterOfgood: {
    "ui:widget": (props) => {
      return <CustomUpload getProps={props} />;
    },
  },
  passportCopy: {
    "ui:widget": (props) => {
      return <CustomUpload getProps={props} />;
    },
  },
  declareCheckBox:{
          "ui:widget": (props) => {
            return <CustomCheckBox getProps={props} />;
          },
        },
      }
   
