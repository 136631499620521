import React from "react";
import { AuthenticatedTemplate } from "@azure/msal-react";
import { ThemeProvider } from "@material-ui/core/styles";
import { globalTheme } from "style/globalTheme";
import { useSelector } from "react-redux";
import ListWidget from "app/components/mvp/listWidget";
import { getlistPayload } from "./model/payload";
import { config } from "utilities/config";
import { getStudentSchema } from "./model/studentSchema";
import { getStudentUiSchema } from "./model/UiSchema";

import {
  CreaterequestPayload,
  CreatedocPayload,
  createDocAttachmentPayload,
} from "./model/createPayload";
import { stateCodes } from "utilities/stateCodes";
import { Button, Grid } from "@material-ui/core";

function StudentService() {
  const role = useSelector((state) => state.role);
  const getcontactId = useSelector((state) => state.contactid);
  const getcontactName = useSelector((state) => state.user);
  const viewRecord = (params) => {
    window.location.href = "/members/viewrequest?id=" + params.row.ctpt_caseid;
  };
  const dueColumns = [
    {
      field: "ctpt_title",
      flex: 1,
      headerName: "Case Name",
      headerClassName: "super-app-theme--header",
    },
    {
      field: "statuscode",
      flex: 0.5,
      headerName: "Status",
      renderCell: (params) => (
        <div>
          {params.row.statuscode !== null
            ? stateCodes.caseTypes[params.row.statuscode]
            : ""}
        </div>
      ),
      headerClassName: "super-app-theme--header",
    },
    {
      field: "createdon",
      flex: 1,
      headerName: "Case created on",
      renderCell: (params) => (
        <div>
          {params.row.createdon !== null
            ? new Date(params.row.createdon).toISOString().substring(0, 10)
            : ""}
        </div>
      ),
      headerClassName: "super-app-theme--header",
    },
    {
      field: "modifiedon",
      flex: 1,
      headerName: "Last updated",
      renderCell: (params) => (
        <div>
          {params.row.modifiedon !== null
            ? new Date(params.row.modifiedon).toISOString().substring(0, 10)
            : ""}
        </div>
      ),
      headerClassName: "super-app-theme--header",
    },
    {
      field: "ctpt_caseid",
      flex: 1,
      headerName: " ",
      renderCell: (params) => (
        <div>
          <Button
            variant="text"
            color="primary"
            className="primaryBtn"
            onClick={() => {
              viewRecord(params);
            }}
          >
            View Request
          </Button>
        </div>
      ),
      headerClassName: "super-app-theme--header",
    },
  ];

  const configuration = {
    apiURL: config.teleportal.file.list,
    apiUrlDel: config.teleportal.file.delete,
    columns: dueColumns,
    searchPayload: "searchFilter",
    getlistPayload: getlistPayload,
    deleteListPayload: {},
    listHeading: "Service Requests",
    formwidgetOptions: {
      widgetType: "add",
      formSchema: getStudentSchema(getcontactId),
      uiSchema: getStudentUiSchema,
      apiURL: config.teleportal.submitForm.Create,
      entityType: "ctpt_cases",
      formUischema: "",
      button1Label: "Request",
      button2Label: "Cancel",
      button3Label: "Clear",
      payloadSubmit: CreaterequestPayload,
      columns: [],
      contactId: getcontactId,
      contactName: getcontactName.name,
      historyUrl: "",
      payloadSecondform: CreatedocPayload,
      isMemberDeclaration:false,
      payloadThirdForm: createDocAttachmentPayload,
      hasMultiplecall: true,
      hasModel: true,
    },
    nonRecord: false,
    role: role,
    rows: "",
    localDataStore: false,
    hideControls: false,
    hasAggregate: false,
    staticTable: false,
    staticRow: [],
    hiddenColumn: {},
    contactId: getcontactId,
    filteredValue: "",
    hasFilter: false,
    filterField: "_ctpt_customerid_value",
    filterValue: [],
    aggregateColumns: [],
    aggregateField: "",
    hasPayloadfilter: true,
    hideDelete: false,
    hideSearch: false,
    addText: "Add new request",
  };

  return (
    <ThemeProvider theme={globalTheme}>
      <AuthenticatedTemplate>
        <Grid container spacing={0}>
          <Grid
            item
            xs={12}
            md={12}
            style={{ marginTop: "20px", minHeight: "50rem" }}
          >
            <ListWidget options={configuration} />
          </Grid>
        </Grid>
      </AuthenticatedTemplate>
    </ThemeProvider>
  );
}

export default StudentService;
