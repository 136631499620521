export const BrandInfo = {
  brandTitle: "BICA Storybook : powered by TelePortal",
  brandUrl: "http://localhost:3000",
  brandImage: `/brand/brand-storybook.svg`,
  header: {
    title: "BICA",
    logo: "/brand/logo.jpg",
    desktop: {
      logoWidth: "150px",
      logoHeight: "77px",
    },
    mobile: {
      logoWidth: "92px",
      logoHeight: "46px",
    },
  },
};

export const socialFeeds = {
  facebook: {
    url: "https://www.facebook.com/BICABW/",
    buttonText: "Find us on Facebook",
  },
  twitter: {
    url: "https://twitter.com/bicabw",
    buttonText: "Follow us on Twitter",
  },
  linkedIn: {
    url: "https://www.linkedin.com/company/botswana-institute-of-chartered-accountants",
    buttonText: "Check us out on LinkedIn",
  },
  instagram: "https://instagram.com/BICA",
};
