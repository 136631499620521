import { Button } from "@material-ui/core";
import { InputLabel, TextField } from "@material-ui/core";
import SWFField from "app/components/atoms/swfField/SWFField.mui";
import React from "react";
import { useStyles } from "../style";
function PayThroughSponsor({
  fullName,
  email,
  emailRegex,
  emailError,
  finishPayment,
  cancelPayment,
  handleInputChange,
}) {
  const classes = useStyles();

  return (
    <div className={classes.makePayment}>
      <p>
        If you are being sponsored, please provide contact details for your
        sponsor using the boxes below. This will be reviewed by our membership
        team before your application is approved.
      </p>
      <SWFField gap="small">
        <InputLabel>Contact Name :</InputLabel>
        <TextField
          onChange={(event) => handleInputChange(event)}
          value={fullName}
          id="fullName"
        />
        <InputLabel>Email Address :</InputLabel>
        <TextField
          onChange={(event) => handleInputChange(event)}
          value={email}
          id="email"
          error={
            email.length > 0 ? (email.match(emailRegex) ? false : true) : false
          }
          helperText={emailError}
        />
      </SWFField>
      <div className={classes.buttonSection}>
        <Button
          color="primary"
          variant="outlined"
          onClick={finishPayment}
          className={classes.finishBtn}
          disabled={fullName === "" || email === "" || emailError !== ""}
        >
          Continue to next step
        </Button>
        <Button color="primary" variant="outlined" onClick={cancelPayment}>
          Cancel
        </Button>
      </div>
    </div>
  );
}

export default PayThroughSponsor;
