import { unstable_createMuiStrictModeTheme as createMuiTheme } from "@material-ui/core/styles";
import { brand } from "brand/theme/brandTheme";
import { globalSizes } from "theme/units";
// Create a theme instance.
export const globalTheme = createMuiTheme({
  typography: brand.typography,
  palette: {
    primary: {
      main: brand.palette.primary,
      light: brand.palette.primaryTint[200],
      dark: brand.palette.primaryTint[900],
    },
    primaryTint: brand.palette.primaryTint,
    secondary: {
      main: brand.palette.secondary,
      light: brand.palette.secondaryTint[200],
      dark: brand.palette.secondaryTint[900],
    },
    secondaryTint: brand.palette.secondaryTint,
    error: {
      main: brand.palette.error,
    },
    info: {
      main: brand.palette.desaturated,
    },
    warning: {
      main: brand.palette.warning,
    },
    lightBg: {
      main: "#fafafa",
    },
    midlightBg: {
      main: "#e0e0e0",
    },
    white: {
      main: "#ffffff",
    },
    black: {
      main: "#000000",
    },
  },
  spacing: 4,
  globalSizes: { ...globalSizes },
});
