export const getProcessPayload = (submissionID) => ({
  properties: [
    "ctpt_powerautomatelogid",
    "statuscode",
    "_ctpt_swfsubmissionid_value",
  ],
  filter: {
    globalfilter: "",
    globalAndOperator: false,
    fieldAndOperator: false,
    filterfields: [
      {
        field: "_ctpt_swfsubmissionid_value",
        searchvalue: submissionID,
        type: "guid",
      },
    ],
  },
  Aggregate: {
    AggregateFields: [],
  },
  sortfield: {
    field: "createdon",
    sorttype: "desc",
  },
  recordtype: "ctpt_powerautomatelogs",
  idfield: "ctpt_powerautomatelogid",
  continuationToken: "",
  pazesize: "1000",
});
