import React, { useEffect, useState } from "react";
import { Grid } from "@material-ui/core";
import { AuthenticatedTemplate } from "@azure/msal-react";
import { ThemeProvider } from "@material-ui/core/styles";
import { globalTheme } from "style/globalTheme";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import FormWidget from "app/components/mvp/formWidget";
import { getStudentSchema } from "./model/studentSchema";
import { getStudentUiSchema } from "./model/UiSchema";
import { getrecordPayload, getSupportingDocPayload, getAnnotationPayload } from "./model/payload";
import { config } from "utilities/config";
import { useHistory, useLocation } from "react-router-dom";
import { post } from "app/services/api";
import { CreaterequestPayload } from "./model/createPayload";
import { formatData } from "utilities/datatable";

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const StartPage = styled.section`
  ${globalTheme.breakpoints.up("sm")} {
    padding: 4rem 4rem 4rem 300px;
  }
  ${globalTheme.breakpoints.down("xs")} {
    padding: 1rem;
  }
`;

function ViewRecord() {
  const [recordId, setrecordId] = useState("");
  const [contact2FormData, setContact2FormData] = useState({});
  const getcontactId = useSelector((state) => state.contactid);
  const [docData, setDocData] = useState({
    docName: "",
    docUrl: "",
    docId: "",
  });
  let query = useQuery();
  let formDataa = {};
  const history = useHistory();

  const getRecordDetails = (payload) => {
    let api = config.teleportal.submitForm.getId;
    if (recordId !== "") {
      post(api, payload)
        .then((res) => {
          res.data.data.forEach((item) => {
            console.log(item);
            formDataa[item.name] = item.value;
            if(item.name=='_ctpt_employer_value'){
              localStorage.setItem("studentEmployerName", item.value);
              localStorage.setItem("studentEmployerId", item.id)
            }
            
          });

          post(config.teleportal.file.list, getSupportingDocPayload(recordId))
            .then((response) => {
              if (response.data) {
                console.log("response:", response);
                const doc = formatData(response.data)?.[0];
                
                let annotationPayload={
                  ...getAnnotationPayload,
                  filter: {
                    globalfilter: "",
                    globalAndOperator: false,
                    fieldAndOperator: true,
                    filterfields: [
                      {
                        field: "_objectid_value",
                        searchvalue: response.data.data[0].id,
                        type: "guid",
                      },
                    ]
                 
                }
              }
                post(config.teleportal.file.list, annotationPayload)
                .then((response2) => {
                  setDocData({
                    docName: doc.ctpt_name,
                    docUrl: doc.ctpt_documenturl,
                    docId: doc.ctpt_supportingdocumentid,
                    docAnnotationId:response2.data.data[0].id
                  });
                }
                )
              }
            })
            .catch((err) => {
              console.log("Supporting Document fetch failed:", err);
            })
            .finally(() => {
              setContact2FormData(formDataa);
            });
        })
        .catch(console.log);
    }
  };

  const getResponse = (res) => {
    if (res.data.id) {
      dispatch({
        type: "SET_STATUS",
        payload: {
          message: "Training agreement updated successfully",
          code: "",
          display: "show",
          severity: "success",
        },
      });
      history.push("/membership/student-training-agreement");
    }
  };

  const options = {
    widgetType: "update",
    formSchema: getStudentSchema(docData.docName, docData.docUrl, getcontactId),
    uiSchema: getStudentUiSchema,
    apiURL: config.teleportal.submitForm.Update,
    entityType: "ctpt_studenttrainingagreements",
    formUischema: "",
    button1Label: "Update",
    button2Label: "Cancel",
    historyUrl: "/membership/student-training-agreement",
    goBackPage: "/membership/student-training-agreement",
    button2GoBack: true,
    columns: [],
    contactId: getcontactId,
    payloadSubmit: CreaterequestPayload(docData),
    formData: contact2FormData,
    initialFormData: contact2FormData,
    hasModel: false,
  };
  const dispatch = useDispatch();

  useEffect(() => {
    if (window.location.href.indexOf("?") > -1) {
      setrecordId(query.get("id"));
    }
    dispatch({ type: "SET_DOC", payload: "" });
  }, []);

  useEffect(() => {
    let payload = {
      ...getrecordPayload,
      filters: [
        {
          field: "ctpt_studenttrainingagreementid",
          searchvalue: recordId,
          type: "guid",
        },
      ],
    };

    getRecordDetails(payload);
  }, [recordId]);

  return (
    <ThemeProvider theme={globalTheme}>
      <AuthenticatedTemplate>
        <Grid container spacing={0}>
          {/* NOTE:layout */}
          {/* <Grid item xs={12} md={2}></Grid> */}
          <Grid item xs={12} md={12}>
            <StartPage>
              <FormWidget
                options={options}
                successResponse={getResponse}
              ></FormWidget>
            </StartPage>
          </Grid>
          {/* <Grid item xs={12} md={2}></Grid> */}
        </Grid>
      </AuthenticatedTemplate>
    </ThemeProvider>
  );
}

export default ViewRecord;
