import React, { useEffect, useState } from "react";
import { Grid, Typography, Button, CssBaseline } from "@material-ui/core";
import Checkbox from "@material-ui/core/Checkbox";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Alert from "@material-ui/lab/Alert";
import { loginRequest, signupRequest, logoutRequest } from "constants/msalConfig";
import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
  useMsal,
  useIsAuthenticated,
  useAccount,
} from "@azure/msal-react";
import { ThemeProvider } from "@material-ui/core/styles";
import { globalTheme } from "style/globalTheme";
import { useStyles } from "./style";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import { InteractionStatus } from "@azure/msal-browser";
import { useDispatch, useSelector } from "react-redux";
import clsx from "clsx";
import Cluster from "layout/Cluster";
import Stack from "layout/Stack";
import ImageCard from "app/components/molecules/imageCard/ImageCard.mui";
import TileCard from "app/components/molecules/tileCard/TileCard.mui";
import StatusBar from "app/components/atoms/statusbar";
import Row from "layout/Row";
import { callRedirectAPI } from "utilities/utils";
import { getContactDetails, getConigitems } from "./service";
import { usePreLoader } from "app/services/hooks/usePreLoader";
import { brandPalette } from "brand/theme/brandTheme";
import { HOME_TILES_DATA } from "./model/constants";
import "./styles.css";
import CustomCheckBox from "app/components/atoms/customCheckBox";
import moment from "moment";
import { config } from "utilities/config";
import { post } from "app/services/api";
import { appData } from "data/appData";
import { useHistory } from "react-router-dom";
import { ContactsOutlined } from "@material-ui/icons";

//layout

function Home() {
  const classes = useStyles();
  const { instance, inProgress, accounts } = useMsal();
  const isAuthenticated = useIsAuthenticated();
  const account = useAccount(accounts[0] || {});
  const dispatch = useDispatch();
  const contactId = useSelector((state) => state.contactid);
  const token = useSelector((state) => state.token);
  const [checked, setChecked] = React.useState(false);
  const [notEntitled, setnotEntitled] = React.useState(false);
  const { enableLoader } = usePreLoader();

  const [contactDetails, setContactDetails] = useState({});
  const [profileUpdate, setProfileupdate] = useState(false);

  const history = useHistory();

  const compareDates = () => {
    const startDate = contactDetails.profileupdateDate;
    const currenDate = moment(new Date()).format("DD/MM/YYYY");
    const endDate = moment(currenDate, "DD/MM/YYYY");
    const results = endDate.diff(startDate, "months");
    const expiryDate = appData[0].utilities.profileExpiryDuration;
    if (results > expiryDate) {
      setProfileupdate(true);
    } else {
      setProfileupdate(false);
    }
  };

  const saveProfile = () => {
    if (checked) {
      const apiURL = config.teleportal.submitForm.Update;
      const saveDate = new Date();
      const payload = {
        recordtype: "contacts",
        id: contactId,
        data: [
          {
            name: "ctpt_profiledetailsconfirmedaccurateon",
            value: saveDate,
            type: "string",
          },
        ],
      };
      post(apiURL, payload)
        .then((res) => {
          if (res) {
            setProfileupdate(false);
          }
        })
        .catch(console.log);
    } else {
      setProfileupdate(false);
    }
  };

  const handleLocalLogin = () => {
    localStorage.clear();
    if (inProgress === InteractionStatus.None && !isAuthenticated) {
      callRedirectAPI(instance, loginRequest);
      localStorage.setItem("isRedirect", false);
      localStorage.setItem("tknPay", "tokenRequest");
      localStorage.setItem("isSocial", false);
    }
  };

  const handleChange = (event) => {
    setChecked(event.target.checked);
  };

  const handleGoogleSignIn = () => {
    callRedirectAPI(instance, signupRequest);
  };

  useEffect(() => {
    //console.log("get--"+)
    if (isAuthenticated) {
      if (typeof window !== 'first_visit') {
      window.gtag('event', 'screen_view', {
        'app_name': 'myAppName',
        'screen_name': 'userAuthetication'
      });

      window.gtag('event', 'login', {
        'event_label': 'Authentication',
        'event_category': 'login',
        'non_interaction': true
      });
    }
      dispatch({ type: "SET_AUTH", payload: true });
      dispatch({
        type: "SET_USER",
        payload: { name: account.idTokenClaims.given_name },
      });
      window.dataLayer = window.dataLayer || []
      window.dataLayer.push({'event': "logged-in", 'login account': account})
      setnotEntitled("")
    }
    else{
      const notEntitles=localStorage.getItem("notitle");
      
      if(notEntitles!==null && notEntitles!==""){
        setnotEntitled("Try again after some time")
      }else{
        setnotEntitled("")
      }
      
    }
  }, [account, dispatch, isAuthenticated, notEntitled]);

  useEffect(() => {
    const fetchContactDetails = async () => {
      console.log("Token fetched:", !!token);
      if (isAuthenticated && contactId && token) {
        const contactDetails = await getContactDetails(contactId);
        setContactDetails(contactDetails);
        dispatch({
          type: "SET_ACCOUNT_STATUS",
          payload: contactDetails.accountStatus,
        });
      }
      enableLoader(false);
    };

    fetchContactDetails().catch(console.log);
  }, [contactId, isAuthenticated, token, enableLoader, dispatch]);

  useEffect(() => {
    dispatch({ type: "SET_CONTACTDET", payload: contactDetails });
    compareDates();
    if(Object.keys(contactDetails).length > 0){
      if(account.idTokenClaims.tfp.indexOf("registration") > -1 || account.idTokenClaims.newUser){
        callRedirectAPI(instance, logoutRequest, false);
      }
    }
  }, [contactDetails, dispatch]);

  return (
    <ThemeProvider theme={globalTheme}>
      <CssBaseline />
      <UnauthenticatedTemplate>
        <Grid container>
          <Grid item className={classes.heroImg} id="herobanner"></Grid>
        </Grid>
        <Cluster
          className={classes.buttonBand}
          align="center"
          padding="large"
          gap="medium"
        >
          <Row className={classes.textBand} align="center">
            <LockOutlinedIcon size="large" />
            <Typography variant="body2" className={classes.bodyCopy2}>
              MyBica
            </Typography>
          </Row>

          <Button
            variant="contained"
            color="secondary"
            className={clsx(classes.buttonBig, classes.primary)}
            onClick={() => handleLocalLogin()}
          >
            Login
          </Button>

          <Button
            variant="outlined"
            color="secondary"
            className={clsx(classes.buttonBig, classes.secondary)}
            onClick={() => handleGoogleSignIn()}
          >
            Register
          </Button>
          <span>{notEntitled}</span>
        </Cluster>
        <div className={classes.imageCards}>
          <Cluster justify="evenly" padding="medium" gap="medium">
            <Grid item xs={12} sm={5} style={{ borderShadow: "0px 2px 2px -1px rgb(0 0 0 / 20%), 0px 1px 2px 2px rgb(0 0 0 / 14%), 0px 1px 3px 2px rgb(0 0 0 / 12%)" }}>
              <ImageCard
                header="Be Part Of BICA Today"
                lrRatio="60:40"
                imgRatio="1:1"
                image={<img alt="member" src="/brand/memberImages/7.png" />}
              >
                <Typography variant="body1">
                  Register with Botswana Institute of Chartered Accountants today and be associated with an Institute that’s reputable and contributes to the development of the accounting profession in Botswana.
                </Typography>
                <Typography variant="body1" style={{ paddingTop: "1.5rem" }}>
                  BICA is a statutory body established by Accountants Act, 2010 read in conjunction with Accountants (Amendment) Act, 2020.
                </Typography>
              </ImageCard>
            </Grid>
            <Grid item xs={12} sm={5}>
              <ImageCard
                header="About Us"
                lrRatio="100:0"
                imgRatio="1:1"
                //image={<img alt="member" src="/brand/memberImages/15.png" />}
              >
                <Typography variant="body1">
                  The Institute is established to among others:
                  <ul>
                    <li><Typography variant="body1">Establish the Botswana Chartered Accountancy Qualification;</Typography></li>
                    <li><Typography variant="body1">Provide registration of accountants in all branches of the accountancy profession;</Typography></li>
                    <li><Typography variant="body1">Promote development of the accounting profession;</Typography></li>
                    <li><Typography variant="body1">Supervise the accountancy profession in the public interest;</Typography></li>
                    <div style={{ textAlign: "right", width: "100%", flexDirection: "row" }}>
                      <li style={{ width: "65%", textAlign: "left", float: "left" }}><Typography variant="body1">Promote the highest standards of professional ethics and business conduct of professional Accountants; and</Typography></li>
                      <div style={{ width: "35%", textAlign: "right", float: "right" }}>
                        <img alt="member" style={{width: "201px", textAlign: "right"}} src="/brand/memberImages/15.png" />
                      </div>
                    </div>
                    <li><Typography variant="body1">Promote, maintain and increase knowledge, skills and competence of members of the Institute and students.</Typography></li>
                  </ul>
                </Typography>
              </ImageCard>
            </Grid>
          </Cluster>
        </div>
      </UnauthenticatedTemplate>

      <AuthenticatedTemplate>
        <div className={classes.bannerContainer}></div>
        <Grid component="main" container spacing={0} className={classes.root}>
          <StatusBar className="show" open={true} />
          <Grid item xs={12}>
            <Stack gap="medium" padding="large">
              <div
                className={`profileBlock ${
                  profileUpdate ? classes.showflex : classes.hideflex
                  }`}
              >
                <Typography
                  variant="h6"
                  element="h6"
                  className="profileHeading"
                >
                  Please check your profile details are up to date
                </Typography>
                <FormControlLabel
                  key="saveid"
                  control={
                    <Checkbox
                      checked={checked}
                      name="checked"
                      color="primary"
                      onChange={handleChange}
                    />
                  }
                  label="I can confirm my profile details are up to date"
                />
                <div>
                  <Button
                    disabled={!checked}
                    variant="contained"
                    onClick={saveProfile}
                    color={"primary"}
                  >
                    {" "}
                    Save
                  </Button>
                  <Button
                    variant="contained"
                    disabled={checked}
                    style={{ marginLeft: "1rem" }}
                    onClick={() => history.push("/profile")}
                  >
                    {" "}
                    Go to profile
                  </Button>
                </div>
              </div>
              <Typography variant="h2" element="h2">
                Hello {contactDetails && contactDetails.fullName? contactDetails.fullName : account ?.idTokenClaims ?.given_name || "User"}
              </Typography>

              <Typography variant="subtitle1" className={classes.description}>
              {`Welcome to the MyBica self service portal.`}
                {/* {contactDetails.membershipType
                  ? `Welcome to MyBICA. Your next renewal is due on ${contactDetails.memberRenewalDue}.​`
                  : `Welcome to the MyBica self service portal.`} */}
              </Typography>
              <Typography variant="h2" element="h2">
                Watch the My BICA Tutorial
              </Typography>
              <div  style={{ marginLeft: "180px" }}>
                <iframe width="850" height="434" src="https://www.youtube.com/embed/vQUERCrrXO4" title="MY BICA WEB PORTAL" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
              </div>
              <Typography className={classes.heading} variant="h2" element="h2">
                Manage Your Membership
              </Typography>
              <Cluster justify="start" gap="large">
                {HOME_TILES_DATA.map((tile) => (
                  <div className={classes.tileCardContainer}>
                    <TileCard
                      title={tile.title}
                      linkURL="/profile"
                      image={
                        <img src={`./brand/leaf-optimized.jpg`} alt="member" />
                      }
                      itemLinks={tile.items}
                      colour={brandPalette["Primary"][100]}
                    />
                  </div>
                ))}
              </Cluster>
            </Stack>
          </Grid>
        </Grid>
      </AuthenticatedTemplate>
    </ThemeProvider>
  );
}

export default Home;
