import React, { Component } from "react";
import { config } from "utilities/config";
import { post } from "app/services/api";
import { transactionCurrencyBWP } from "constants/settings";
import { getProcessPayload } from "./model/payload";

function CreateOrder(HocComponent, data) {
  return class extends Component {
    constructor(props) {
      super(props);
      this.state = {
        data: data,
      };
    }

    getProcess = (oData, callback) => {
      console.log(oData.swfsubmissionid);

      let refreshCount = 0;
      localStorage.setItem("oid", "");

      var refreshId = setInterval(() => {
        if (refreshCount > 10) {
          window.location.href = "/error";
        } else {
          post(
            config.teleportal.submitForm.get,
            getProcessPayload(oData.swfsubmissionid)
          )
            .then((res) => {
              console.log({ id: res.data.data[0].data[1].value });
              if (res.data.data[0].data[1].value === 533910000) {
                clearInterval(refreshId);
                this.getOrderId(oData, callback);
              } else {
                console.log("still processing");
              }
            })
            .catch((err) => {
              console.log("error order created " + err);
            });
        }
        refreshCount = refreshCount + 1;
      }, 10000);
    };

    getOrderId = (oData, callback) => {
      const payload = {
        properties: ["salesorderid"],
        formattedFields: [],
        recordType: "salesorders",
        filters: [
          {
            field: "_ctpt_sonicwebformsubmission_value",
            searchvalue: oData.swfsubmissionid,
            type: "guid",
          },
          {
            field: "_transactioncurrencyid_value",
            searchvalue: transactionCurrencyBWP,
            type: "guid",
          },
        ],
        FieldAndOperator: "true",
        idfield: "salesorderid",
      };

      post(config.teleportal.submitForm.getId, payload)
        .then((res) => {
          localStorage.setItem("oid", res.data.id);
          if (callback) callback();
        })
        .catch((err) => {
          console.log("error order created " + err);
        });
    };
    createOrder = (oData) => {
      post(config.teleportal.order.create, oData.payload)
        .then((res) => {
          console.log("order created " + JSON.stringify(res));
        })
        .catch((err) => {
          console.log("error order created " + err);
        });
    };

    handleChange = (args, callback) => {
      const orderObject = JSON.parse(args);
      console.log("orderObject--" + args);
      if (!orderObject.getOrderId) {
        if (orderObject.stepCounter === orderObject.orderStep) {
          this.createOrder(orderObject);
        }
      } else {
        this.getProcess({ ...orderObject }, callback);
      }
    };
    render() {
      return (
        <div>
          <HocComponent
            data={this.state.data}
            {...this.props}
            handleChange={this.handleChange}
            getProcess={this.getProcess}
          ></HocComponent>
        </div>
      );
    }
  };
}

export default CreateOrder;
