import React, { useEffect, useState } from "react";
import { AuthenticatedTemplate } from "@azure/msal-react";
import { ThemeProvider } from "@material-ui/core/styles";
import { globalTheme } from "style/globalTheme";
import styled from "styled-components";
import SuccessIcon from "@material-ui/icons/CheckCircle";
import { useHistory } from "react-router-dom";
import { useStyles } from "./style";
import { Button, Box } from "@material-ui/core";
import { useDispatch } from "react-redux";
import { postPay } from "app/services/api";
import { config } from "utilities/config";
import { post } from "app/services/api";
import { useSelector } from "react-redux";

const StartPage = styled.section`
  ${globalTheme.breakpoints.up("sm")} {
    padding: 4rem 4rem 4rem 300px;
  }
  ${globalTheme.breakpoints.down("xs")} {
    padding: 1rem;
  }
`;

function PaymentSuccess() {
  const classes = useStyles();
  const [payDetails, setpayDetails] = useState(null);
  const [payloads, setpayloads] = useState({
    orderId: "",
  });
  const [invoiceNumber, setInvoiceNumber] = useState("");
  const dispatch = useDispatch();
  let history = useHistory();
  const userName = useSelector((state) => state.user);

  const getInvoice = () => {
    postPay(config.payment.orderDetails, payloads)
      .then((res) => {
        setpayDetails(res.data.data);
        createPayment(res.data.data);
        dispatch({ type: "SET_PAYSUMMARY", payload: res.data.data });
      })
      .catch((err) => {
        dispatch({
          type: "SET_STATUS",
          payload: {
            message: "Oops something went wrong!",
            code: "",
            display: "show",
            severity: "error",
          },
        });
      });
  };

  const createPayment = (data) => {
    const apiURL = config.teleportal.submitForm.Create;
      const payload = {
        "recordtype": "ctpt_paymenttransactions",
        "data": [
            {
                "name": "ctpt_name",
                "value": userName.name,
                "type": "string"
            },
            {
                "name": "ctpt_SalesOrder",
                "value": payloads.orderId,
                "type": "lookup",
                "relatedentity": "salesorders"
            },
            {

              "name": "ctpt_datepaid",
              
              "value": data.invoiceDate,
              
              "type": "Date and Time"
              
              },
            {
                "name": "ctpt_amountpaid",
                "value": data.invoiceTotalAmount,
                "type": "Decimal"
            },
            {
                "name": "ctpt_paymentreference",
                "value": "533910000",
                "type": "picklist"
            }
        ]
    }
      
    post(apiURL, payload)
      .then((res) => {
        console.log("payment created")
      })
      .catch(console.log);
  }

  useEffect(() => {
    setInvoiceNumber(localStorage.getItem("invoiceNumber"));
  }, []);
  useEffect(() => {
    let oId = localStorage.getItem("oid");
    setpayloads({
      orderId: oId,
    });
  }, []);
  useEffect(() => {
    if (payloads.orderId !== "") {
      getInvoice();
    }
  }, [payloads]);

  return (
    <ThemeProvider theme={globalTheme}>
      <AuthenticatedTemplate>
        <StartPage>
         
          {payDetails !== null && <span>
            <h1 className={classes.headingTxt}>Thank you for your Application</h1>
            <p>{`Your reference number ${invoiceNumber || "XXXXXXXX"}`}</p>
            <div className={classes.desc}>
              <div className={classes.paymentWrapper}>
                <h4>Payment Summary</h4>
                {payDetails !== null
                  ? payDetails.products.map((element, index) => {
                      return (
                        <Box sx={{ display: "flex" }}>
                          <div className={classes.tableDesc}>
                            {element.description !== null
                              ? element.description
                              : " "}
                          </div>
                          <div className={classes.tablePrice}>
                            {element.amount}
                          </div>
                        </Box>
                      );
                    })
                  : ""}
                <Box sx={{ display: "flex" }} className={classes.total}>
                  <div className={classes.tableDesc}>Total paid</div>
                  <div className={classes.tablePrice}>
                    {payDetails !== null
                      ? payDetails.invoiceTotal.invoideTotal
                      : ""}
                  </div>
                </Box>
              </div>
            </div>
            <div className={classes.notes}>
            
              {payDetails.eventName && payDetails.eventName.length>0 ? 
              <p>
                You have successfully registered for event <b>{payDetails.eventName}</b>. Kindly note that all other details will be communicated through email. You will be notified by email once it is approved or a member of our team will contact you if required. If you have any queries in relation to your application, please contact us.
              </p> :
              <p>
                Your application has now been submitted to our membership team for review. You will be notified by email once it is approved or a member of our team will contact you if required. If you have any queries in relation to your application, please contact us.
              </p>
              }
            
            </div>
            <Box sx={{ p: 2 }}>
              <Button
                color="primary"
                variant="contained"
                onClick={() => history.push("/")}
              >
                Return to Membership Homepage
              </Button>
            </Box>
          </span>}
        </StartPage>
      </AuthenticatedTemplate>
    </ThemeProvider>
  );
}

export default PaymentSuccess;
