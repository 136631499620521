import React, { useState } from 'react';
import { settings } from "constants/settings"

async function loadComponent() {
  return import('./EntitleComponent.' + settings.UI + '.js')
}

function EntitleComponent(props) {

  const { children, data } = props;

  const [component, setComponent] = useState(null)

  loadComponent().then(x => setComponent(x))

  if (!component) return null;
  return (
    <component.default data={data}>{children}</component.default>
  )
}

export default EntitleComponent;