const thisDate=new Date().toISOString().split('T')[0];

export const contactSchema = {
  title: "Personal Information",
  logicalname: "Contact",
  type: "object",
  description:
    "Update your personal information using the form below and click Save",
  //required: ["name", "address", "primaryemail", "primarycontact"],
  properties: {
    salutation: {
      title: "Title",
      type: "string",
      schemaname: "salutation",
      datatype: "string",
      requiredCheck: true,
      helpText: "For example: Ms, Mr, Mrs, Dr",
    },
    firstname: {
      title: "First Name",
      type: "string",
      schemaname: "firstname",
      datatype: "string",
      requiredCheck: true,
      pattern: "^[a-zA-Z' ']*$",
      message: "Only string allowed"
    },
    lastname: {
      title: "Last Name",
      type: "string",
      datatype: "string",
      requiredCheck: true,
      pattern: "^[a-zA-Z' ']*$",
      message: "Only string allowed"
    },
    fullname: {
      title: "Full Name",
      type: "string",
      datatype: "string",
      requiredCheck: true,
      pattern: "^[a-zA-Z' ']*$",
      message: "Only string allowed"
    },
    ctpt_othername: {
      title: "Other Name",
      type: "string",
      datatype: "string",
      requiredCheck: false,
    },
    // ctpt_countryofbirth: {
    //   title: "Date of birth",
    //   type: "string",
    //   datatype: "number"
    // },
    birthdate: {
      title: "Date of birth",
      type: "string",
      datatype: "number",
      requiredCheck: true,
      disableFuture: true
    },
    // fax: {
    //   title: "Fax Number",
    //   type: "string",
    //   datatype: "string",
    // },
    ctpt_isresidentofbotswana: {
      title: "Are you a resident of Botswana?",
      type: "string",
      enum: ["True", "False"],
      enumNames: ["Yes", "No"],
      default: "True",
    },
    ctpt_profiledetailsconfirmedaccurateon: {
      title: "update date",
      type: "string",
      datatype: "string",
      default: thisDate,
    },
  },
};
