export const navbarList = [
  {
    label: "Home",
    href: "/",
  },
  {
    label: "Edit Record",
    href: "/update",
  },
  {
    label: "Contact",
    href: "/contact",
  },
  {
    label: "Sonic Web Form",
    href: "/sonicform",
  },
  {
    label: "Sonic Web Form Update",
    href: "/sonicformupdate",
  },
];

export const navbarItems = [
  {
    logo: {
      imageUrl: "",
      altText: "",
      text: "Teleportal",
    },
    items: [
      {
        labelText: "Profile",
        linkUrl: "/profile",
        iconUrl: "account_circle",
      },
    ],
  },
];

export const footerItems = {
  logo: {
    imageUrl: "",
    altText: "",
    text: "Teleportal",
  },
  verticalSections: [
    {
      title: "About BICA",
      items: [
        {
          labelText: "About Us",
          linkUrl: "https://www.bica.org.bw/about-us",
        },
        {
          labelText: "BICA Presidents",
          linkUrl: "https://www.bica.org.bw/bica-presidents",
        },
        {
          labelText: "BICA Secretariat",
          linkUrl: "https://www.bica.org.bw/bica-secretariat",
        },
        {
          labelText: "Council Members",
          linkUrl: "https://www.bica.org.bw/council-members",
        },
        {
          labelText: "CSR",
          linkUrl: "https://www.bica.org.bw/corporate-social-responsibility",
        },
      ],
    },
    {
      title: "Useful Links",
      items: [
        {
          labelText: "BICA Events",
          linkUrl: "https://www.bica.org.bw/bica-events",
        },
        {
          labelText: "Member Directory",
          linkUrl: "/memberDirectory",
        },
        {
          labelText: "Firm Directory",
          linkUrl: "/firmDirectory",
        },
        {
          labelText: "Contact Us",
          linkUrl: "https://www.bica.org.bw/contact-us",
        },
        {
          labelText: "Enquiries & Complaints",
          linkUrl: "https://www.bica.org.bw/enquiries-complaints",
        },
        {
          labelText: "Vacancies",
          linkUrl: "https://www.bica.org.bw/vacancies",
        },
        {
          labelText: "FAQ",
          linkUrl: "https://www.bica.org.bw/faq",
        },
      ],
    },
  ],
  horizontalSection: {
    title: "",
    items: [
      {
        labelText: "Privacy Policy",
        linkUrl: "https://www.bica.org.bw/terms-and-conditions",
        iconUrl: "Notifications",
      },
      {
        labelText: "Help",
        linkUrl: "https://www.bica.org.bw/contact-us",
        iconUrl: "Person",
      },
      {
        labelText: "About",
        linkUrl: "https://www.bica.org.bw/about-us",
        iconUrl: "Person",
      },
    ],
  },
};
