import CustomTextBox from "app/components/atoms/customTextBox";
import CustomDate from "app/components/atoms/customDate";
import CustomSelect from "app/components/atoms/customSelect";

export const viewEmploymentHistoryDetailsUiSchema = {
    "ui:title": "",
    ctpt_jobtitle: {
        "ui:widget": (props) => {
            return <CustomTextBox getProps={props} />;
        },
    },
    ctpt_jobdescription: {
        "ui:widget": (props) => {
            return <CustomTextBox getProps={props} />;
        },
    },
    ctpt_professionalbody: {
        "ui:widget": (props) => {
            return <CustomTextBox getProps={props} />;
        },
    },
    ctpt_typeofemployment: {
        "ui:widget": (props) => {
            return <CustomSelect getProps={props} />;
        },
    },
    ctpt_startdate: {
        "ui:widget": (props) => {
            return <CustomDate getProps={props} />;
        },
    },
    ctpt_enddate: {
        "ui:widget": (props) => {
            return <CustomDate getProps={props} />;
        },
    },
    // ctpt_businessnature: {
    //     "ui:widget": (props) => {
    //         return <CustomTextBox getProps={props} />;
    //     },
    // },
};

export const addNewEmploymentHistoryDetailsUiSchema = {
    "ui:title": "",
    ctpt_jobtitle: {
        "ui:widget": (props) => {
            return <CustomTextBox getProps={props} />;
        },
    },
    ctpt_jobdescription: {
        "ui:widget": (props) => {
            return <CustomTextBox getProps={props} />;
        },
    },
    ctpt_professionalbody: {
        "ui:widget": (props) => {
            return <CustomTextBox getProps={props} />;
        },
    },
    ctpt_typeofemployment: {
        "ui:widget": (props) => {
            return <CustomSelect getProps={props} />;
        },
    },
    ctpt_startdate: {
        "ui:widget": (props) => {
            return <CustomDate getProps={props} />;
        },
    },
    ctpt_enddate: {
        "ui:widget": (props) => {
            return <CustomDate getProps={props} />;
        },
    }
};

export const viewProfessionalHistoryDetailsUiSchema = {
    "ui:title": "",
    ctpt_jobtitle: {
        "ui:widget": (props) => {
            return <CustomTextBox getProps={props} />;
        },
    },
    ctpt_jobdescription: {
        "ui:widget": (props) => {
            return <CustomTextBox getProps={props} />;
        },
    },
    ctpt_professionalbody: {
        "ui:widget": (props) => {
            return <CustomTextBox getProps={props} />;
        },
    },
    ctpt_typeofemployment: {
        "ui:widget": (props) => {
            return <CustomSelect getProps={props} />;
        },
    },
    ctpt_startdate: {
        "ui:widget": (props) => {
            return <CustomDate getProps={props} />;
        },
    },
    ctpt_enddate: {
        "ui:widget": (props) => {
            return <CustomDate getProps={props} />;
        },
    }
};

export const addNewProfessionalHistoryDetailsUiSchema = {
    "ui:title": "",
    ctpt_jobtitle: {
        "ui:widget": (props) => {
            return <CustomTextBox getProps={props} />;
        },
    },
    ctpt_jobdescription: {
        "ui:widget": (props) => {
            return <CustomTextBox getProps={props} />;
        },
    },
    ctpt_professionalbody: {
        "ui:widget": (props) => {
            return <CustomTextBox getProps={props} />;
        },
    },
    ctpt_startdate: {
        "ui:widget": (props) => {
            return <CustomDate getProps={props} />;
        },
    },
    ctpt_enddate: {
        "ui:widget": (props) => {
            return <CustomDate getProps={props} />;
        },
    }
};
