import React, { useState, useEffect } from "react";
import { Grid, Button } from "@material-ui/core";
import { ThemeProvider } from "@material-ui/core/styles";
import { globalTheme } from "style/globalTheme";
import styled from "styled-components";
import { brandPalette } from "brand/theme/brandTheme";
import { useStyles } from "./style";
import { useLocation, useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { config } from "utilities/config";
import { getRegistrationPayload } from "./model/createpayload";
import { post, postSwf } from "app/services/api";
import {
  getEventSchedulePayload,
  getAttractionSchedulePayload,
  getSupportingDocPayload,
  updateEventRegPayload,
  getSWFSubmissionIDPayload,
} from "./model/payload";
import { formatData } from "utilities/datatable";
import TileCard from "app/components/molecules/tileCard/TileCard.mui";
import { usePreLoader } from "app/services/hooks/usePreLoader";
import break_address from "utilities/splitUrl";
import CreateOrder from "brand/components/order";
import moment from "moment";

const StartPage = styled.section`
  ${globalTheme.breakpoints.up("sm")} {
    padding: 2rem 1rem 1rem 1rem;
  }
  ${globalTheme.breakpoints.down("xs")} {
    padding: 1rem;
  }
  min-height: 65rem;
`;

const Heading = styled.div`
  min-height: 45vh;
  width: 100%;
  background-image: url(/brand/DESKTOP-HOMEPAGE4.jpg);
  background-blend-mode: screen;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;

  .banner-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
    max-width: max-content;
    background-color: rgba(255,255,255,.8);
  }
  

  h2 {
    display: inline-block;
    justify-content: flex-start;
    margin: 1.5vw 3.6vw;
    font-size: 2.5rem;
    letter-spacing: 5px !important;
    text-transform: uppercase;
    font-weight: 400;
    letter-spacing: 0.07rem;
    
    border-radius: 8px;
    
    //-webkit-background-clip: text;
    //color: rgba(255, 255, 255, 0.85); 
    color: black;
  }

  p {
    color:  rgba(255, 255, 255, 0.75);
    color: black;
    font-size: 1.1rem;
    max-width: 40rem;
    margin: -1rem 3.6vw;
    padding-bottom: 3rem;
  }

  ${globalTheme.breakpoints.down("sm")} {
    h2 {
      //margin : 3rem 3rem;
      font-size: 2.4rem;
      margin-bottom: 2rem;
    }
  }

  ${globalTheme.breakpoints.down("xs")} {
    padding: 0;
    display: flex;
    align-content: center;
    //justify-content: flex-end;
    h2 {
      //margin : 3rem 3rem;
    }
   
  }
}
`;

function ExamDetails(props) {
  const { state: params } = useLocation();
  const [registerEvent, setRegisterEvent] = useState(false);
  const [sessionInfo, setSessionInfo] = useState(null);
  const [supportingDocs, setSupportingDocs] = useState(null);
  const [enableCta, setEnableCta] = useState(false);
  const { enableLoader } = usePreLoader();
  const dispatch = useDispatch();
  const history = useHistory();

  const classes = useStyles();
  const contactID = useSelector((state) => state.contactid);

  const createOrder = (subId, swfId) => {
    localStorage.setItem("oid", "");
    localStorage.setItem("sid", subId);
    const orderCreatePayload = {
      orderStep: 1,
      stepCounter: 1,
      getOrderId: false,
      payload: {
        applicationtype: "exam",
        contactid: contactID,
        data: {
          eventid: params?.data?.msmedia_mediaeventid,
          examid: params?.data?._ctpt_examid_value,
        },
        sonicwebformid: swfId,
        swfsubmissionid: subId,
      },
    };

    props.handleChange(JSON.stringify(orderCreatePayload));

    setInterval(() => {
      if (!localStorage.getItem("oid")) {
        props.handleChange(
          JSON.stringify({
            ...orderCreatePayload,
            getOrderId: true,
            swfsubmissionid: subId,
          })
        );
      } else {
        enableLoader(false);
        window.location.href =
          "/application/prepayment?type=" +
          0 +
          "&payLater=true" +
          "&swfName=examreg";
      }
    }, 10000);
  };

  const onPressRegister = () => {
    enableLoader(true, "Loading Exam details");
    postSwf(config.sonicwebform.getFormschema, { WebFormName: "examreg" })
      .then((swfRes) => {
        if (swfRes.data?.id) {
          post(
            config.teleportal.submitForm.Create,
            getRegistrationPayload(
              contactID,
              params?.data?.msmedia_mediaeventid
            )
          )
            .then(() => {
              postSwf(
                config.sonicwebform.createFormsubmit,
                getSWFSubmissionIDPayload(contactID, swfRes.data.id)
              )
                .then((res) => {
                  const subId = break_address(res.data.uri, 3);
                  if (subId) {
                    createOrder(subId, swfRes.data.id);
                  } else {
                    enableLoader(false);
                  }
                })
                .catch((err) => {
                  console.log(err);
                  enableLoader(false);
                });
            })
            .catch((err) => {
              console.log(err);
              enableLoader(false);
            });
        } else {
          enableLoader(false);
        }
      })
      .catch((err) => {
        console.log(err);
        enableLoader(false);
      });
  };

  useEffect(() => {
    setRegisterEvent(!params?.registrationID);
  }, [params]);

  useEffect(() => {
    if (contactID) {
      console.log("PARAMS:", params);
      if (params.registrationID) {
        if (params.data.ctpt_cancellationduedate) {
          const canCancelEvent = moment(
            params.data.ctpt_cancellationduedate
          ).isSameOrAfter(moment());

          setEnableCta(canCancelEvent);
        } else {
          setEnableCta(true);
        }
      } else {
        if (params.data.msmedia_registrationcloseson) {
          const registrationStarted = moment(
            params.data.msmedia_registrationopenson
          ).isSameOrBefore(moment());
          const registrationClosed = moment(
            params.data.msmedia_registrationcloseson
          ).isSameOrBefore(moment());

          setEnableCta(Boolean(registrationStarted && !registrationClosed));
        } else {
          setEnableCta(true);
        }
      }
    }
  }, [contactID, params]);

  useEffect(() => {
    const formatEventSchedules = (result) => {
      const eventSchedules = [];
      if (result.data) {
        formatData(result.data)?.map((row) => {
          if (row.msmedia_eventscheduleid) {
            eventSchedules.push(row.msmedia_eventscheduleid);
          }
        });
      }
      return eventSchedules;
    };

    const updateSessionInfo = (response) => {
      if (response.data) {
        console.log(formatData(response.data));
        setSessionInfo(formatData(response.data));
      }
    };
    const loadSessionInformation = async () => {
      const api = config.teleportal.file.list;
      post(api, getEventSchedulePayload(params.data.msmedia_mediaeventid))
        .then((result) => {
          const eventSchedules = formatEventSchedules(result);
          if (eventSchedules.length > 0) {
            post(api, getAttractionSchedulePayload(eventSchedules))
              .then(updateSessionInfo)
              .catch((err) => {
                console.log("Unable to fetch Session information:", err);
              });
          }
        })
        .catch(console.log);
    };

    if (params?.data?.msmedia_mediaeventid) {
      loadSessionInformation().catch(console.log);
    }
  }, [params]);

  useEffect(() => {
    const formatAndStoreSupportingDocs = (response) => {
      if (response.data) {
        let docs = [];
        formatData(response.data)?.forEach((doc) => {
          console.log({ doc });
          if (doc.ctpt_documenturl || true) {
            docs.push({
              name: doc.ctpt_name,
              url: doc.ctpt_documenturl,
              description: doc.ctpt_description,
            });
          }
        });
        if (docs.length > 0) {
          setSupportingDocs(docs);
        }
      }
    };
    const loadSupportingDocs = async () => {
      if (params?.data?.msmedia_mediaeventid) {
        post(
          config.teleportal.file.list,
          getSupportingDocPayload(params.data.msmedia_mediaeventid)
        ).then(formatAndStoreSupportingDocs);
      }
    };

    loadSupportingDocs().catch(console.log);
  }, [params]);

  const onPressCancelRegister = () => {
    enableLoader(true);
    post(
      config.teleportal.submitForm.Update,
      updateEventRegPayload(params.registrationID)
    )
      .then(() => {
        setRegisterEvent(true);
        dispatch({
          type: "SET_STATUS",
          payload: {
            message: "Successfully Unregistered",
            code: "",
            display: "show",
            severity: "success",
          },
        });
      })
      .catch((err) => {
        console.log("unable to unregister");
      })
      .finally(() => {
        enableLoader(false);
      });
  };

  return (
    <ThemeProvider theme={globalTheme}>
      <Grid container spacing={0}>
        <Grid item xs={12} md={12}>
          <Heading>
            <div className="banner-content">
              <h2>{params.data?.msmedia_name}</h2>
              <p>{params.data?.msmedia_description}</p>
              <div
                style={{
                  marginTop: "0rem",
                  marginBottom: "2rem",
                  marginLeft: "auto",
                  marginRight: "1rem",
                }}
              >
                <Button
                  variant="contained"
                  color="primary"
                  className={classes.registerBtnTop}
                  disabled={!enableCta}
                  onClick={() => {
                    if (!registerEvent) {
                      onPressCancelRegister();
                    } else {
                      onPressRegister();
                    }
                  }}
                >
                  {!registerEvent ? "Cancel Exam" : "Register Exam"}
                </Button>
                <Button
                  variant="outlined"
                  color="primary"
                  style={{
                    margin: "2rem 0 2rem 0rem",
                    marginLeft: "1rem",
                    width: "8rem",
                    fontSize: "0.8rem",
                    minHeight: "1.5rem",
                    backgroundColor: "white",
                  }}
                  onClick={() => {
                    history.replace("/exam/register");
                  }}
                >
                  {"Go back"}
                </Button>
              </div>
            </div>
          </Heading>
        </Grid>
      </Grid>

      <StartPage>
        <Grid
          container
          spacing={0}
          justifyContent="center"
          className={classes.middleSection}
        >
          {/* <Grid item xs={12} md={1}></Grid> */}
          <Grid item xs={12} md={5} className={classes.itemStyle}>
            <div className={classes.detailsSection}>
              <h1 className="header">Exam Details</h1>

              <ul className={classes.detailTable}>
                <li className={classes.listItem}>
                  <span className="key">Topic:</span>
                  <span className="value">{params.data?.msmedia_eventtopic}</span>
                </li>
                <li className={classes.listItem}>
                  <span className="key">Venue:</span>
                  <span className="value">{params.data?.msmedia_primaryvenue}</span>
                </li>
                <li className={classes.listItem}>
                  <span className="key">Start Date:</span>
                  <span className="value">
                    {params.data?.msmedia_startdate !== null
                      ? moment(params.data?.msmedia_startdate).format(
                          "DD MMM YYYY, h:mm"
                        )
                      : ""}
                  </span>
                </li>
                <li className={classes.listItem}>
                  <span className="key">End Date:</span>
                  <span className="value">
                    {params.data?.msmedia_enddate !== null
                      ? moment(params.data?.msmedia_enddate).format(
                          "DD MMM YYYY, h:mm"
                        )
                      : ""}
                  </span>
                </li>

                <li className={classes.listItem}>
                  <span className="key">Total Cost:</span>
                  <span className="value">{params.data?.msmedia_totalcost}</span>
                </li>

                <li className={classes.listItem}>
                  <span className="key">Registration Starts On:</span>
                  <span className="value" style={{ marginLeft: "1rem" }}>
                    {params.data?.msmedia_registrationopenson !== null
                      ? moment(params.data?.msmedia_registrationopenson).format(
                          "DD MMM YYYY, h:mm"
                        )
                      : ""}
                  </span>
                </li>
                <li className={classes.listItem}>
                  <span className="key">Registration Ends On:</span>
                  <span className="value" style={{ marginLeft: "1.6rem" }}>
                    {params.data?.msmedia_registrationcloseson !== null
                      ? moment(
                          params.data?.msmedia_registrationcloseson
                        ).format("DD MMM YYYY, h:mm")
                      : ""}
                  </span>
                </li>
                <li className={classes.listItem}>
                  <span className="key">Cancellation Deadline:</span>
                  <span className="value" style={{ marginLeft: "1.6rem" }}>
                    {params.data?.ctpt_cancellationduedate !== null
                      ? moment(params.data?.ctpt_cancellationduedate).format(
                          "DD MMM YYYY, h:mm"
                        )
                      : ""}
                  </span>
                </li>
              </ul>
            </div>
          </Grid>
          <Grid item xs={12} md={5} className={classes.itemStyle}>
            <div className={classes.documentSection}>
              <h1 className="header">Materials</h1>
              {!registerEvent ? (
                <ul className={classes.detailTable}>
                  {supportingDocs?.length > 0 ? (
                    supportingDocs.map((doc) => (
                      <li className={classes.listItem} key={doc.name}>
                        <span className="key">{doc.name}:</span>
                        <span className="value">
                          <a
                            href={doc.url}
                            target="_blank"
                            className={classes.docLink}
                            rel="noreferrer"
                          >
                            View &rarr;
                          </a>
                        </span>
                      </li>
                    ))
                  ) : (
                    <h3>Information not available</h3>
                  )}
                </ul>
              ) : (
                <h3 style={{ paddingLeft: "1rem" }}>
                  Please register to access materials
                </h3>
              )}
            </div>
          </Grid>
        </Grid>
        <div className={classes.sessionContainer}>
          <h1 className={classes.sessionHeader}>Sessions</h1>
          <div className={classes.sessionItems}>
            {sessionInfo?.length > 0 ? (
              sessionInfo.map((session) => (
                <div className="session-item-cont">
                  <div className={classes.sessionList}>
                    <TileCard
                      key={session.msmedia_name}
                      title={session.msmedia_name}
                      //image={<img src={`/brand/leaf.jpg`} alt="member" />}
                      linkURL={""}
                      linkText={""}
                      items={[
                        {
                          "Start Date":
                            session.msmedia_startdatetime !== null
                              ? new Date(session.msmedia_startdatetime)
                                  .toISOString()
                                  .substring(0, 10)
                              : "",
                        },
                        {
                          "End Date":
                            session.msmedia_enddatetime !== null
                              ? new Date(session.msmedia_enddatetime)
                                  .toISOString()
                                  .substring(0, 10)
                              : "",
                        },
                      ]}
                      variant={"PROFILE"}
                      colour={brandPalette["Primary"][800]}
                    />
                  </div>
                </div>
              ))
            ) : (
              <h3>Information not available</h3>
            )}
          </div>
          <div className={classes.center}>
            <Button
              variant="contained"
              color="primary"
              className={classes.registerBtn}
              disabled={!enableCta}
              onClick={() => {
                if (!registerEvent) {
                  onPressCancelRegister();
                } else {
                  onPressRegister();
                }
              }}
            >
              {!registerEvent ? "Cancel Exam" : "Register Exam"}
            </Button>
            <Button
              variant="outlined"
              color="primary"
              className={classes.registerBtn}
              style={{ marginLeft: "1rem" }}
              onClick={() => {
                history.replace("/exam/register");
              }}
            >
              {"Go back"}
            </Button>
          </div>
        </div>
      </StartPage>
    </ThemeProvider>
  );
}

export default CreateOrder(ExamDetails);
