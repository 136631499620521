import React, { useEffect } from "react";
import { Switch } from "react-router-dom";

import {
  Home,
  Applications,
  IndvMembership,
  FirmMembership,
  Profile,
  Prepayment,
  Paymentsucess,
  Paymentfailure,
  Letterofgood,
  Preferences,
  Invoices,
  TutionProvider,
  StudentApplication,
  TrainingEmployer,
  StudentService,
  Upgrade,
  UpgradeTechnician,
  UpgradeFellow,
  Notifications,
  UpgradeAssociate,
  ViewRequest,
  DuplicateCertificate,
  MemberDirectory,
  FirmDirectory,
  ClearanceApplication,
  ExemptionCertificate,
  ExamResult,
  EventDetails,
  Events,
  MemberDeclarations,
  EvidenceDetails,
  ContractualRecords,
  ViewRecord,
  AboutYou,
  ViewContactDetails,
  ViewIdentityDetails,
  Nationalities,
  WorkAddress,
  PersonalAddress,
  PayLater,
  PaymentSuccessStatic,
  PostalAddress,
  EmailUpdate,
  NoAccess,
  CustomError,
  ViewEvidence,
  StudentPlacement,
  Exams,
  ExamDetail,
  AddEvidence,
  UploadCV,
  PlacementDetails,
  Qualifications,
  ViewQualificationDetails,
  EmploymentHistory,
  ViewEmploymentHistoryDetails,
  ProfessionalHistory,
  ViewProfessionalHistoryDetails,
  ExamCertificates
} from "app/pages";
import PrivateRoute from "./privateRoute";
import PublicRoute from "./publicRoute";
import AccountInactive from "app/pages/accountInactive";
import { useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";

export default function Pages() {
  const location = useLocation();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch({
      type: "SET_SHOW_LOADER",
      payload: { status: false, message: "" },
    });
  }, [location, dispatch]);

  return (
    <Switch>
      <PublicRoute
        restricted={false}
        component={Home}
        path="/"
        exact
        siteNav="visible"
      />
      <PrivateRoute
        component={Applications}
        path="/applications"
        exact
        siteNav="visible"
      />
      <PrivateRoute
        component={IndvMembership}
        path="/application/individualMembership"
        exact
        siteNav="hidden"
      />
      <PrivateRoute
        component={FirmMembership}
        path="/application/firmMembership_nonaat"
        exact
        siteNav="hidden"
      />
      <PrivateRoute
        component={Profile}
        path="/profile"
        exact
        siteNav="visible"
      />
      <PrivateRoute
        component={FirmMembership}
        path="/application/firmMembership_nonaudit"
        exact
        siteNav="hidden"
      />
      <PrivateRoute
        component={Prepayment}
        path="/application/prepayment"
        exact
        siteNav="visible"
      />
      <PrivateRoute
        component={Paymentsucess}
        path="/application/prepayment/sucess"
        exact
        siteNav="visible"
      />
      <PrivateRoute
        component={Paymentfailure}
        path="/application/prepayment/failure"
        exact
        siteNav="visible"
      />
      <PrivateRoute
        component={Letterofgood}
        path="/members/letter"
        exact
        siteNav="visible"
      />
      <PrivateRoute
        component={Profile}
        path="/profile"
        exact
        siteNav="visible"
      />
      <PrivateRoute
        component={AboutYou}
        path="/profile/aboutyou"
        exact
        siteNav="visible"
      />
      <PrivateRoute
        component={ViewContactDetails}
        path="/updatecontact"
        exact
        siteNav="visible"
      />
      <PrivateRoute
        component={ViewIdentityDetails}
        path="/updateidentity"
        exact
        siteNav="visible"
      />
      <PrivateRoute
        component={Nationalities}
        path="/profile/nationalities"
        exact
        siteNav="visible"
      />
      <PrivateRoute
        component={WorkAddress}
        path="/profile/workaddress"
        exact
        siteNav="visible"
      />
      <PrivateRoute
        component={PersonalAddress}
        path="/profile/personaladdress"
        exact
        siteNav="visible"
      />
      <PrivateRoute
        component={PostalAddress}
        path="/profile/postaladdress"
        exact
        siteNav="visible"
      />
      <PrivateRoute
        component={Preferences}
        path="/preferences"
        exact
        siteNav="visible"
      />
      <PrivateRoute
        component={Invoices}
        path="/invoices"
        exact
        siteNav="visible"
      />
      <PrivateRoute
        component={TutionProvider}
        path="/application/tutionProvider"
        exact
        siteNav="hidden"
      />
      <PrivateRoute
        component={StudentApplication}
        path="/application/studentApplication"
        exact
        siteNav="hidden"
      />
      <PrivateRoute
        component={TrainingEmployer}
        path="/application/trainingemployer"
        exact
        siteNav="hidden"
      />
      <PrivateRoute
        component={StudentService}
        path="/membership/service-requests"
        exact
        siteNav="hidden"
      />
      <PrivateRoute
        component={Upgrade}
        path="/members/upgrade"
        exact
        siteNav="hidden"
      />
      <PrivateRoute
        component={UpgradeTechnician}
        path="/application/upgradeTechnician"
        exact
        siteNav="hidden"
      />
      <PrivateRoute
        component={UpgradeFellow}
        path="/application/upgradeFellow"
        exact
        siteNav="hidden"
      />
      <PrivateRoute
        component={Notifications}
        path="/notifications"
        exact
        siteNav="hidden"
      />
      <PrivateRoute
        component={UpgradeAssociate}
        path="/application/upgradeAssociate"
        exact
        siteNav="hidden"
      />
      <PrivateRoute
        component={ViewRequest}
        path="/members/viewrequest"
        exact
        siteNav="hidden"
      />
      <PrivateRoute
        component={DuplicateCertificate}
        path="/application/duplicateCertificate"
        exact
        siteNav="hidden"
      />
       <PrivateRoute
        component={ExamCertificates}
        path="/exam/certificates"
        exact
        siteNav="visible"
      />
      <PrivateRoute
        component={ViewEvidence}
        path="/viewEvidence"
        exact
        siteNav="visible"
      />
      <PublicRoute
        restricted={false}
        component={MemberDirectory}
        path="/memberDirectory"
        exact
        siteNav="visible"
      />
      <PublicRoute
        restricted={false}
        component={FirmDirectory}
        path="/firmDirectory"
        exact
        siteNav="visible"
      />
      <PrivateRoute
        restricted={false}
        component={ClearanceApplication}
        path="/application/clearanceCertificate"
        exact
        siteNav="visible"
      />
      <PrivateRoute
        restricted={false}
        component={ExemptionCertificate}
        path="/application/exemptionCertificate"
        exact
        siteNav="visible"
      />
      <PrivateRoute
        restricted={false}
        component={ExamResult}
        path="/exam/reports"
        exact
        siteNav="visible"
      />
      <PrivateRoute
        component={Events}
        path="/event/registration"
        exact
        siteNav="visible"
      />
      <PrivateRoute
        component={EventDetails}
        path="/event/details"
        exact
        siteNav="visible"
      />
      <PrivateRoute
        component={MemberDeclarations}
        path="/cpd/declaration"
        exact
        siteNav="visible"
      />
      <PrivateRoute
        component={EvidenceDetails}
        path="/exam/evidence"
        exact
        siteNav="visible"
      />
      <PrivateRoute
        component={ContractualRecords}
        path="/membership/student-training-agreement"
        exact
        siteNav="visible"
      />
      <PrivateRoute
        component={ViewRecord}
        path="/cpd/viewrecord"
        exact
        siteNav="visible"
      />
      <PrivateRoute
        component={PayLater}
        path="/application/prepayment/pay-later"
        exact
        siteNav="visible"
      />
      <PrivateRoute
        component={PaymentSuccessStatic}
        path="/application/prepayment/payment-success"
        exact
        siteNav="visible"
      />
      <PrivateRoute
        component={PaymentSuccessStatic}
        path="/application/success"
        exact
        siteNav="visible"
      />
      <PublicRoute
        component={NoAccess}
        path="/noaccess"
        exact
        siteNav="visible"
      />
      <PrivateRoute
        component={EmailUpdate}
        path="/profile/update-email"
        exact
        siteNav="visible"
      />
      <PrivateRoute
        component={UploadCV}
        path="/profile/upload-cv"
        exact
        siteNav="visible"
      />
      <PrivateRoute
        component={Exams}
        path="/exam/register"
        exact
        siteNav="visible"
      />
      <PrivateRoute
        component={ExamDetail}
        path="/exam-detail"
        exact
        siteNav="visible"
      />
      <PrivateRoute
        component={AddEvidence}
        path="/add-evidence"
        exact
        siteNav="visible"
      />
      <PrivateRoute
        component={PlacementDetails}
        path="/placement-details"
        exact
        siteNav="visible"
      />
      <PublicRoute
        component={AccountInactive}
        path="/inactive"
        exact
        siteNav="visible"
      />
      <PublicRoute
        component={CustomError}
        path="/error"
        exact
        siteNav="visible"
      />
      <PrivateRoute
        component={StudentPlacement}
        path="/student-placement"
        exact
        siteNav="visible"
      />
      <PrivateRoute
        component={Qualifications}
        path="/profile/qualifications"
        exact
        siteNav="visible"
      />
      <PrivateRoute
        component={ViewQualificationDetails}
        path="/profile/viewqualificationdetails"
        exact
        siteNav="visible"
      />
      <PrivateRoute
        component={EmploymentHistory}
        path="/profile/employmenthistory"
        exact
        siteNav="visible"
      />
      <PrivateRoute
        component={ViewEmploymentHistoryDetails}
        path="/profile/viewemploymenthistorydetails"
        exact
        siteNav="visible"
      />
      <PrivateRoute
        component={ProfessionalHistory}
        path="/profile/professionalhistory"
        exact
        siteNav="visible"
      />
      <PrivateRoute
        component={ViewProfessionalHistoryDetails}
        path="/profile/viewprofessionalhistorydetails"
        exact
        siteNav="visible"
      />
    </Switch>
  );
}
