import CustomTextBox from "app/components/atoms/customTextBox";
import CustomDate from "app/components/atoms/customDate";
import CustomSelect from "app/components/atoms/customSelect";

export const viewQualificationDetailsUiSchema = {
    "ui:title": "",
    mshied_name: {
        "ui:widget": (props) => {
            return <CustomTextBox getProps={props} />;
        },
    },
    ctpt_schoolname: {
        "ui:widget": (props) => {
            return <CustomTextBox getProps={props} />;
        },
    },
    ctpt_startdate: {
        "ui:widget": (props) => {
            return <CustomDate getProps={props} />;
        },
    },
    ctpt_enddate: {
        "ui:widget": (props) => {
            return <CustomDate getProps={props} />;
        },
    },
    _mshied_educationlevelid_value: {
        "ui:widget": (props) => {
            return <CustomSelect getProps={props} />;
        },
    },
    ctpt_grade: {
        "ui:widget": (props) => {
            return <CustomTextBox getProps={props} />;
        },
    },
    ctpt_level: {
        "ui:widget": (props) => {
            return <CustomTextBox getProps={props} />;
        },
    },
    ctpt_type: {
        "ui:widget": (props) => {
            return <CustomSelect getProps={props} />;
        },
    },
    mshied_graduationdate: {
        "ui:widget": (props) => {
            return <CustomDate getProps={props} />;
        },
    },

};

export const addNewQualificationDetailsUiSchema = {
    "ui:title": "",
    mshied_name: {
        "ui:widget": (props) => {
            return <CustomTextBox getProps={props} />;
        },
    },
    ctpt_schoolname: {
        "ui:widget": (props) => {
            return <CustomTextBox getProps={props} />;
        },
    },
    ctpt_startdate: {
        "ui:widget": (props) => {
            return <CustomDate getProps={props} />;
        },
    },
    ctpt_enddate: {
        "ui:widget": (props) => {
            return <CustomDate getProps={props} />;
        },
    },
    mshied_educationlevelid: {
        "ui:widget": (props) => {
            return <CustomSelect getProps={props} />;
        },
    },
    ctpt_grade: {
        "ui:widget": (props) => {
            return <CustomTextBox getProps={props} />;
        },
    },
    // ctpt_level: {
    //     "ui:widget": (props) => {
    //         return <CustomTextBox getProps={props} />;
    //     },
    // },
    ctpt_type: {
        "ui:widget": (props) => {
            return <CustomSelect getProps={props} />;
        },
    },
    mshied_graduationdate: {
        "ui:widget": (props) => {
            return <CustomDate getProps={props} />;
        },
    },

};
