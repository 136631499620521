//Import React Library
import React, { useState } from 'react';

import { settings } from "constants/settings"

async function loadComponent() {
  return import('./CustomTextBox.' + settings.UI + '.js')
}

function CustomTextBox(props) {

  const { children, data, getProps } = props;

  const [component, setComponent] = useState(null)

  loadComponent().then(x => setComponent(x))

  if (!component) return null;
  return (
    <component.default data={data} getProps={getProps}>{children}</component.default>
  )
}

export default CustomTextBox;