import CustomSelect from "app/components/atoms/customSelect";

export const getNationalityUiSchema = {
    "ui:title": "",
    ctpt_nationalityid: {
        "ui:widget": props => {
            return <CustomSelect getProps={props} />;
        }
    },

};
