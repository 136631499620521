import React, { useEffect, useState } from "react";
import { Grid, Button } from "@material-ui/core";
import { AuthenticatedTemplate } from "@azure/msal-react";
import { ThemeProvider } from "@material-ui/core/styles";
import { globalTheme } from "style/globalTheme";
import { useSelector } from "react-redux";
import styled from "styled-components";
import FormWidget from "app/components/mvp/formWidget";
import { getStudentUiSchema } from "./model/UiSchema";
import { getrecordPayload } from "./model/payload";
import { config } from "utilities/config";
import { useLocation, useHistory } from "react-router-dom";
import { post } from "app/services/api";
import { getEvidenceSchema } from "./model/schema";
import { useStyles } from "./style";
import { usePreLoader } from "app/services/hooks/usePreLoader";
import clsx from "clsx";

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const StartPage = styled.section`
  ${globalTheme.breakpoints.up("sm")} {
    padding: 2rem 8rem 4rem 8rem;
  }
  ${globalTheme.breakpoints.down("xs")} {
    padding: 1rem;
  }
`;

function ViewEvidence() {
  const [recordId, setrecordId] = useState("");
  const [contact2FormData, setContact2FormData] = useState({});
  const getcontactId = useSelector((state) => state.contactid);
  let query = useQuery();
  let formDataa = {};
  const { enableLoader } = usePreLoader();

  const classes = useStyles();
  const history = useHistory();

  const getRecordDetails = (payload) => {
    let api = config.teleportal.submitForm.getId;
    if (recordId !== "") {
      enableLoader(true, "Loading Evidence Details");
      post(api, payload)
        .then((res) => {
          res.data.data.forEach((item) => {
            console.log(item);
            formDataa[item.name] = item.value;
          });
          setContact2FormData(formDataa);
        })
        .catch(console.log)
        .finally(() => {
          enableLoader(false);
        });
    }
  };
  console.log("CCONS:", contact2FormData);
  const options = {
    widgetType: "update",
    formSchema: getEvidenceSchema(getcontactId),
    uiSchema: getStudentUiSchema,
    entityType: "ctpt_evidences",
    formUischema: "",
    button1Label: "",
    button2Label: "",
    historyUrl: "",
    columns: [],
    contactId: getcontactId,
    formData: contact2FormData,
    hasModel: false,
  };

  useEffect(() => {
    if (window.location.href.indexOf("?") > -1) {
      setrecordId(query.get("id"));
    }
  }, []);

  useEffect(() => {
    let payload = {
      ...getrecordPayload,
      id: recordId,
    };

    getRecordDetails(payload);
  }, [recordId]);

  return (
    <ThemeProvider theme={globalTheme}>
      <AuthenticatedTemplate>
        <Grid container spacing={0}>
          {/* NOTE:layout */}
          {/* <Grid item xs={12} md={2}></Grid> */}
          <Grid item xs={12} md={12}>
            <StartPage>
              <FormWidget options={options}></FormWidget>
              {contact2FormData.ctpt_evidenceurl && (
                <div>
                  <Button
                    size="medium"
                    variant="contained"
                    color="primary"
                    onClick={() =>
                      window.open(contact2FormData.ctpt_evidenceurl, "_blank")
                    }
                    style={{ marginLeft: "1rem" }}
                  >
                    View Evidence Document
                  </Button>
                  <Button
                    size="medium"
                    variant="outlined"
                    color="primary"
                    onClick={() => {
                      history.goBack();
                    }}
                    style={{ marginLeft: "1.5rem" }}
                  >
                    Go Back
                  </Button>
                </div>
              )}
            </StartPage>
          </Grid>
          {/* <Grid item xs={12} md={2}></Grid> */}
        </Grid>
      </AuthenticatedTemplate>
    </ThemeProvider>
  );
}

export default ViewEvidence;
