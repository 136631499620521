import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Snackbar from "@material-ui/core/Snackbar";
import styled, { css } from "styled-components";
import IconButton from "@material-ui/core/IconButton";
import Row from "layout/Row";

import { useStyles } from "./style";
import "./status.css";
import { globalTheme } from "style/globalTheme";

import CloseIcon from "@material-ui/icons/Cancel";
import WarningIcon from "@material-ui/icons/Warning";
import InfoIcon from "@material-ui/icons/Info";
import SuccessIcon from "@material-ui/icons/CheckCircle";
import {
  Button,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  DialogActions,
} from "@material-ui/core";

const StatusMsg = styled(Row)(
  (props) => css`
    background: ${props.severity === "info" &&
      globalTheme.palette.primaryTint[50]}${props.severity === "success" &&
      globalTheme.palette.success.light}${props.severity === "warning" &&
      globalTheme.palette.warning.light}${props.severity === "error" &&
      "#f4bcc6"};
    min-width: 340px;
    border-radius: 0 0 0 1rem;
    position: fixed;
    top: 4rem;
    right: 0px;
    ${globalTheme.breakpoints.up("lg")} {
      right: calc((100vw - 1280px) / 2);
      max-width: calc((1280px / 100) * 65);
    }
    ${globalTheme.breakpoints.down("lg")} {
      right: 0px;
      max-width: 70vw;
    }
  `
);

export default function Statusbar() {
  const classes = useStyles();
  const status = useSelector((state) => state.status);
  const classValue = status.display;
  const [open, setOpen] = React.useState(false);
  const isFormWidgetOpenInListWidget = localStorage.getItem(
    "isFormWidgetOpenInListWidget"
  );
  const dispatch = useDispatch();
  useEffect(() => {
    if (classValue === "hide") {
      setOpen(false);
    } else if (classValue === "show") {
      setOpen(true);
    }
  }, [classValue, status]);

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
    dispatch({ type: "SET_STATUS", payload: { ...status, display: "hide" } });
  };

  let barIcon;
  switch (status.severity) {
    case "success":
      barIcon = <SuccessIcon />;
      break;
    case "error":
      barIcon = <WarningIcon />;
      break;
    case "warning":
      barIcon = <WarningIcon />;
      break;
    default:
      barIcon = <InfoIcon />;
  }

  const marginRightSnackBar =
    window.innerWidth - 1280 > 0 ? (window.innerWidth - 1280) / 2 : 0;

  if (isFormWidgetOpenInListWidget)
    return (
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle>Unsupported File format</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {status.message}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} autoFocus>
            OK
          </Button>
        </DialogActions>
      </Dialog>
    );

  return (
    <div
      id="statusbar"
      className={`${classes.root} ${
        classValue === "hide" ? classes.hide : classes.show
      }`}
    >
      <Snackbar
        open={open}
        onClose={handleClose}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        autoHideDuration={6000}
      >
        <StatusMsg
          severity={status.severity}
          justify="between"
          align="center"
          padding="small"
          style={{
            marginRight: `${marginRightSnackBar}px`,
            marginTop: ".5rem",
          }}
        >
          <Row gap="medium" align="center">
            <div>{barIcon}</div>
            <div>{status.message}</div>
          </Row>
          <IconButton
            size="small"
            aria-label="close"
            color="inherit"
            onClick={handleClose}
          >
            <CloseIcon fontSize="small" />
          </IconButton>
        </StatusMsg>
      </Snackbar>
    </div>
  );
}
