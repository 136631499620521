import { makeStyles } from "@material-ui/core/styles";
import { brandPalette } from "brand/theme/brandTheme";

export const useStyles = makeStyles((theme) => ({
  docLink: {
    "&:link, &:visited": {
      fontSize: "1rem",
      color: brandPalette.Primary[500],
      display: "inline-block",
      textDecoration: "none",
      borderRadius: "0rem",
      borderBottom: `1px solid ${brandPalette.Primary[500]}`,
      padding: "3px",
      width: "max-content",
      transition: "all 0.2s",
    },
    "&:hover": {
      backgroundColor: brandPalette.Primary[600],
      color: "white",
      boxShadow: "0 1rem 2rem rgba(0,0,0, 0.15)",
      borderRadius: "100px",
      paddingRight: "1rem",
      paddingLeft: "1rem",
      paddingTop: "3px",
      paddingBottom: "3px",
    },

    "&:active": {
      boxShadow: "0 0.5rem 1rem rgba(0,0,0, 0.15)",
      transform: "translateY(0)",
    },
  },
}));
