import { appMenuItems } from "app/components/molecules/navbar/model/commonMenuData";

export const MEMBERSHIP_TYPE_MAP = {
  1: "Fellow (FCA)",
  2: "Fellow (FCPA)",
  3: "Associate Chartered Accountant",
  4: "Associate (ACPA)",
  5: "Accounting Technician",
  6: "Student",
};

export const HOME_TILES_DATA = [
  {
    title: "Your applications",
    items: appMenuItems[0].items,
  },
  {
    title: "Membership",
    items: appMenuItems[1].items,
  },
  {
    title: "Apprenticeship",
    items: appMenuItems[2].items,
  },
  {
    title: "Account",
    items: appMenuItems[3].items,
  },
  {
    title: "Events & Exams",
    items: appMenuItems[4].items,
  },
  {
    title: "CPD",
    items: appMenuItems[5].items,
  },
];
