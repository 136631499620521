import PayLater from "./PayLater";
import React from "react";
import PayThroughSponsor from "./PayThroughSponsor";
import PayUsingDebitCard from "./PayUsingDebitCard";
import UploadProofOfPayment from "./UploadProofOfPayment";
function PaymentMethods({
  paymentMethod,
  payDetails,
  payloads,
  cancelPayment,
  finishPayment,
  fullName,
  email,
  emailRegex,
  emailError,
  handleInputChange,
}) {
  if (paymentMethod === "1") {
    return <PayUsingDebitCard payDetails={payDetails} payloads={payloads} />;
  }
  if (paymentMethod === "2") {
    return (
      <UploadProofOfPayment
        cancelPayment={cancelPayment}
        finishPayment={finishPayment}
      />
    );
  }
  if (paymentMethod === "3") {
    return (
      <PayThroughSponsor
        cancelPayment={cancelPayment}
        finishPayment={finishPayment}
        fullName={fullName}
        email={email}
        emailRegex={emailRegex}
        emailError={emailError}
        handleInputChange={handleInputChange}
      />
    );
  }
  if (paymentMethod === "4") {
    return <PayLater payDetails={payDetails} />;
  }
  return <div />;
}

export default PaymentMethods;
