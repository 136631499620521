import React from "react";
import { AuthenticatedTemplate } from "@azure/msal-react";
import { ThemeProvider } from "@material-ui/core/styles";
import { globalTheme } from "style/globalTheme";
import styled from "styled-components";
import ErrorIcon from "@material-ui/icons/Warning";
import { useHistory } from "react-router-dom";
import { useStyles } from "./style";
import {Button, Box } from "@material-ui/core";


const StartPage = styled.section`
  ${globalTheme.breakpoints.up("sm")} {
    padding: 4rem 4rem 4rem 300px;
  }
  ${globalTheme.breakpoints.down("xs")} {
    padding: 1rem;
  }
`;

function Paymentfailure(props) {
  const classes = useStyles();
  let history = useHistory();
  return( 
    <ThemeProvider theme={globalTheme}>
    <AuthenticatedTemplate>
    <StartPage>
    <ErrorIcon /> <h1 className={classes.headingTxt}>Your payment failed, please try again</h1>
    <p>Your reference number XXXXXXXX </p>
    <div className={classes.desc}>
<p>Some content can go  here to explain reason(s) it may have failed.</p>
<ul>
  <li>Please try again to make payment</li>
  <li>If you’ve tried again and it’s still not working, call us on XXX</li>
</ul>
    </div>
    <Box sx={{ p: 2}}><Button color="primary" variant="contained" onClick={()=> history.push("/application/prepayment")}> Try payment again</Button></Box>
    <Box sx={{ p: 2}}><Button color="primary" variant="outlined" onClick={()=> history.push("/")}> Go to homepage</Button></Box>
    </StartPage>
    </AuthenticatedTemplate>
    </ThemeProvider>
  )

}

export default Paymentfailure;