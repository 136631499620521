export const GetNationalityPayload = {
  properties: ["ctpt_title", "ctpt_nationalityid"],
  // formattedFields: [
  //     "_ctpt_nationalityid_value"
  // ],
  recordType: "ctpt_nationalities",
  // filters: [
  //   {
  //     field: "_ctpt_contactid_value",
  //     searchvalue: contactid,
  //     type: "guid"
  //   }
  // ],
  filter: {
    globalfilter: "",
    globalAndOperator: false,
    fieldAndOperator: false,
    filterfields: [
      // {
      //     field: "_ctpt_contactid_value",
      //     searchvalue: "contactid",
      //     type: "guid"
      // }
    ],
  },
  Aggregate: {
    AggregateFields: [],
  },
  sortfield: {
    field: "ctpt_title",
    sorttype: "asc",
  },
  idfield: "ctpt_nationalityid",
  continuationToken: "",
  pazesize: "10",
};
