import CustomTextBox from "app/components/atoms/customTextBox";
import CustomSelect from "app/components/atoms/customSelect";
import Upload from "app/components/atoms/upload";
const componentType = {
  upload: {
    "ui:widget": (props) => {
      return (
        <Upload
          recordType={props.schema.containerName}
          recordPath={props.schema.recordPath}
          getProps={props}
        />
      );
    },
  },
};
export const getStudentUiSchema = {
  "ui:title": "",
  ctpt_title: {
    "ui:widget": (props) => {
      return <CustomTextBox getProps={props} />;
    },
  },
  ctpt_specialrequirements: {
    "ui:widget": (props) => {
      return <CustomTextBox getProps={props} />;
    },
  },
  ctpt_casetypeid: {
    "ui:widget": (props) => {
      return <CustomSelect getProps={props} />;
    },
  },
  ctpt_documenturl: componentType.upload,
};

export const getDocumentUiSchema = {
  "ui:title": "",
  ctpt_name: {
    "ui:widget": "hidden",
  },
  ctpt_documenttype: {
    "ui:widget": "hidden",
  },
  ctpt_case: {
    "ui:widget": "hidden",
  },
  ctpt_documenturl: {
    "ui:widget": "hidden",
  },
};
