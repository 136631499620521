import React from "react";
import styled from "styled-components";
import { Button } from "@material-ui/core";
import {globalTheme  } from "style/globalTheme";

const Btn = styled(Button)`
min-width: 130px;
  height: 40px;
  color: #fff;
  padding: 5px 10px;
  font-weight: bold;
  cursor: pointer;
  transition: all 0.3s ease;
  position: relative;
  display: inline-block;
  outline: none;
  border: 1px solid #4433ff;
  background: ${globalTheme.palette.primary.main};
  &&& {
    fontSize:13px
  }
  
 &:hover {
    transform: scale(1.05);
    background-color: #a9a9a9;
  }`;

function RoundCornerButton({ children, ...props }) {
  return <Btn {...props}>{children}</Btn>;
}

export default RoundCornerButton;
