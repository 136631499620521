export const AddNationalityPayload = (jsonData) => {
    const details = jsonData.data
    var payload;
    payload = {

        "recordtype": "ctpt_contactnationalities",
        "data": [
            {
                "name": "ctpt_NationalityId",
                "value": details[0].value,
                "type": "lookup",
                "relatedentity": "ctpt_nationalities"
            },
            {
                "name": "ctpt_ContactId",
                "value": jsonData.contactid,
                "type": "lookup",
                "relatedentity": "contacts"
            }

        ]
    }
    return payload;
}

