import {useEffect} from 'react';
import { appData } from "data/appData";
import favIcon from "assets/favicon1.ico";

export default function PageProperties() {

  
  useEffect(()=>{
    const faviCon = document.getElementById("favicon");
  faviCon.href=favIcon;
  document.title=appData[0].pageDetails.header.title;

  })

  return false
}