import React, { useEffect, useState } from "react";
import { Grid, Button } from "@material-ui/core";
import { AuthenticatedTemplate } from "@azure/msal-react";
import { ThemeProvider } from "@material-ui/core/styles";
import { globalTheme } from "style/globalTheme";
import { useSelector, useDispatch } from "react-redux";
import styled from "styled-components";
import FormWidget from "app/components/mvp/formWidget";
import { getidentityrecordPayload,updateIdentityPayload } from "data/payload/contactInfoPayload";
import { config } from "utilities/config";
import { useLocation, useHistory } from "react-router-dom";
import { post } from "app/services/api";
import { useStyles } from "./style";
import { usePreLoader } from "app/services/hooks/usePreLoader";
import clsx from "clsx";
import { getIdentityDetailsSchema } from "./model/schema";
import { identityUiSchema } from "./model/contactUpdateUiSchema";
import { IDENTITY_TYPE_MAP } from "./model/constants";

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const StartPage = styled.section`
  ${globalTheme.breakpoints.up("sm")} {
    padding: 2rem 8rem 4rem 8rem;
  }
  ${globalTheme.breakpoints.down("xs")} {
    padding: 1rem;
  }
`;

function ViewIdentityDetails() {
  const [recordId, setrecordId] = useState("");
  const [contact2FormData, setContact2FormData] = useState({});
  const getcontactId = useSelector((state) => state.contactid);
  let query = useQuery();
  let formDataa = {};
  const { enableLoader } = usePreLoader();
 
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();

  const getRecordDetails = (payload) => {
    let api = config.teleportal.submitForm.getId;
    if (recordId !== "") {
      enableLoader(true, "Loading Identity Details");
      post(api, payload)
        .then((res) => {
          res.data.data.forEach((item) => {
            console.log(item);
            if(item.name=="ctpt_type"){
              formDataa[item.name] = IDENTITY_TYPE_MAP[item.value];
            }
            else formDataa[item.name] = item.value;
          });
          updateFormData({}, formDataa);
          //setContact2FormData(formDataa);
        })
        .catch(console.log)
        .finally(() => {
          enableLoader(false);
        });
    }
  };
  const options = {
    widgetType: "update",
    formSchema: getIdentityDetailsSchema,
    uiSchema: identityUiSchema,
    entityType: "ctpt_identities",
    formUischema: "",
    button1Label: "Save",
    button2Label: "Go Back",
    goBackPage: "/profile/aboutyou",
    apiURL: config.teleportal.submitForm.Update,
    payloadSubmit: updateIdentityPayload,
    historyUrl: "",
    contactId: getcontactId,
    formData: contact2FormData,
    button2GoBack: true,
    recordId:recordId
  };

  const [contactOptions, setContactOptions] = useState(options);

  useEffect(() => {
    if (window.location.href.indexOf("?") > -1) {
      setrecordId(query.get("id"));
    }
  }, []);

  useEffect(() => {
    let payload = {
      ...getidentityrecordPayload,
      id: recordId,
    };

    getRecordDetails(payload);
  }, [recordId]);

  const updateFormData = (entityType, formData) => {
    // if (userRole && userRole.name && userRole.name.includes('Individual') &&
    //   permissions &&
    //   permissions.permissionsets != null &&
    //   permissions.permissionsets.length > 0 &&
    //   permissions.permissionsets[0] != null &&
    //   permissions.permissionsets[0].canwrite == "0" &&
    //   permissions.permissionsets[0].cancreate == "0") 
    //   {
    //   contact.formSchema.properties.firstname.disabled = true;
    //   contact.formSchema.properties.lastname.disabled = true;
    //   contact.formSchema.properties.birthdate.isReadonly = true;
    //   contact.formSchema.properties.ctpt_isresidentofbotswana.disabled = true
    //   contact.formSchema.properties.ctpt_othername.disabled = true
    //   contact.formSchema.properties.ctpt_profiledetailsconfirmedaccurateon.isReadonly = true
    //   contact.formSchema.properties.fax.disabled = true
    //   contact.formSchema.properties.fullname.disabled = true
    //   contact.formSchema.properties.salutation.disabled = true
    // }
    
    options.formData = formData;
    options.initialFormData = formData;
    setContactOptions(options);
    setContact2FormData(formData);
  };

  const getResponse = (res) => {
    if (res.data.id) {
      dispatch({
        type: "SET_STATUS",
        payload: {
          message: "Record updated successfully",
          code: "",
          display: "show",
          severity: "success",
        },
      });
    } else {
      dispatch({
        type: "SET_STATUS",
        payload: {
          message: "Record update failed",
          code: "",
          display: "show",
          severity: "error",
        },
      });
    }
  };


  return (
    <ThemeProvider theme={globalTheme}>
      <AuthenticatedTemplate>
        <Grid container spacing={0}>
          {/* NOTE:layout */}
          {/* <Grid item xs={12} md={2}></Grid> */}
          <Grid item xs={12} md={12}>
            <StartPage>
              <FormWidget options={contactOptions}
               updateFormData={updateFormData}
               successResponse={getResponse}></FormWidget>
              {/* {contact2FormData.ctpt_telephone && (
                <div>
                  <Button
                    size="medium"
                    variant="outlined"
                    color="primary"
                    onClick={() => {
                      history.goBack();
                    }}
                    style={{ marginLeft: "1.5rem" }}
                  >
                    Go Back
                  </Button>
                </div>
              )} */}
            </StartPage>
          </Grid>
          {/* <Grid item xs={12} md={2}></Grid> */}
        </Grid>
      </AuthenticatedTemplate>
    </ThemeProvider>
  );
}

export default ViewIdentityDetails;
