import moment from "moment";

const thisDate=moment(new Date()).format("YYYY-MM-DD");

export const ContactPayload = (JsonData) => {
  const details = JsonData.data;
  var payload;
  payload = {
    id: JsonData.id,
    recordtype: JsonData.recordtype,
    data: [
      {
        name: "salutation",
        value: details[0].value,
        type: "string",
      },
      {
        name: "firstname",
        value: details[1].value,
        type: "string",
      },
      {
        name: "lastname",
        value: details[2].value,
        type: "string",
      },
      {
        name: "fullname",
        value: details[3].value,
        type: "string",
      },
      {
        name: "ctpt_othername",
        value: details[4].value,
        type: "string",
      },
      {
        name: "birthdate",
        value: details[5].value
          ? moment(new Date(details[5].value)).format("YYYY-MM-DD")
          : undefined,
        type: "string",
      },
      // {
      //   name: "fax",
      //   value: details[6].value,
      //   type: "string",
      // },
      {
        name: "ctpt_isresidentofbotswana",
        value: details[6].value,
        type: "string",
      },
      {
        name: "ctpt_profiledetailsconfirmedaccurateon",
        value: thisDate,
        type: "string",
      },
    ],
  };
  return payload;
};
