export const routerMappingOG = [
  {
    key: "Page 1.0",
    route: "/",
    page: "Member Portal | Home Page | Access",
    component: "home",
    siteNav: "",
    RouteType: "PrivateRoute",
    permissionsets: [],
  },
  {
    key: "Page 1.1",
    route: "/notifications",
    page: "Member Portal | Notifications Page | Access",
    component: "Notifications",
    siteNav: "",
    RouteType: "PrivateRoute",
    permissionsets: [],
  },
  {
    key: "Page 2.0",
    route: "/applications",
    page: "Member Portal | Application Page | Access",
    component: "Applications",
    siteNav: "",
    RouteType: "PrivateRoute",
    permissionsets: [],
  },
  {
    key: "Page 2.1",
    route: "/application/individualMembership",
    page: "Member Portal | Individual Membership | Access",
    component: "IndvMembership",
    siteNav: "",
    RouteType: "PrivateRoute",
    permissionsets: [],
  },
  {
    key: "Page 2.2",
    route: "/application/firmMembership_nonaat",
    page: "Member Portal | Firm Membership Application Page Non AAT | Access",
    component: "FirmMembership",
    siteNav: "",
    RouteType: "PrivateRoute",
    permissionsets: [],
  },
  {
    key: "Page 2.3",
    route: "/application/firmMembership_nonaudit",
    page: "Member Portal | Firm Membership Application Page Non Audit | Access",
    component: "FirmMembership",
    siteNav: "",
    RouteType: "PrivateRoute",
    permissionsets: [],
  },
  {
    key: "Page 2.4",
    route: "/application/studentApplication",
    page: "Member Portal | Student Application Page | Access",
    component: "StudentApplication",
    siteNav: "",
    RouteType: "PrivateRoute",
    permissionsets: [],
  },
  {
    key: "Page 2.5",
    route: "/application/tutionProvider",
    page: "Member Portal | Tuition Provider Application Page | Access",
    component: "TutionProvider",
    siteNav: "",
    RouteType: "PrivateRoute",
    permissionsets: [],
  },
  {
    key: "Page 2.6",
    route: "/application/trainingemployer",
    page: "Member Portal | Training Employer Application Page | Access",
    component: "TrainingEmployer",
    siteNav: "",
    RouteType: "PrivateRoute",
    permissionsets: [],
  },
  {
    key: "Page 2.7",
    route: "/application/exemptionCertificate",
    page: "Member Portal | Credit For Prior Learning Application Page | Access",
    component: "ExemptionCertificate",
    siteNav: "",
    RouteType: "PrivateRoute",
    permissionsets: [],
  },
  {
    key: "Page 3.0",
    route: "/invoices",
    page: "Member Portal | Membership Page | Access",
    component: "Invoices",
    siteNav: "",
    RouteType: "PrivateRoute",
    permissionsets: [],
  },
  {
    key: "Page 3.1",
    route: "/members/upgrade",
    page: "Member Portal | Upgrade Membership Page | Access",
    component: "Upgrade",
    siteNav: "",
    RouteType: "PrivateRoute",
    permissionsets: [],
  },
  {
    key: "Page 3.1.1",
    route: "/application/upgradeTechnician",
    page: "Member Portal | Upgrade Membership Page AT To A | Access",
    component: "UpgradeTechnician",
    siteNav: "",
    RouteType: "PrivateRoute",
    permissionsets: [],
  },
  {
    key: "Page 3.1.2",
    route: "/application/upgradeAssociate",
    page: "Member Portal | Upgrade Membership Page A To F | Access",
    component: "UpgradeAssociate",
    siteNav: "",
    RouteType: "PrivateRoute",
    permissionsets: [],
  },
  {
    key: "Page 3.1.3",
    route: "/application/upgradeFellow",
    page: "Member Portal | Upgrade Membership Page AT To F | Access",
    component: "UpgradeFellow",
    siteNav: "",
    RouteType: "PrivateRoute",
    permissionsets: [],
  },
  {
    key: "Page 3.2",
    route: "/application/duplicateCertificate",
    page: "Member Portal | Duplicate Membership Page | Access",
    component: "DuplicateCertificate",
    siteNav: "",
    RouteType: "PrivateRoute",
    permissionsets: [],
  },
  {
    key: "Page 3.3",
    route: "/members/letter",
    page: "Member Portal | Letter Of Good Standing | Access",
    component: "Letterofgood",
    siteNav: "",
    RouteType: "PrivateRoute",
    permissionsets: [],
  },
  {
    key: "Page 3.4",
    route: "/application/clearanceCertificate",
    page: "Member Portal | Clearance Certificate Page | Access",
    component: "ClearanceApplication",
    siteNav: "",
    RouteType: "PrivateRoute",
    permissionsets: [],
  },
  {
    key: "Page 3.5",
    route: "/membership/service-requests",
    page: "Member Portal | Service Request Page | Access",
    component: "StudentService",
    siteNav: "",
    RouteType: "PrivateRoute",
    permissionsets: [],
  },
  {
    key: "Page 3.5.1",
    route: "/members/viewrequest",
    page: "Member Portal | Service Request Details Page | Access",
    component: "StudentService",
    siteNav: "",
    RouteType: "PrivateRoute",
    permissionsets: [],
  },
  {
    key: "Page 4.0",
    route: "/invoices",
    page: "Member Portal | Invoices Summary Page | Access",
    component: "Invoices",
    siteNav: "",
    RouteType: "PrivateRoute",
    permissionsets: [],
  },
  {
    key: "Page 5.1",
    route: "/exam/register",
    page: "Member Portal |  Exam Registration Page | Access",
    component: "ExamRegistration",
    siteNav: "",
    RouteType: "PrivateRoute",
    permissionsets: [],
  },
  {
    key: "Page 5.1.1",
    route: "/exam-detail",
    page: "Member Portal | Exam Details Page | Access",
    component: "ViewEvent",
    siteNav: "",
    RouteType: "PrivateRoute",
    permissionsets: [],
  },
  {
    key: "Page 5.2",
    route: "/exam/reports",
    page: "Member Portal | Exam Reports Page | Access",
    component: "ExamResult",
    siteNav: "",
    RouteType: "PrivateRoute",
    permissionsets: [],
  },
  {
    key: "Page 5.3",
    route: "/exam/certificates",
    page: "Member Portal | Exam Reports Page | Exam Certificates",
    component: "ExamCertificates",
    siteNav: "",
    RouteType: "PrivateRoute",
    permissionsets: [],
  },
  {
    key: "Page 6.0",
    route: "/profile",
    page: "Member Portal | Profile Page | Access",
    component: "profile",
    siteNav: "",
    RouteType: "PublicRoute",
    permissionsets: [],
  },
  {
    key: "Page 6.1",
    route: "/profile/aboutyou",
    page: "Member Portal | About You Page | Access",
    component: "profile",
    siteNav: "",
    RouteType: "PublicRoute",
    permissionsets: [],
  },
  {
    key: "Page 6.1.1",
    route: "/updatecontact",
    page: "Member Portal | Contact Details | Access",
    component: "ViewContactDetails",
    siteNav: "",
    RouteType: "PublicRoute",
    permissionsets: [],
  },
  {
    key: "Page 6.2",
    route: "/profile/nationalities",
    page: "Member Portal | Nationality Page | Access",
    component: "profile",
    siteNav: "",
    RouteType: "PublicRoute",
    permissionsets: [],
  },
  {
    key: "Page 6.3",
    route: "/profile/workaddress",
    page: "Member Portal | Work Address Page | Access",
    component: "profile",
    siteNav: "",
    RouteType: "PublicRoute",
    permissionsets: [],
  },
  {
    key: "Page 6.4",
    route: "/profile/personaladdress",
    page: "Member Portal | Personal Address Page | Access",
    component: "profile",
    siteNav: "",
    RouteType: "PublicRoute",
    permissionsets: [],
  },
  {
    key: "Page 6.5",
    route: "/profile/postaladdress",
    page: "Member Portal | Postal Address Page | Access",
    component: "profile",
    siteNav: "",
    RouteType: "PublicRoute",
    permissionsets: [],
  },
  {
    key: "Page 6.6",
    route: "/preferences",
    page: "Member Portal | Preferences Page | Access",
    component: "profile",
    siteNav: "",
    RouteType: "PublicRoute",
    permissionsets: [],
  },
  {
    key: "Page 6.7",
    route: "/profile/upload-cv",
    page: "Member Portal | Upload CV Page | Access",
    component: "UploadCV",
    siteNav: "",
    RouteType: "PublicRoute",
    permissionsets: [],
  },
  {
    key: "Page 6.8",
    route: "/profile/update-email",
    page: "Member Portal | Update Email Page | Access",
    component: "EmailUpdate",
    siteNav: "",
    RouteType: "PublicRoute",
    permissionsets: [],
  },
  {
    key: "Page 6.9",
    route: "/profile/qualifications",
    page: "Member Portal | Qualification Page | Access",
    component: "Qualifications",
    siteNav: "",
    RouteType: "PrivateRoute",
    permissionsets: [],
  },
  {
    key: "Page 6.10",
    route: "/profile/viewqualificationdetails",
    page: "Member Portal | Qualification Details Page | Access",
    component: "ViewQualificationDetails",
    siteNav: "",
    RouteType: "PrivateRoute",
    permissionsets: [],
  },
  {
    key: "Page 6.11",
    route: "/profile/employmenthistory",
    page: "Member Portal | Employment History Page | Access",
    component: "EmploymentHistory",
    siteNav: "",
    RouteType: "PrivateRoute",
    permissionsets: [],
  },
  {
    key: "Page 6.12",
    route: "/profile/viewemploymenthistorydetails",
    page: "Member Portal | Employment History Detail Page | Access",
    component: "ViewEmploymentHistoryDetails",
    siteNav: "",
    RouteType: "PrivateRoute",
    permissionsets: [],
  },
  {
    key: "Page 6.13",
    route: "/profile/professionalhistory",
    page: "Member Portal | Professional History Page | Access",
    component: "ProfessionalHistory",
    siteNav: "",
    RouteType: "PrivateRoute",
    permissionsets: [],
  },
  {
    key: "Page 6.14",
    route: "/profile/viewprofessionalhistorydetails",
    page: "Member Portal | Professional History Detail Page | Access",
    component: "ViewProfessionalHistoryDetails",
    siteNav: "",
    RouteType: "PrivateRoute",
    permissionsets: [],
  },
  {
    key: "Page 7.1",
    route: "/event/registration",
    page: "Member Portal | Event Registration Page | Access",
    component: "Events",
    siteNav: "",
    RouteType: "PrivateRoute",
    permissionsets: [],
  },
  {
    key: "Page 7.1.1",
    route: "/event/details",
    page: "Member Portal | Event Details Page | Access",
    component: "EventDetails",
    siteNav: "",
    RouteType: "PrivateRoute",
    permissionsets: [],
  },
  {
    key: "Page 8.1",
    route: "/cpd/declaration",
    page: "Member Portal | CPD Declaration Page | Access",
    component: "MemberDeclarations",
    siteNav: "",
    RouteType: "PrivateRoute",
    permissionsets: [],
  },
  {
    key: "Page 8.1.1",
    route: "/exam/evidence",
    page: "Member Portal | CPD Uploads Page | Access",
    component: "EvidenceDetails",
    siteNav: "",
    RouteType: "PrivateRoute",
    permissionsets: [],
  },
  {
    key: "Page 8.1.2",
    route: "/viewEvidence",
    page: "Member Portal | CPD View Evidence Page | Access",
    component: "ViewEvidence",
    siteNav: "",
    RouteType: "PrivateRoute",
    permissionsets: [],
  },
  {
    key: "Page 8.2",
    route: "/membership/student-training-agreement",
    page: "Member Portal | CPD Records Page | Access",
    component: "ContractualRecords",
    siteNav: "",
    RouteType: "PrivateRoute",
    permissionsets: [],
  },
  {
    key: "Page 8.2.1",
    route: "/cpd/viewrecord",
    page: "Member Portal | CPD Record Details Page | Access",
    component: "ViewRecord",
    siteNav: "",
    RouteType: "PrivateRoute",
    permissionsets: [],
  },
  {
    key: "Page 8.3",
    route: "/add-evidence",
    page: "Member Portal | CPD Add Evidence Page | Access",
    component: "AddEvidence",
    siteNav: "",
    RouteType: "PrivateRoute",
    permissionsets: [],
  },
  {
    key: "Page 9.0",
    route: "/application/prepayment",
    page: "Member Portal | Pre Payment Page | Access",
    component: "Prepayment",
    siteNav: "",
    RouteType: "PrivateRoute",
    permissionsets: [],
  },
  {
    key: "Page 9.1",
    route: "/application/prepayment/payment-success",
    page: "Member Portal | Pre Payment Success Page | Access",
    component: "PaymentSuccessStatic",
    siteNav: "",
    RouteType: "PrivateRoute",
    permissionsets: [],
  },
  {
    key: "Page 9.4",
    route: "/student-placement",
    page: "Member Portal | Student Placement Page | Access",
    component: "StudentPlacement",
    siteNav: "",
    RouteType: "PrivateRoute",
    permissionsets: [],
  },
  {
    key: "Page 9.5",
    route: "/placement-details",
    page: "Member Portal | Student Placement Details Page | Access",
    component: "PlacementDetails",
    siteNav: "",
    RouteType: "PrivateRoute",
    permissionsets: [],
  },
  {
    key: "Page 9.6",
    route: "/application/prepayment/sucess",
    page: "Member Portal | Payment Success Page  | Access",
    component: "Paymentsucess",
    siteNav: "",
    RouteType: "PrivateRoute",
    permissionsets: [],
  },
  {
    key: "Page 9.7",
    route: "/application/success",
    page: "Member Portal | Payment Success Page 2 | Access",
    component: "PaymentSuccessStatic",
    siteNav: "",
    RouteType: "PrivateRoute",
    permissionsets: [],
  },
  {
    key: "Page 9.8",
    route: "/application/prepayment/failure",
    page: "Member Portal | Payment Failure Page | Access",
    component: "Paymentfailure",
    siteNav: "",
    RouteType: "PrivateRoute",
    permissionsets: [],
  },
  {
    key: "Page 9.9",
    route: "/application/prepayment/pay-later",
    page: "Member Portal | Payment Later Page | Access",
    component: "PayLater",
    siteNav: "",
    RouteType: "PrivateRoute",
    permissionsets: [],
  },
];

export const entitlementList = {
  assets: [
    {
      id: "a9305dcc-5427-ec11-b6e5-000d3ab6301d",
      key: "Page 1.1",
      name: "Member Portal | Upgrade Membership Page",
    },
    {
      id: "ab305dcc-5427-ec11-b6e5-000d3ab6301d",
      key: "Page 1.2",
      name: "Member Portal | Request Certs Page",
    },
    {
      id: "ad305dcc-5427-ec11-b6e5-000d3ab6301d",
      key: "Page 1.2.1",
      name: "Member Portal | Raise Request Page",
    },
    {
      id: "af305dcc-5427-ec11-b6e5-000d3ab6301d",
      key: "Page 1.3",
      name: "Member Portal | Resignation Page",
    },
    {
      id: "b1305dcc-5427-ec11-b6e5-000d3ab6301d",
      key: "Page 6.0",
      name: "Member Portal | Profile Page",
    },
    {
      id: "b3305dcc-5427-ec11-b6e5-000d3ab6301d",
      key: "Page 6,1",
      name: "Member Portal | Personal Details Page",
    },
    {
      id: "b5305dcc-5427-ec11-b6e5-000d3ab6301d",
      key: "Page 6.2",
      name: "Member Portal | Contact Prefs Page",
    },
    {
      id: "b7305dcc-5427-ec11-b6e5-000d3ab6301d",
      key: "Page 6.3",
      name: "Member Portal | Change Password Page",
    },
    {
      id: "b9305dcc-5427-ec11-b6e5-000d3ab6301d",
      key: "Page 5.0",
      name: "Member Portal | Tutor Submission Page",
    },
    {
      id: "f80b5536-5627-ec11-b6e5-000d3ab634d1",
      key: "Page 6.4",
      name: "Member Portal | KYC Declaration Page",
    },
    {
      id: "fa0b5536-5627-ec11-b6e5-000d3ab634d1",
      key: "Page 5.1",
      name: "Member Portal | Submit Results Page",
    },
    {
      id: "fc0b5536-5627-ec11-b6e5-000d3ab634d1",
      key: "Page 8.0",
      name: "Member Portal | Notifications Page",
    },
    {
      id: "fe0b5536-5627-ec11-b6e5-000d3ab634d1",
      key: "Page 3.0",
      name: "Member Portal | Training & Development Page",
    },
    {
      id: "000c5536-5627-ec11-b6e5-000d3ab634d1",
      key: "Page 3.1",
      name: "Member Portal | Events Page",
    },
    {
      id: "020c5536-5627-ec11-b6e5-000d3ab634d1",
      key: "Page 3.2",
      name: "Member Portal | BICA Qualification Page",
    },
    {
      id: "040c5536-5627-ec11-b6e5-000d3ab634d1",
      key: "Page 3.2.1",
      name: "Member Portal | Exam Details Page",
    },
    {
      id: "060c5536-5627-ec11-b6e5-000d3ab634d1",
      key: "Page 3.3",
      name: "Member Portal | Progress & Certs Page",
    },
    {
      id: "080c5536-5627-ec11-b6e5-000d3ab634d1",
      key: "Page 3.4",
      name: "Member Portal | CPD Declaration Page",
    },
    {
      id: "0a0c5536-5627-ec11-b6e5-000d3ab634d1",
      key: "Page 3.4.2",
      name: "Member Portal | Evidence Page",
    },
    {
      id: "9800bcac-6910-ec11-b6e6-000d3ade86ac",
      key: "Page 1.0",
      name: "Member Portal | Membership Page",
    },
    {
      id: "158f9d40-be67-ec11-8943-00224842bff9",
      key: "Page 2.0",
      name: "Member Portal | Training Employer Application Page",
    },
    {
      id: "6fe61f6b-be67-ec11-8943-00224842bff9",
      key: "Page 2.1",
      name: "Member Portal | Invoices & Payments Page",
    },
    {
      id: "48d5248a-be67-ec11-8943-00224842bff9",
      key: "Page 2.2",
      name: "Member Portal |All Credits Page",
    },
    {
      id: "345f1397-be67-ec11-8943-00224842bff9",
      key: "Page 2.3",
      name: "Member Portal |Account Statement",
    },
    {
      id: "92a978b4-be67-ec11-8943-00224842bff9",
      key: "Page 2.4",
      name: "Member Portal | Submit Attendance Page",
    },
    {
      id: "2825f3c5-be67-ec11-8943-00224842bff9",
      key: "Page 2.5",
      name: "Member Portal | Submit Schedules Page",
    },
    {
      id: "15584063-bf67-ec11-8943-00224842bff9",
      key: "Page 2.6",
      name: "Member Portal | Applications Page",
    },
    {
      id: "ec4c1c71-bf67-ec11-8943-00224842bff9",
      key: "Page 2.7",
      name: "Member Portal | Individual Membership Application Page",
    },
    {
      id: "1232eb90-bf67-ec11-8943-00224842bff9",
      key: "Page 2.8",
      name: "Member Portal | Firm Membership Application Page",
    },
    {
      id: "0653eaa7-bf67-ec11-8943-00224842bff9",
      key: "Page 2.9",
      name: "Member Portal | Tuition Provider Application Page",
    },
    {
      id: "91e361fa-c867-ec11-8943-00224842bff9",
      key: "Page 4.0",
      name: "Member Portal | Home Page",
    },
  ],
  entitlements: [
    {
      assetid: "a9305dcc-5427-ec11-b6e5-000d3ab6301d",
      documenturl: null,
      entityname: null,
      groupid: null,
      id: "14440a9e-5727-ec11-b6e5-000d3ab6301d",
      key: "Page 1.1",
      name: "Member Portal | Upgrade Membership Page",
      productid: null,
      type: "Asset",
    },
    {
      assetid: "ab305dcc-5427-ec11-b6e5-000d3ab6301d",
      documenturl: null,
      entityname: null,
      groupid: null,
      id: "16440a9e-5727-ec11-b6e5-000d3ab6301d",
      key: "Page 1.2",
      name: "Member Portal | Request Certs Page",
      productid: null,
      type: "Asset",
    },
    {
      assetid: "ad305dcc-5427-ec11-b6e5-000d3ab6301d",
      documenturl: null,
      entityname: null,
      groupid: null,
      id: "18440a9e-5727-ec11-b6e5-000d3ab6301d",
      key: "Page 1.2.1",
      name: "Member Portal | Raise Request Page",
      productid: null,
      type: "Asset",
    },
    {
      assetid: "af305dcc-5427-ec11-b6e5-000d3ab6301d",
      documenturl: null,
      entityname: null,
      groupid: null,
      id: "1a440a9e-5727-ec11-b6e5-000d3ab6301d",
      key: "Page 1.3",
      name: "Member Portal | Resignation Page",
      productid: null,
      type: "Asset",
    },
    {
      assetid: "fe0b5536-5627-ec11-b6e5-000d3ab634d1",
      documenturl: null,
      entityname: null,
      groupid: null,
      id: "1c440a9e-5727-ec11-b6e5-000d3ab6301d",
      key: "Page 3.0",
      name: "Member Portal | Training & Development Page",
      productid: null,
      type: "Asset",
    },
    {
      assetid: "000c5536-5627-ec11-b6e5-000d3ab634d1",
      documenturl: null,
      entityname: null,
      groupid: null,
      id: "1e440a9e-5727-ec11-b6e5-000d3ab6301d",
      key: "Page 3.1",
      name: "Member Portal | Events Page",
      productid: null,
      type: "Asset",
    },
    {
      assetid: "020c5536-5627-ec11-b6e5-000d3ab634d1",
      documenturl: null,
      entityname: null,
      groupid: null,
      id: "20440a9e-5727-ec11-b6e5-000d3ab6301d",
      key: "Page 3.2",
      name: "Member Portal | BICA Qualification Page",
      productid: null,
      type: "Asset",
    },
    {
      assetid: "040c5536-5627-ec11-b6e5-000d3ab634d1",
      documenturl: null,
      entityname: null,
      groupid: null,
      id: "22440a9e-5727-ec11-b6e5-000d3ab6301d",
      key: "Page 3.2.1",
      name: "Member Portal | Exam Details Page",
      productid: null,
      type: "Asset",
    },
    {
      assetid: "060c5536-5627-ec11-b6e5-000d3ab634d1",
      documenturl: null,
      entityname: null,
      groupid: null,
      id: "24440a9e-5727-ec11-b6e5-000d3ab6301d",
      key: "Page 3.3",
      name: "Member Portal | Progress & Certs Page",
      productid: null,
      type: "Asset",
    },
    {
      assetid: "080c5536-5627-ec11-b6e5-000d3ab634d1",
      documenturl: null,
      entityname: null,
      groupid: null,
      id: "26440a9e-5727-ec11-b6e5-000d3ab6301d",
      key: "Page 3.4",
      name: "Member Portal | CPD Declaration Page",
      productid: null,
      type: "Asset",
    },
    {
      assetid: "0a0c5536-5627-ec11-b6e5-000d3ab634d1",
      documenturl: null,
      entityname: null,
      groupid: null,
      id: "28440a9e-5727-ec11-b6e5-000d3ab6301d",
      key: "Page 3.4.2",
      name: "Member Portal | Evidence Page",
      productid: null,
      type: "Asset",
    },
    {
      assetid: "b9305dcc-5427-ec11-b6e5-000d3ab6301d",
      documenturl: null,
      entityname: null,
      groupid: null,
      id: "2a440a9e-5727-ec11-b6e5-000d3ab6301d",
      key: "Page 5.0",
      name: "Member Portal | Tutor Submission Page",
      productid: null,
      type: "Asset",
    },
    {
      assetid: "fa0b5536-5627-ec11-b6e5-000d3ab634d1",
      documenturl: null,
      entityname: null,
      groupid: null,
      id: "2c440a9e-5727-ec11-b6e5-000d3ab6301d",
      key: "Page 5.1",
      name: "Member Portal | Submit Results Page",
      productid: null,
      type: "Asset",
    },
    {
      assetid: "b3305dcc-5427-ec11-b6e5-000d3ab6301d",
      documenturl: null,
      entityname: null,
      groupid: null,
      id: "2e440a9e-5727-ec11-b6e5-000d3ab6301d",
      key: "Page 6,1",
      name: "Member Portal | Personal Details Page",
      productid: null,
      type: "Asset",
    },
    {
      assetid: "b1305dcc-5427-ec11-b6e5-000d3ab6301d",
      documenturl: null,
      entityname: null,
      groupid: null,
      id: "30440a9e-5727-ec11-b6e5-000d3ab6301d",
      key: "Page 6.0",
      name: "Member Portal | Profile Page",
      productid: null,
      type: "Asset",
    },
    {
      assetid: "b5305dcc-5427-ec11-b6e5-000d3ab6301d",
      documenturl: null,
      entityname: null,
      groupid: null,
      id: "32440a9e-5727-ec11-b6e5-000d3ab6301d",
      key: "Page 6.2",
      name: "Member Portal | Contact Prefs Page",
      productid: null,
      type: "Asset",
    },
    {
      assetid: "b7305dcc-5427-ec11-b6e5-000d3ab6301d",
      documenturl: null,
      entityname: null,
      groupid: null,
      id: "34440a9e-5727-ec11-b6e5-000d3ab6301d",
      key: "Page 6.3",
      name: "Member Portal | Change Password Page",
      productid: null,
      type: "Asset",
    },
    {
      assetid: "f80b5536-5627-ec11-b6e5-000d3ab634d1",
      documenturl: null,
      entityname: null,
      groupid: null,
      id: "36440a9e-5727-ec11-b6e5-000d3ab6301d",
      key: "Page 6.4",
      name: "Member Portal | KYC Declaration Page",
      productid: null,
      type: "Asset",
    },
    {
      assetid: "fc0b5536-5627-ec11-b6e5-000d3ab634d1",
      documenturl: null,
      entityname: null,
      groupid: null,
      id: "38440a9e-5727-ec11-b6e5-000d3ab6301d",
      key: "Page 8.0",
      name: "Member Portal | Notifications Page",
      productid: null,
      type: "Asset",
    },
    {
      assetid: null,
      documenturl: "https://azureblob2891/newletter_oct_2021.pdf",
      entityname: null,
      groupid: null,
      id: "83e50dd6-ff2b-ec11-b6e5-000d3ab9a28b",
      key: "DOC-001",
      name: "Document | Newsletter October 2021",
      productid: null,
      type: "Document",
    },
    {
      assetid: "9800bcac-6910-ec11-b6e6-000d3ade86ac",
      documenturl: null,
      entityname: null,
      groupid: null,
      id: "b963be07-5327-ec11-b6e5-000d3add67d3",
      key: "Page 1.0",
      name: "Member Portal | Membership Page",
      productid: null,
      type: "Asset",
    },
    {
      assetid: null,
      documenturl: null,
      entityname: "contact",
      groupid: null,
      id: "312ff48d-7327-ec11-b6e5-000d3add67d3",
      key: "E-01",
      name: "Entity | Contact",
      productid: null,
      type: "Entity",
    },
    {
      assetid: null,
      documenturl: null,
      entityname: "ctpt_identity",
      groupid: null,
      id: "ebb04b65-226e-ec11-8943-00224842b4ae",
      key: "E-02",
      name: "Entity | Identity",
      productid: null,
      type: "Entity",
    },
    {
      assetid: null,
      documenturl: null,
      entityname: "ctpt_nationality",
      groupid: null,
      id: "cf1cc6ab-226e-ec11-8943-00224842b4ae",
      key: "E-03",
      name: "Entity | Nationality",
      productid: null,
      type: "Entity",
    },
    {
      assetid: "91e361fa-c867-ec11-8943-00224842bff9",
      documenturl: null,
      entityname: null,
      groupid: null,
      id: "bdbca420-c967-ec11-8943-00224842bff9",
      key: "Page 4.0",
      name: "Member Portal | Home Page",
      productid: null,
      type: "Asset",
    },
    {
      assetid: "6fe61f6b-be67-ec11-8943-00224842bff9",
      documenturl: null,
      entityname: null,
      groupid: null,
      id: "6fb89f56-c967-ec11-8943-00224842bff9",
      key: "Page 2.1",
      name: "Member Portal | Invoices & Payments Page",
      productid: null,
      type: "Asset",
    },
    {
      assetid: "48d5248a-be67-ec11-8943-00224842bff9",
      documenturl: null,
      entityname: null,
      groupid: null,
      id: "2dfd0079-cc67-ec11-8943-00224842bff9",
      key: "Page 2.2",
      name: "Member Portal |All Credits Page",
      productid: null,
      type: "Asset",
    },
    {
      assetid: "345f1397-be67-ec11-8943-00224842bff9",
      documenturl: null,
      entityname: null,
      groupid: null,
      id: "638d3aa0-cc67-ec11-8943-00224842bff9",
      key: "Page 2.3",
      name: "Member Portal |Account Statement",
      productid: null,
      type: "Asset",
    },
    {
      assetid: "92a978b4-be67-ec11-8943-00224842bff9",
      documenturl: null,
      entityname: null,
      groupid: null,
      id: "1ab765b9-cc67-ec11-8943-00224842bff9",
      key: "Page 2.4",
      name: "Member Portal | Submit Attendance Page",
      productid: null,
      type: "Asset",
    },
    {
      assetid: "2825f3c5-be67-ec11-8943-00224842bff9",
      documenturl: null,
      entityname: null,
      groupid: null,
      id: "2f7f27db-cc67-ec11-8943-00224842bff9",
      key: "Page 2.5",
      name: "Member Portal | Submit Schedules Page",
      productid: null,
      type: "Asset",
    },
    {
      assetid: "15584063-bf67-ec11-8943-00224842bff9",
      documenturl: null,
      entityname: null,
      groupid: null,
      id: "192a92b2-d267-ec11-8943-00224842bff9",
      key: "Page 2.6",
      name: "Member Portal | Applications Page",
      productid: null,
      type: "Asset",
    },
    {
      assetid: "ec4c1c71-bf67-ec11-8943-00224842bff9",
      documenturl: null,
      entityname: null,
      groupid: null,
      id: "c9e486d8-d267-ec11-8943-00224842bff9",
      key: "Page 2.7",
      name: "Member Portal | Individual Membership Application Page",
      productid: null,
      type: "Asset",
    },
    {
      assetid: "1232eb90-bf67-ec11-8943-00224842bff9",
      documenturl: null,
      entityname: null,
      groupid: null,
      id: "7e196ef7-d267-ec11-8943-00224842bff9",
      key: "Page 2.8",
      name: "Member Portal | Firm Membership Application Page",
      productid: null,
      type: "Asset",
    },
    {
      assetid: "0653eaa7-bf67-ec11-8943-00224842bff9",
      documenturl: null,
      entityname: null,
      groupid: null,
      id: "0f6c6410-d367-ec11-8943-00224842bff9",
      key: "Page 2.9",
      name: "Member Portal | Tuition Provider Application Page",
      productid: null,
      type: "Asset",
    },
    {
      assetid: "158f9d40-be67-ec11-8943-00224842bff9",
      documenturl: null,
      entityname: null,
      groupid: null,
      id: "3d770f2b-d367-ec11-8943-00224842bff9",
      key: "Page 2.0",
      name: "Member Portal | Training Employer Application Page",
      productid: null,
      type: "Asset",
    },
  ],
  groups: null,
  products: null,
  roles: [
    {
      id: "8dff6f5a-7110-ec11-b6e6-000d3ade86ac",
      name: "Sitemap | Member",
      permissionsets: [
        {
          assetkey: "Page 6.0",
          cancreate: "0",
          candelete: "0",
          canread: "1",
          canwrite: "0",
          entitledlimited: "",
          entitlementid: "b963be07-5327-ec11-b6e5-000d3add67d3",
          entitlementlimited: "0",
          id: "47e875d0-5827-ec11-b6e5-000d3ab6373f",
          name: "Member Portal | Profile Page | Access",
          scope: null,
        },
        {
          assetkey: "Page 4.0",
          cancreate: "0",
          candelete: "0",
          canread: "1",
          canwrite: "0",
          entitledlimited: "",
          entitlementid: "b963be07-5327-ec11-b6e5-000d3add67d3",
          entitlementlimited: "0",
          id: "47e875d0-5827-ec11-b6e5-000d3ab6373f",
          name: "Member Portal | Home Page | Access",
          scope: null,
        },
        {
          assetkey: "Page 2.0",
          cancreate: "0",
          candelete: "0",
          canread: "1",
          canwrite: "0",
          entitledlimited: "",
          entitlementid: "b963be07-5327-ec11-b6e5-000d3add67d3",
          entitlementlimited: "0",
          id: "47e875d0-5827-ec11-b6e5-000d3ab6373f",
          name: "Member Portal | Individual Membership | Access",
          scope: null,
        },
        {
          assetkey: "Page 8.0",
          cancreate: "0",
          candelete: "0",
          canread: "1",
          canwrite: "1",
          entitledlimited: "",
          entitlementid: "b963be07-5327-ec11-b6e5-000d3add67d3",
          entitlementlimited: "0",
          id: "47e875d0-5827-ec11-b6e5-000d3ab6373f",
          name: "Member Portal | Invoices | Access",
          scope: null,
        },
        {
          assetkey: "",
          cancreate: "0",
          candelete: "0",
          canread: "1",
          canwrite: "0",
          entitledlimited: "",
          entitlementid: "b963be07-5327-ec11-b6e5-000d3add67d3",
          entitlementlimited: "0",
          id: "47e875d0-5827-ec11-b6e5-000d3ab6373f",
          name: "Member Portal | Membership Page | Access",
          scope: null,
        },
        {
          assetkey: "",
          cancreate: "0",
          candelete: "0",
          canread: "1",
          canwrite: "0",
          entitledlimited: "",
          entitlementid: "16440a9e-5727-ec11-b6e5-000d3ab6301d",
          entitlementlimited: "0",
          id: "4be875d0-5827-ec11-b6e5-000d3ab6373f",
          name: "Member Portal | Request Certs Page | Access",
          scope: null,
        },
        {
          assetkey: "",
          cancreate: "0",
          candelete: "0",
          canread: "1",
          canwrite: "0",
          entitledlimited: "",
          entitlementid: "1a440a9e-5727-ec11-b6e5-000d3ab6301d",
          entitlementlimited: "0",
          id: "4fe875d0-5827-ec11-b6e5-000d3ab6373f",
          name: "Member Portal | Resignation Page | Access",
          scope: null,
        },
        {
          assetkey: "",
          cancreate: "0",
          candelete: "0",
          canread: "1",
          canwrite: "0",
          entitledlimited: "",
          entitlementid: "1c440a9e-5727-ec11-b6e5-000d3ab6301d",
          entitlementlimited: "0",
          id: "51e875d0-5827-ec11-b6e5-000d3ab6373f",
          name: "Member Portal | Training & Development Page | Access",
          scope: null,
        },
        {
          assetkey: "",
          cancreate: "0",
          candelete: "0",
          canread: "1",
          canwrite: "1",
          entitledlimited: "",
          entitlementid: "1e440a9e-5727-ec11-b6e5-000d3ab6301d",
          entitlementlimited: "0",
          id: "53e875d0-5827-ec11-b6e5-000d3ab6373f",
          name: "Member Portal | Events Page | Access",
          scope: null,
        },
        {
          assetkey: "",
          cancreate: "0",
          candelete: "0",
          canread: "1",
          canwrite: "0",
          entitledlimited: "",
          entitlementid: "20440a9e-5727-ec11-b6e5-000d3ab6301d",
          entitlementlimited: "0",
          id: "55e875d0-5827-ec11-b6e5-000d3ab6373f",
          name: "Member Portal | BICA Qualification Page | Access",
          scope: null,
        },
        {
          assetkey: "",
          cancreate: "0",
          candelete: "0",
          canread: "1",
          canwrite: "1",
          entitledlimited: "",
          entitlementid: "22440a9e-5727-ec11-b6e5-000d3ab6301d",
          entitlementlimited: "0",
          id: "57e875d0-5827-ec11-b6e5-000d3ab6373f",
          name: "Member Portal | Exam Details Page | Access",
          scope: null,
        },
        {
          assetkey: "",
          cancreate: "0",
          candelete: "0",
          canread: "1",
          canwrite: "0",
          entitledlimited: "",
          entitlementid: "24440a9e-5727-ec11-b6e5-000d3ab6301d",
          entitlementlimited: "0",
          id: "59e875d0-5827-ec11-b6e5-000d3ab6373f",
          name: "Member Portal | Progress & Certs Page | Access",
          scope: null,
        },
        {
          assetkey: "",
          cancreate: "0",
          candelete: "0",
          canread: "1",
          canwrite: "0",
          entitledlimited: "",
          entitlementid: "26440a9e-5727-ec11-b6e5-000d3ab6301d",
          entitlementlimited: "0",
          id: "5be875d0-5827-ec11-b6e5-000d3ab6373f",
          name: "Member Portal | CPD Declaration Page | Access",
          scope: null,
        },
        {
          assetkey: "",
          cancreate: "0",
          candelete: "0",
          canread: "1",
          canwrite: "0",
          entitledlimited: "",
          entitlementid: "28440a9e-5727-ec11-b6e5-000d3ab6301d",
          entitlementlimited: "0",
          id: "5de875d0-5827-ec11-b6e5-000d3ab6373f",
          name: "Member Portal | Evidence Page | Access",
          scope: null,
        },
        {
          assetkey: "",
          cancreate: "0",
          candelete: "0",
          canread: "1",
          canwrite: "0",
          entitledlimited: "",
          entitlementid: "2e440a9e-5727-ec11-b6e5-000d3ab6301d",
          entitlementlimited: "0",
          id: "63e875d0-5827-ec11-b6e5-000d3ab6373f",
          name: "Member Portal | Personal Details Page | Access",
          scope: null,
        },
        {
          assetkey: "",
          cancreate: "0",
          candelete: "0",
          canread: "1",
          canwrite: "0",
          entitledlimited: "",
          entitlementid: "32440a9e-5727-ec11-b6e5-000d3ab6301d",
          entitlementlimited: "0",
          id: "67e875d0-5827-ec11-b6e5-000d3ab6373f",
          name: "Member Portal | Contact Prefs Page | Access",
          scope: null,
        },
        {
          assetkey: "",
          cancreate: "0",
          candelete: "0",
          canread: "1",
          canwrite: "0",
          entitledlimited: "",
          entitlementid: "34440a9e-5727-ec11-b6e5-000d3ab6301d",
          entitlementlimited: "0",
          id: "69e875d0-5827-ec11-b6e5-000d3ab6373f",
          name: "Member Portal | Change Password Page | Access",
          scope: null,
        },
        {
          assetkey: "",
          cancreate: "0",
          candelete: "0",
          canread: "1",
          canwrite: "0",
          entitledlimited: "",
          entitlementid: "36440a9e-5727-ec11-b6e5-000d3ab6301d",
          entitlementlimited: "0",
          id: "6be875d0-5827-ec11-b6e5-000d3ab6373f",
          name: "Member Portal | KYC Declaration Page | Access",
          scope: null,
        },
        {
          assetkey: "",
          cancreate: "0",
          candelete: "0",
          canread: "1",
          canwrite: "0",
          entitledlimited: "",
          entitlementid: "38440a9e-5727-ec11-b6e5-000d3ab6301d",
          entitlementlimited: "0",
          id: "6de875d0-5827-ec11-b6e5-000d3ab6373f",
          name: "Member Portal | Notifications Page | Access",
          scope: null,
        },
        {
          assetkey: "",
          cancreate: "1",
          candelete: "0",
          canread: "1",
          canwrite: "1",
          entitledlimited: "",
          entitlementid: "312ff48d-7327-ec11-b6e5-000d3add67d3",
          entitlementlimited: "0",
          id: "52325d57-7427-ec11-b6e5-000d3ab63f3a",
          name: "Contact | Create/Read/Write | Own Records",
          scope: "OwnRecords",
        },
        {
          assetkey: "",
          cancreate: "0",
          candelete: "0",
          canread: "1",
          canwrite: "1",
          entitledlimited: "",
          entitlementid: "ebb04b65-226e-ec11-8943-00224842b4ae",
          entitlementlimited: "0",
          id: "9dc9d7eb-216e-ec11-8943-00224842b4ae",
          name: "Identity | Create/Read/Write | Own Records",
          scope: "OwnRecords",
        },
        {
          assetkey: "",
          cancreate: "0",
          candelete: "0",
          canread: "1",
          canwrite: "1",
          entitledlimited: "",
          entitlementid: "cf1cc6ab-226e-ec11-8943-00224842b4ae",
          entitlementlimited: "0",
          id: "76fe648f-226e-ec11-8943-00224842b4ae",
          name: "Nationality | Create/Read/Write | Own Records",
          scope: "OwnRecords",
        },
        {
          assetkey: "",
          cancreate: "0",
          candelete: "0",
          canread: "1",
          canwrite: "0",
          entitledlimited: "",
          entitlementid: "bdbca420-c967-ec11-8943-00224842bff9",
          entitlementlimited: "0",
          id: "49967272-7668-ec11-8943-00224842bff9",
          name: "Member Portal | Home Page | Access",
          scope: null,
        },
        {
          assetkey: "",
          cancreate: "0",
          candelete: "0",
          canread: "1",
          canwrite: "1",
          entitledlimited: "",
          entitlementid: "6fb89f56-c967-ec11-8943-00224842bff9",
          entitlementlimited: "0",
          id: "c260f489-7968-ec11-8943-00224842bff9",
          name: "Member Portal | Invoices & Payments Page | Access",
          scope: null,
        },
        {
          assetkey: "",
          cancreate: "0",
          candelete: "0",
          canread: "1",
          canwrite: "0",
          entitledlimited: "",
          entitlementid: "2dfd0079-cc67-ec11-8943-00224842bff9",
          entitlementlimited: "0",
          id: "cb257e9a-7968-ec11-8943-00224842bff9",
          name: "Member Portal |All Credits Page | Access",
          scope: null,
        },
        {
          assetkey: "",
          cancreate: "0",
          candelete: "0",
          canread: "1",
          canwrite: "0",
          entitledlimited: "",
          entitlementid: "638d3aa0-cc67-ec11-8943-00224842bff9",
          entitlementlimited: "0",
          id: "bfe32edb-7968-ec11-8943-00224842bff9",
          name: "Member Portal |Account Statement | Access",
          scope: null,
        },
        {
          assetkey: "",
          cancreate: "0",
          candelete: "0",
          canread: "1",
          canwrite: "0",
          entitledlimited: "",
          entitlementid: "192a92b2-d267-ec11-8943-00224842bff9",
          entitlementlimited: "0",
          id: "48c65823-7a68-ec11-8943-00224842bff9",
          name: "Member Portal | Applications Page | Access",
          scope: null,
        },
        {
          assetkey: "",
          cancreate: "0",
          candelete: "0",
          canread: "1",
          canwrite: "0",
          entitledlimited: "",
          entitlementid: "7e196ef7-d267-ec11-8943-00224842bff9",
          entitlementlimited: "0",
          id: "3c542b4b-7a68-ec11-8943-00224842bff9",
          name: "Member Portal | Firm Membership Application Page | Access",
          scope: null,
        },
        {
          assetkey: "",
          cancreate: "0",
          candelete: "0",
          canread: "1",
          canwrite: "0",
          entitledlimited: "",
          entitlementid: "0f6c6410-d367-ec11-8943-00224842bff9",
          entitlementlimited: "0",
          id: "f9250d58-7a68-ec11-8943-00224842bff9",
          name: "Member Portal | Tuition Provider Application Page | Access",
          scope: null,
        },
        {
          assetkey: "",
          cancreate: "0",
          candelete: "0",
          canread: "1",
          canwrite: "0",
          entitledlimited: "",
          entitlementid: "3d770f2b-d367-ec11-8943-00224842bff9",
          entitlementlimited: "0",
          id: "0cbe296b-7a68-ec11-8943-00224842bff9",
          name: "Member Portal | Training Employer Application Page | Access",
          scope: null,
        },
      ],
    },
    {
      id: "d5d72f68-9431-ec11-b6e6-000d3adabd31",
      name: "Sitemap | Individual",
      permissionsets: [
        {
          cancreate: "0",
          candelete: "0",
          canread: "1",
          canwrite: "0",
          entitledlimited: "",
          entitlementid: "30440a9e-5727-ec11-b6e5-000d3ab6301d",
          entitlementlimited: "0",
          key: "page1.0",
          id: "65e875d0-5827-ec11-b6e5-000d3ab6373f",
          name: "Member Portal | Profile Page | Access",
          scope: null,
        },
        {
          cancreate: "0",
          candelete: "0",
          canread: "1",
          canwrite: "0",
          entitledlimited: "",
          entitlementid: "34440a9e-5727-ec11-b6e5-000d3ab6301d",
          entitlementlimited: "0",
          id: "69e875d0-5827-ec11-b6e5-000d3ab6373f",
          name: "Member Portal | Change Password Page | Access",
          scope: null,
        },
        {
          cancreate: "0",
          candelete: "0",
          canread: "1",
          canwrite: "0",
          entitledlimited: "",
          entitlementid: "38440a9e-5727-ec11-b6e5-000d3ab6301d",
          entitlementlimited: "0",
          id: "6de875d0-5827-ec11-b6e5-000d3ab6373f",
          name: "Member Portal | Notifications Page | Access",
          scope: null,
        },
        {
          cancreate: "0",
          candelete: "0",
          canread: "1",
          canwrite: "0",
          entitledlimited: "",
          entitlementid: "bdbca420-c967-ec11-8943-00224842bff9",
          entitlementlimited: "0",
          id: "49967272-7668-ec11-8943-00224842bff9",
          name: "Member Portal | Home Page | Access",
          scope: null,
        },
        {
          cancreate: "0",
          candelete: "0",
          canread: "1",
          canwrite: "0",
          entitledlimited: "",
          entitlementid: "192a92b2-d267-ec11-8943-00224842bff9",
          entitlementlimited: "0",
          id: "48c65823-7a68-ec11-8943-00224842bff9",
          name: "Member Portal | Applications Page | Access",
          scope: null,
        },
        {
          cancreate: "1",
          candelete: "1",
          canread: "1",
          canwrite: "1",
          entitledlimited: "",
          entitlementid: "c9e486d8-d267-ec11-8943-00224842bff9",
          entitlementlimited: "0",
          id: "ac2ac335-7a68-ec11-8943-00224842bff9",
          name: "Member Portal | Individual Membership Application Page | Access",
          scope: null,
        },
        {
          cancreate: "0",
          candelete: "0",
          canread: "1",
          canwrite: "0",
          entitledlimited: "",
          entitlementid: "0f6c6410-d367-ec11-8943-00224842bff9",
          entitlementlimited: "0",
          id: "f9250d58-7a68-ec11-8943-00224842bff9",
          name: "Member Portal | Tuition Provider Application Page | Access",
          scope: null,
        },
        {
          cancreate: "0",
          candelete: "0",
          canread: "1",
          canwrite: "0",
          entitledlimited: "",
          entitlementid: "3d770f2b-d367-ec11-8943-00224842bff9",
          entitlementlimited: "0",
          id: "0cbe296b-7a68-ec11-8943-00224842bff9",
          name: "Member Portal | Training Employer Application Page | Access",
          scope: null,
        },
      ],
    },
    {
      id: "bce80bf9-4827-ec11-b6e5-000d3add67d3",
      name: "Sitemap | Tutor",
      permissionsets: null,
    },
    {
      id: "f7c9a8ff-4827-ec11-b6e5-000d3add67d3",
      name: "Sitemap | Student",
      permissionsets: [
        {
          cancreate: "0",
          candelete: "0",
          canread: "1",
          canwrite: "0",
          entitledlimited: "",
          entitlementid: "16440a9e-5727-ec11-b6e5-000d3ab6301d",
          entitlementlimited: "0",
          id: "4be875d0-5827-ec11-b6e5-000d3ab6373f",
          name: "Member Portal | Request Certs Page | Access",
          scope: null,
        },
        {
          cancreate: "0",
          candelete: "0",
          canread: "1",
          canwrite: "0",
          entitledlimited: "",
          entitlementid: "1c440a9e-5727-ec11-b6e5-000d3ab6301d",
          entitlementlimited: "0",
          id: "51e875d0-5827-ec11-b6e5-000d3ab6373f",
          name: "Member Portal | Training & Development Page | Access",
          scope: null,
        },
        {
          cancreate: "0",
          candelete: "0",
          canread: "1",
          canwrite: "1",
          entitledlimited: "",
          entitlementid: "1e440a9e-5727-ec11-b6e5-000d3ab6301d",
          entitlementlimited: "0",
          id: "53e875d0-5827-ec11-b6e5-000d3ab6373f",
          name: "Member Portal | Events Page | Access",
          scope: null,
        },
        {
          cancreate: "0",
          candelete: "0",
          canread: "1",
          canwrite: "0",
          entitledlimited: "",
          entitlementid: "20440a9e-5727-ec11-b6e5-000d3ab6301d",
          entitlementlimited: "0",
          id: "55e875d0-5827-ec11-b6e5-000d3ab6373f",
          name: "Member Portal | BICA Qualification Page | Access",
          scope: null,
        },
        {
          cancreate: "0",
          candelete: "0",
          canread: "1",
          canwrite: "1",
          entitledlimited: "",
          entitlementid: "22440a9e-5727-ec11-b6e5-000d3ab6301d",
          entitlementlimited: "0",
          id: "57e875d0-5827-ec11-b6e5-000d3ab6373f",
          name: "Member Portal | Exam Details Page | Access",
          scope: null,
        },
        {
          cancreate: "0",
          candelete: "0",
          canread: "1",
          canwrite: "0",
          entitledlimited: "",
          entitlementid: "24440a9e-5727-ec11-b6e5-000d3ab6301d",
          entitlementlimited: "0",
          id: "59e875d0-5827-ec11-b6e5-000d3ab6373f",
          name: "Member Portal | Progress & Certs Page | Access",
          scope: null,
        },
        {
          cancreate: "0",
          candelete: "0",
          canread: "1",
          canwrite: "0",
          entitledlimited: "",
          entitlementid: "2e440a9e-5727-ec11-b6e5-000d3ab6301d",
          entitlementlimited: "0",
          id: "63e875d0-5827-ec11-b6e5-000d3ab6373f",
          name: "Member Portal | Personal Details Page | Access",
          scope: null,
        },
        {
          cancreate: "0",
          candelete: "0",
          canread: "1",
          canwrite: "0",
          entitledlimited: "",
          entitlementid: "30440a9e-5727-ec11-b6e5-000d3ab6301d",
          entitlementlimited: "0",
          id: "65e875d0-5827-ec11-b6e5-000d3ab6373f",
          name: "Member Portal | Profile Page | Access",
          scope: null,
        },
        {
          cancreate: "0",
          candelete: "0",
          canread: "1",
          canwrite: "0",
          entitledlimited: "",
          entitlementid: "32440a9e-5727-ec11-b6e5-000d3ab6301d",
          entitlementlimited: "0",
          id: "67e875d0-5827-ec11-b6e5-000d3ab6373f",
          name: "Member Portal | Contact Prefs Page | Access",
          scope: null,
        },
        {
          cancreate: "0",
          candelete: "0",
          canread: "1",
          canwrite: "0",
          entitledlimited: "",
          entitlementid: "34440a9e-5727-ec11-b6e5-000d3ab6301d",
          entitlementlimited: "0",
          id: "69e875d0-5827-ec11-b6e5-000d3ab6373f",
          name: "Member Portal | Change Password Page | Access",
          scope: null,
        },
        {
          cancreate: "0",
          candelete: "0",
          canread: "1",
          canwrite: "0",
          entitledlimited: "",
          entitlementid: "38440a9e-5727-ec11-b6e5-000d3ab6301d",
          entitlementlimited: "0",
          id: "6de875d0-5827-ec11-b6e5-000d3ab6373f",
          name: "Member Portal | Notifications Page | Access",
          scope: null,
        },
        {
          cancreate: "1",
          candelete: "0",
          canread: "1",
          canwrite: "1",
          entitledlimited: "",
          entitlementid: "312ff48d-7327-ec11-b6e5-000d3add67d3",
          entitlementlimited: "0",
          id: "52325d57-7427-ec11-b6e5-000d3ab63f3a",
          name: "Contact | Create/Read/Write | Own Records",
          scope: "OwnRecords",
        },
        {
          cancreate: "0",
          candelete: "0",
          canread: "1",
          canwrite: "1",
          entitledlimited: "",
          entitlementid: "ebb04b65-226e-ec11-8943-00224842b4ae",
          entitlementlimited: "0",
          id: "9dc9d7eb-216e-ec11-8943-00224842b4ae",
          name: "Identity | Create/Read/Write | Own Records",
          scope: "OwnRecords",
        },
        {
          cancreate: "0",
          candelete: "0",
          canread: "1",
          canwrite: "1",
          entitledlimited: "",
          entitlementid: "cf1cc6ab-226e-ec11-8943-00224842b4ae",
          entitlementlimited: "0",
          id: "76fe648f-226e-ec11-8943-00224842b4ae",
          name: "Nationality | Create/Read/Write | Own Records",
          scope: "OwnRecords",
        },
        {
          cancreate: "0",
          candelete: "0",
          canread: "1",
          canwrite: "0",
          entitledlimited: "",
          entitlementid: "bdbca420-c967-ec11-8943-00224842bff9",
          entitlementlimited: "0",
          id: "49967272-7668-ec11-8943-00224842bff9",
          name: "Member Portal | Home Page | Access",
          scope: null,
        },
        {
          cancreate: "0",
          candelete: "0",
          canread: "1",
          canwrite: "1",
          entitledlimited: "",
          entitlementid: "6fb89f56-c967-ec11-8943-00224842bff9",
          entitlementlimited: "0",
          id: "c260f489-7968-ec11-8943-00224842bff9",
          name: "Member Portal | Invoices & Payments Page | Access",
          scope: null,
        },
        {
          cancreate: "0",
          candelete: "0",
          canread: "1",
          canwrite: "0",
          entitledlimited: "",
          entitlementid: "2dfd0079-cc67-ec11-8943-00224842bff9",
          entitlementlimited: "0",
          id: "cb257e9a-7968-ec11-8943-00224842bff9",
          name: "Member Portal |All Credits Page | Access",
          scope: null,
        },
        {
          cancreate: "0",
          candelete: "0",
          canread: "1",
          canwrite: "0",
          entitledlimited: "",
          entitlementid: "638d3aa0-cc67-ec11-8943-00224842bff9",
          entitlementlimited: "0",
          id: "bfe32edb-7968-ec11-8943-00224842bff9",
          name: "Member Portal |Account Statement | Access",
          scope: null,
        },
        {
          cancreate: "0",
          candelete: "0",
          canread: "1",
          canwrite: "0",
          entitledlimited: "",
          entitlementid: "192a92b2-d267-ec11-8943-00224842bff9",
          entitlementlimited: "0",
          id: "48c65823-7a68-ec11-8943-00224842bff9",
          name: "Member Portal | Applications Page | Access",
          scope: null,
        },
        {
          cancreate: "1",
          candelete: "1",
          canread: "1",
          canwrite: "1",
          entitledlimited: "",
          entitlementid: "c9e486d8-d267-ec11-8943-00224842bff9",
          entitlementlimited: "0",
          id: "ac2ac335-7a68-ec11-8943-00224842bff9",
          name: "Member Portal | Individual Membership Application Page | Access",
          scope: null,
        },
        {
          cancreate: "0",
          candelete: "0",
          canread: "1",
          canwrite: "0",
          entitledlimited: "",
          entitlementid: "7e196ef7-d267-ec11-8943-00224842bff9",
          entitlementlimited: "0",
          id: "3c542b4b-7a68-ec11-8943-00224842bff9",
          name: "Member Portal | Firm Membership Application Page | Access",
          scope: null,
        },
        {
          cancreate: "0",
          candelete: "0",
          canread: "1",
          canwrite: "0",
          entitledlimited: "",
          entitlementid: "0f6c6410-d367-ec11-8943-00224842bff9",
          entitlementlimited: "0",
          id: "f9250d58-7a68-ec11-8943-00224842bff9",
          name: "Member Portal | Tuition Provider Application Page | Access",
          scope: null,
        },
        {
          cancreate: "0",
          candelete: "0",
          canread: "1",
          canwrite: "0",
          entitledlimited: "",
          entitlementid: "3d770f2b-d367-ec11-8943-00224842bff9",
          entitlementlimited: "0",
          id: "0cbe296b-7a68-ec11-8943-00224842bff9",
          name: "Member Portal | Training Employer Application Page | Access",
          scope: null,
        },
      ],
    },
    {
      id: "902b9516-8627-ec11-b6e5-000d3add67d3",
      name: "Ad-Hoc Role | Delete Contacts",
      permissionsets: null,
    },
  ],
};

export const contactEntitlement = {
  contactid: "ac7d90c7-e720-ec11-b6e6-0022484303ea",
  roles: [
    {
      id: "8dff6f5a-7110-ec11-b6e6-000d3ade86ac",
      name: "Sitemap | Member",
    },
  ],
};
