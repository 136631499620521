export const getrecordPayload = {
  properties: [
    "ctpt_name",
    "_ctpt_student_value",
    "ctpt_startdate",
    "ctpt_duration",
    "ctpt_enddate",
    "ctpt_studenttrainingagreementid",
  ],
  formattedFields: ["_ctpt_employer_value"],
  recordType: "ctpt_studenttrainingagreements",
  filters: [
    {
      field: "ctpt_studenttrainingagreementid",
      searchvalue: "",
      type: "guid",
    },
  ],

  idfield: "ctpt_studenttrainingagreementid",
};

export const getSupportingDocPayload = (agreementID) => ({
  properties: [
    "ctpt_name",
    "ctpt_supportingdocumentid",
    "ctpt_documenturl",
    "ctpt_documenttype",
    "ctpt_documentdate",
    "ctpt_description",
    "ctpt_type",
  ],
  formattedFields: [],
  filter: {
    globalfilter: "",
    globalAndOperator: false,
    fieldAndOperator: true,
    filterfields: [
      {
        field: "_ctpt_studenttrainingagreementid_value",
        searchvalue: agreementID,
        type: "guid",
      },
    ],
  },
  Aggregate: {
    AggregateFields: [],
  },
  recordtype: "ctpt_supportingdocuments",
  idfield: "ctpt_supportingdocumentid",
  continuationToken: "",
  pazesize: "10",
});

export const getAnnotationPayload = {
  properties: [
    "annotationid"
  ],
  formattedFields: [],
  filter: {
    globalfilter: "",
    globalAndOperator: false,
    fieldAndOperator: true,
    filterfields: [
    ],
  },
  Aggregate: {
    AggregateFields: [],
  },
  recordtype: "annotations",
  idfield: "annotationid",
  continuationToken: "",
  pazesize: "10",
};
