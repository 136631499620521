export const TELEPHONE_TYPE_MAP = {
    1:{
        727530000:"Work",
        727530001:"Work 2",
        727530002:"Work 3",
        727530003:"Mobile"
    },
    2:{
        727530000:"Home",
        727530001:"Home 2",
        727530002:"Home 3",
        727530003:"Mobile"
    }
  };
  
  export const IDENTITY_TYPE_MAP = {
    100000000:"Omang Number",
        100000001:"Passport Number",
        100000002:"Work Permit",
        100000003:"Exemption Certificate"
  };