import React from "react";
import { makeStyles, createStyles } from "@material-ui/core/styles";

import List from "@material-ui/core/List";

import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Divider from "@material-ui/core/Divider";
import Collapse from "@material-ui/core/Collapse";

import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";

import AppMenuItemComponent from "./AppMenuItemComponent";

//going to (optionally) pass down the open/closed state as a prop, as well as the toggle functionality - to allow for the parent menu to handle state (allowing toggling a menuItem to close all other menus) [DJ]

//AppMenu has been relegated to just the mobile (drawer) UI
//main desktop Nav is now "SiteMenu"
const useStyles = makeStyles((theme) =>
  createStyles({
    nestedList: {
      paddingLeft: "10px",
    },
    menuItem: {
      "& div": { paddingLeft: "10px", "& span": { fontSize: "1rem" } },
      "&.active": {
        background: "rgba(0, 0, 0, 0.08)",
        "& .MuiListItemIcon-root": {
          color: "#fff",
        },
      },
    },
  })
);

const AppMenuItem = (props) => {
  const { name, link, Icon, items = [], params } = props;
  const classes = useStyles();
  const isExpandable = items && items.length > 0;

  function handleClick(e) {
    props.handleNavCollapse(e);
  }

  const MenuItemRoot = (
    <AppMenuItemComponent
      className={classes.menuItem}
      link={link}
      onClick={handleClick}
      params={params}
      id={props.listId}
    >
      {/* Display an icon if any */}
      {!!Icon && (
        <ListItemIcon className={classes.menuItemIcon}>
          <Icon />
        </ListItemIcon>
      )}
      <ListItemText primary={name} inset={!Icon} />
      {/* Display the expand menu if the item has children */}
      {isExpandable && !props.isOpen && <ExpandMore />}
      {isExpandable && props.isOpen && <ExpandLess />}
    </AppMenuItemComponent>
  );

  const MenuItemChildren = isExpandable ? (
    <Collapse in={props.isOpen} timeout="auto" unmountOnExit>
      <Divider />
      <List component="div" className={classes.nestedList}>
        {items.map((item, index) => (
          <AppMenuItem {...item} key={index} />
        ))}
      </List>
    </Collapse>
  ) : null;

  return (
    <>
      {MenuItemRoot}
      {MenuItemChildren}
    </>
  );
};

export default AppMenuItem;
