import React, { useEffect } from "react";
import Link from "@material-ui/core/Link";
import InputLabel from "@material-ui/core/InputLabel";
import SWFField from "../swfField/SWFField.mui";
import { post } from "app/services/api";

export default function CustomLinkText(props) {
  const { schema } = props.getProps;
  const [url, setUrl] = React.useState({
    link: "",
  });
  const [isDownload, setisDownload] = React.useState(false);

  const getDocument = () => {
    setisDownload(true);
    console.log("link text--" + props.getProps.value);
    if (props.getProps.value !== undefined) {
      let payloads = props.getProps.value;
      console.log("getdcuemnt" + payloads);
      post(schema.apiUrl, payloads)
        .then((res) => {
          setUrl({ link: res.data.uri });
        })
        .catch(() => {
          this.props.status({
            message: "Document fetch failed",
            code: "",
            display: "show",
            severity: "error",
          });
        });
    } else {
      console.log("undefined");
    }
  };

  useEffect(() => {
    if (schema.isDynamic !== undefined && schema.isDynamic) {
      getDocument();
    } else {
      console.log("link text--" + props.getProps.value);
      setUrl({ link: props.getProps.value });
    }
  }, []);
  useEffect(() => {
    setUrl({ link: schema.linkUrl });
  }, [url, props, isDownload]);

  return (
    <>
      {url.link !== "" ? (
        <SWFField>
          <InputLabel htmlFor={props.getProps.id}>
            {schema.title}
            {props.getProps.schema.requiredCheck && "*"}
          </InputLabel>
          <Link
            href={props.getProps.value}
            target="_blank"
            download={isDownload}
          >
            {schema.labelText}
          </Link>
        </SWFField>
      ) : (
        ""
      )}
    </>
  );
}
