import React from "react";
import { useSelector } from "react-redux";
import "./style.css";
import { useMediaQuery, useTheme } from "@material-ui/core";
import { brandPalette } from "brand/theme/brandTheme";



const PreLoader = ({ enableLoader }) => {
  const showLoader = useSelector((state) => state.showLoader);
  const loadingMessage = useSelector((state) => state.loadingMessage);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const isTablet = useMediaQuery(theme.breakpoints.down("md"));

  const getMessagePosition = () => {
    if (isMobile) {
      return "43%";
    } else if (isTablet) {
      return "45%";
    } else {
      return "48%";
    }
  };

  if (showLoader || enableLoader) {
    return (
      <div className="container">
        <p
          className="loadingMessage"
          style={{
            top: getMessagePosition(),
          }}
        >
          {loadingMessage || "Please wait ..."}
        </p>
      </div>
    );
  }
  return <div />;
};

export default PreLoader;
