import React from "react";
import { useStyles } from "../style";
import { config } from "utilities/config";
import { Button } from "@material-ui/core";
import { v4 as uuidv4 } from 'uuid';
import md5 from "md5"; 
function PayUsingDebitCard({ payDetails, payloads }) {
  const classes = useStyles();
  const Authtoken = localStorage.getItem("token");
  const uuid = uuidv4().substring(0, 25);
  const hash = md5(config.payment.terminalId + uuid + "description" + payDetails?.invoiceTotal?.invoideTotal + payloads?.orderId + payDetails?.currencyCode + payDetails?.invoiceNumber + config.payment.hashKey)
  
  return (
    <div className={classes.makePay}>
      <form
        className={classes.makePayment}
        action={config.payment.endPoint}
        method="post"
        id="paymentForm"
      >
        <input type="hidden" name="p1" value={config.payment.terminalId}/>
        <input type="hidden" name="p2" value={uuid}/>
        <input type="hidden" name="p3" value="description"/>
        <input type="hidden" name="p4" value={payDetails?.invoiceTotal?.invoideTotal}/>
        <input type="hidden" name="m_1" value=""/>
        <input type="hidden" name="m_2" value={payloads?.orderId}/>
        <input type="hidden" name="m_3" value={payDetails?.currencyCode}/>
        <input type="hidden" name="m_4" value={payDetails?.invoiceNumber || ""}/>
        <input type="hidden" name="Hash" value={hash}/>
        <input type="hidden" name="UrlsProvided" value="Y"/>
        <input type="hidden" name="ApprovedUrl" value={`${config.payment.paymentSuccessHandlerAsyncUrl}`}/>
        <input type="hidden" name="DeclinedUrl" value={`${config.payment.paymentFailureHandlerAsyncUrl}`}/>
        
        <Button
          disabled={!payDetails?.invoiceTotal}
          color="primary"
          variant="contained"
          type="submit"
        >
          Make Secure Payment Now
        </Button>
      </form>
      <p className={classes.noteTxt}>
        Payment goes to a secure external site, you’ll be sent back to this site
        after payment
      </p>
    </div>
  );
}

export default PayUsingDebitCard;
