import CustomTextBox from "app/components/atoms/customTextBox";

export const contactUiSchema = {
  "ui:title": "",
  ctpt_telephone: {
    "ui:widget": (props) => {
      return <CustomTextBox getProps={props} />;
    },
  },
};

export const identityUiSchema = {
  "ui:title": "",
  ctpt_type: {
    "ui:widget": (props) => {
      return <CustomTextBox getProps={props} />;
    },
  },
  ctpt_identitynumber: {
    "ui:widget": (props) => {
      return <CustomTextBox getProps={props} />;
    },
  },
};
