import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  root: {
    minHeight: "30rem !important",
    "& span": {
      fontSize: "1rem",
    },
    margin: "3rem 2rem",
    "& h5": {
      marginBottom: "2rem",
    },
  },
  margin: {
    margin: `.5rem 3rem`,
  },
  btnHeader: {
    minWidth: "7rem",
  },
  cta: {
    minWidth: "12rem",
    marginBottom: "5rem",
  },
}));
