import { makeStyles } from "@material-ui/core/styles";
import { brandPalette } from "brand/theme/brandTheme";

const useProfileStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    overflow: "visible",
    //backgroundColor: "red",
    margin: "2rem auto",
    marginTop: 0,
  },
  profileBox: {
    width: "40vh",
    margin: theme.spacing(10, "auto"),
    backgroundColor: theme.palette.lightBg.main,
    height: "40vh",
    [theme.breakpoints.down("xs")]: {
      height: "60vh",
    },
  },
  textBox: {
    margin: theme.spacing(10, "auto"),
  },
  textBoxSmall: {
    margin: theme.spacing(5, "auto"),
    [theme.breakpoints.down("xs")]: {
      textAlign: "center",
    },
  },
  large: {
    width: theme.spacing(20),
    height: theme.spacing(20),
    color: theme.palette.primary.main,
    justifyContent: "center",
    margin: theme.spacing(5, "auto"),
    backgroundColor: theme.palette.midlightBg.main,
    fontSize: "40px",
  },
  dividerBar: {
    margin: theme.spacing(5, 0),
    width: "30vw",
    [theme.breakpoints.down("xs")]: {
      width: "90vw",
      margin: theme.spacing(5, "auto"),
    },
  },
  editBtn: {
    marginLeft: "auto",
  },
  textBlock: {
    display: "inline-flex",
    VerticalAlign: "text-bottom",
    BoxSizing: "inherit",
    textAlign: "center",
    AlignItems: "center",
    marginTop: "30px",
  },
  iconImg: {
    marginLeft: "10px",
    cursor: "pointer",
  },
  tileCardContainer: {
    backgroundColor: theme.palette.midlight,
    display: "flex",
  },
  lastTileCard: {
    display: "flex",
    flex: 0,
  },
  link: {
    //borderBottom: "1px solid grey",
    cursor: "pointer",
    //padding: "3px 1.5rem",
    //backgroundColor: "grey",
    color: theme.palette.primary.main,
    //borderRadius: "15px",
    // transition: "all .2s",
    "&:hover, &:active, &:focus": {
      color: theme.palette.primary.main,
      //border: `2px solid ${theme.palette.secondary.main}`,
      //backgroundColor: theme.palette.secondary.main,
    },
  },
  root: {
    flexGrow: 1,
    "& > *": {
      margin: theme.spacing(1),
    },
    "& .statusTblheader": {
      backgroundColor: "rgba(255, 7, 0, 0.55)",
    },
  },
  snackbarSuccess: {
    backgroundColor: theme.palette.success.main,
  },
  statusTblheader: {
    backgroundColor: "rgba(255, 7, 0, 0.55)",
  },
  snackbarError: {
    backgroundColor: theme.palette.error.main,
  },
  listSection: {
    // maxWidth: "65.6rem",
    margin: "0 auto",
    "& h5": {
      marginBottom: "3rem",
      marginLeft: "1.5rem",
    },
  },
  paidInvoiceList: {
    maxWidth: "68.4rem",
    margin: "0 auto",
  },
  formList: {
    marginTop: "20px",
  },
  formLink: {
    textDecoration: "none",
    color: theme.palette.primary.dark,
  },
  h2heading: {
    fontSize: "15px",
    fontWeight: "bold",
  },
  listContent: {},
  primaryBtn: {
    display: "inline-block",
    border: "1px solid #000",
  },
  actionsButtons: {
    display: "flex",
    alignItems: "center",
  },
  container: {
    minHeight: "50rem",
    padding: "3rem 0rem",
    "& div[class^=MuiTabs-flexContainer]": {
      margin: "0 5rem 3rem 5rem",
    },
  },
  dueListHeader: {
    minWidth: 190,
  },
  paidListHeader: {
    minWidth: 90,
  },
  dueColumHeader: {
    minWidth: 80,
  },
  alignCenter: {
    textAlign: "center",
    minWidth: 57,
  },
  invoiceName: {
    minWidth: "22rem",
    textAlign: "center",
  },
  background1: {
    backgroundColor: "#0055a5",
    position: "absolute",
    left: 0,
    top: 0,
    width: "100%",
    height: "23vh",
    zIndex: 0,
  },
  detailsSection: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    //justifyContent: "center",
    alignItems: "center",
    marginTop: "0rem",
    overflow: "visible",
    margin: "0 auto",
    "& .header": {
      fontSize: "1.9rem",
    },
  },
  documentSection: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
    marginTop: "0rem",
    //justifyContent: "center",
    alignItems: "center",
    //justifyContent: "center",
    "& .header": {
      fontSize: "1.9rem",
    },
  },
  speakerSection: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
    marginTop: "0rem",
    //justifyContent: "center",
    alignItems: "center",
    //justifyContent: "center",
    "& .header": {
      fontSize: "1.9rem",
    },
    "& h2": {
      margin: "0 0 0 0",
      padding: "1.8rem 1.5rem",
      fontSize: "1.6rem",
      overflow: "visible",
    },
    "& .card": {
      margin: "0.5rem",
      paddingLeft: "1rem",
      display: "flex",
      minWidth: "100%",
      //justifyContent: "center",
      alignItems: "center",
      backgroundColor: "rgba(196, 196, 196, 0.08)",

      "& .card-heading": {
        fontSize: "1rem",
        paddingLeft: "1rem",
        margin: 0,
      },
      "& .card-data": {
        margin: 0,
        paddingLeft: "1rem",
      },
      "& div[class^=MuiAvatar-root]": {
        marginLeft: "0rem",
      },
      "& .card-content": {
        //textAlign: "start",
        marginLeft: "0rem",
      },
    },
  },
  registerBtnTop: {
    margin: "0 0 2rem 30rem",
    width: "8rem",
    minHeight: "1.5rem",
    fontSize: "0.8rem",
  },
  registerBtn: {
    marginTop: "1rem",
    width: "12rem",
    minHeight: "4rem",
    fontSize: "1.1rem",
  },
  detailTable: {
    listStyle: "none",
    margin: "0rem auto",
    display: "flex",
    flexDirection: "column",
  },
  listItem: {
    //width: "100%",
    padding: ".6rem 0rem",
    display: "flex",
    alignItems: "left",
    "& .key": {
      minWidth: "10rem",
      display: "inline-block",
      fontWeight: "bold",
    },
    "& .value": {
      minWidth: "8rem",
    },
  },
  itemStyle: {
    [theme.breakpoints.down("sm")]: {
      order: 2,
    },
  },
  middleSection: {
    margin: "0rem 0 3rem 0",
    //minHeight: "40vh",
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
    //alignItems: "center",
  },
  modalClose: {
    float: "right",
  },
  paper: {
    position: "absolute",
    width: "800px",
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    borderRadius: "3px",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    maxHeight: "90vh",
    overflowY: "scroll",

    [theme.breakpoints.down("sm")]: {
      width: "550px",
    },

    [theme.breakpoints.down("xs")]: {
      width: "400px",
    },
  },
  sessionList: {
    listStyle: "none",
    padding: "1rem",
    margin: "1rem",
    "& li": {
      padding: "1rem 0",
    },
    "& li .key": {
      fontWeight: "bold",
      display: "inline-block",
      minWidth: "6rem",
    },
    "& > div > footer": {
      display: "none",
    },
    "& > div ": {
      maxHeight: "22rem",
      minWidth: "17rem",
    },
    "& h3[class^='MuiTypography-root']": {
      padding: "4rem",
    },
    "& div[class*=MuiPaper-outlined]": {
      border: "none",
    },
  },
  sessionContainer: {
    marginTop: "0rem",
    padding: "0rem 0 0 0",
    paddingBottom: "2rem",
    // backgroundImage: `linear-gradient(to right, ${brandPalette["Primary"][100]}, ${brandPalette["Primary"][400]})`,
    //clipPath: "polygon(0 20%, 100% 0, 100% 80%, 0 100%)",
  },
  sessionHeader: {
    textAlign: "center",
    marginBottom: "0.5rem",
    fontSize: "1.9rem",
  },
  sessionItems: {
    display: "flex",
    justifyContent: "center",
    flexWrap: "wrap",
  },
  center: {
    textAlign: "center",
  },
  docList: {
    listStyle: "none",
    margin: "0 0",
    padding: 0,
    border: "1px solid rgba(0, 0, 0, 0.1)",
    width: "50%",

    "& .doc-item": {
      display: "flex",
      alignItems: "center",
      height: "max-content",
      margin: "1rem 0",
      justifyContent: "center",
    },
  },

  docLink: {
    "&:link, &:visited": {
      fontSize: "1.1rem",
      color: "white",
      display: "inline-block",
      textDecoration: "none",
      borderBottom: `1px solid ${brandPalette.Primary[500]}`,
      padding: "3px",
      transition: "all 0.2s",
      backgroundColor: brandPalette.Primary[900],
      borderRadius: "3px",
      paddingRight: "1.5rem",
      paddingLeft: "1.5rem",
      paddingTop: "6px",
      paddingBottom: "6px",
    },
    "&:hover": {
      color: "white",
      boxShadow: "0 1rem 2rem rgba(0,0,0, 0.15)",
    },

    "&:active": {
      boxShadow: "0 0.5rem 1rem rgba(0,0,0, 0.15)",
      transform: "translateY(0)",
    },
  },
  speakers: {
    backgroundColor: brandPalette["Primary"][900],
    height: "100%",
    boxSizing: "border-box",
    color: "rgba(255, 255, 255, 0.8)",
    textAlign: "left",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    paddingBottom: "0rem",
    overflow: "hidden",
    "& h2": {
      margin: "0 0 0 0",
      padding: "1.8rem 1.5rem",
      fontSize: "1.6rem",
      overflow: "visible",
    },
    "& .card": {
      padding: "0.5rem",
      paddingLeft: "0rem",
      display: "flex",
      minWidth: "100%",
      //justifyContent: "center",
      alignItems: "left",
      backgroundColor: "rgba(196, 196, 196, 0.08)",

      "& .card-heading": {
        fontSize: "1rem",
        padding: 0,
        margin: 0,
      },
      "& .card-data": {
        margin: 0,
        padding: 0,
      },
      "& div[class^=MuiAvatar-root]": {
        marginLeft: "0rem",
      },
      "& .card-content": {
        //textAlign: "start",
        marginLeft: "0rem",
      },
    },
  },
  avatar: {
    width: "2rem",
    height: "2rem",
  },
  margin: {
    marginRight: "1rem",
  },
  marginLeft1rem: {
    marginRight: "3rem",
  }
}));
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    "& > *": {
      margin: theme.spacing(1),
    },
    "& .statusTblheader": {
      backgroundColor: "rgba(255, 7, 0, 0.55)",
    },
  },
  snackbarSuccess: {
    backgroundColor: theme.palette.success.main,
  },
  statusTblheader: {
    backgroundColor: "rgba(255, 7, 0, 0.55)",
  },
  snackbarError: {
    backgroundColor: theme.palette.error.main,
  },
  listSection: {
    margin: "1rem auto",
    [theme.breakpoints.down("xs")]: {
      margin: "3rem auto",
    },
  },
  paidInvoiceList: {
    margin: "0 auto",
  },
  formList: {
    marginTop: "20px",
  },
  formLink: {
    textDecoration: "none",
    color: theme.palette.primary.dark,
  },
  h2heading: {
    fontSize: "15px",
    fontWeight: "bold",
  },
  listContent: {},
  primaryBtn: {
    display: "inline-block",
    border: "1px solid #000",
  },
  actionsButtons: {
    display: "flex",
    alignItems: "center",
  },
  container: {
    minHeight: "55rem",
    padding: "2rem 2rem",
    "& div[class^=MuiTabs-flexContainer]": {
      margin: "0 5rem 3rem 5rem",
    },
    [theme.breakpoints.down("xs")]: {
      padding: "3rem 0",
      "& div[class^=MuiTabs-flexContainer]": {
        margin: 0,
      },
    },
  },
  dueListHeader: {
    minWidth: 190,
  },
  paidListHeader: {
    minWidth: 90,
  },
  dueColumHeader: {
    minWidth: 80,
  },
  alignCenter: {
    textAlign: "center",
    minWidth: 57,
  },
  invoiceName: {
    minWidth: "22rem",
    textAlign: "center",
  },
  background1: {
    backgroundColor: "#0055a5",
    position: "absolute",
    left: 0,
    top: 0,
    width: "100%",
    height: "12rem",
    zIndex: 0,
    borderBottom: "3px solid",
    borderColor: theme.palette.primary.dark,
  },
  symbolStyle: {
    fontSize: "13px",
  },
}));


export { useProfileStyles, useStyles };
