import React, { useState, useEffect } from "react";
import { Grid } from "@material-ui/core";
import { ThemeProvider } from "@material-ui/core/styles";
import { globalTheme } from "style/globalTheme";
import ListWidget from "app/components/mvp/listWidget";
import { config } from "utilities/config";
import styled from "styled-components";
import { brandPalette } from "brand/theme/brandTheme";
import { usePreLoader } from "app/services/hooks/usePreLoader";
import axios from "axios";

const StartPage = styled.section`
  min-height: 50rem;
  ${globalTheme.breakpoints.up("sm")} {
    padding: 2rem 1rem 1rem 1rem;
  }
  ${globalTheme.breakpoints.down("xs")} {
    padding: 1rem;
  }
  height: 65rem;
`;

const Heading = styled.div`
  height: 15rem;
  padding: 1rem 1.2rem;
  background-image: linear-gradient(
      to right bottom,
      ${brandPalette["Complementary"][200]},
      ${brandPalette["Analogous-1"][600]}
    ),
    url(/brand/LOGIN-DESKTOP2.jpg);
  background-blend-mode: screen;
  clip-path: polygon(0 0, 100% 0, 100% 80%, 0% 100%);

  h2 {
    display: inline-block;
    justify-content: flex-start;
    margin: 5.7rem 15.6rem;
    font-size: 2rem;
    letter-spacing: 5px !important;
    text-transform: uppercase;
    font-weight: 700;
    letter-spacing: 0.07rem;
    padding: 0.5rem;
    border-radius: 8px;
    background-image: linear-gradient(
      to right,
      rgba(60, 60, 60, 0.5),
      rgba(0, 28, 48, 0.5)
    );
    //-webkit-background-clip: text;
    color: white;
  }

  ${globalTheme.breakpoints.down("sm")} {
    position: relative;
    width: 100%;
    h2 {
      margin: 0;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
    }
  }

  ${globalTheme.breakpoints.down("xs")} {
    display: inline-block;
    justify-content: center;
    margin: 0;
    padding: 0rem;
    position: relative;
    width: 100%;

    h2 {
      margin: 0;
      padding: 0.5rem;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
    }
  }
}
`;

function FirmDirectory() {
  const [memberData, setMemberData] = useState([]);
  const { enableLoader } = usePreLoader();

  useEffect(() => {
    setTimeout(() => {
      enableLoader(true, "Please wait, Loading Firm Members list...");
      axios
        .get(config.publicAPI.firmDirectory)
        .then((res) => {
          setMemberData(res.data?.data || []);
        })
        .catch((err) => {
          console.log("Data fetch failed:", err);
        })
        .finally(() => {
          enableLoader(false);
        });
    }, 0);
  }, [enableLoader]);

  const dueColumns = [
    {
      field: "includeInPublicDirectory",
      headerName: " ",
      hide: true,
    },
    {
      field: "id",
      headerName: "",
      hide: true,
    },
    { field: "membershipNumber", width: 350, headerName: "Firm Number" },
    {
      field: "membershipStatus",
      width: 220,
      headerName: "Firm Status",
    },
    { field: "membershipName", width: 250, headerName: "Firm Name" },
    { field: "primaryContact", width: 250, headerName: "Primary Contact" },
    // {
    //   field: "category",
    //   width: 150,
    //   headerName: "Category",
    // },
    // {
    //   field: "mailingAddress",
    //   width: 280,
    //   headerName: "Mailing Address",
    // },
  ];

  const configuration = {
    apiURL: config.teleportal.file.list,
    apiUrlDel: config.teleportal.file.delete,
    columns: dueColumns,
    searchPayload: "searchFilter",
    getlistPayload: {},
    deleteListPayload: {},
    listHeading: "",
    nonRecord: false,
    role: "",
    rows: "",
    formWidget: {},
    localDataStore: false,
    hideControls: true,
    hasAggregate: false,
    staticTable: true,
    staticRow: memberData,
    formatStaticRow: false,
    hideAdd: true,
    hiddenColumn: {},
    contactId: "",
    filteredValue: "",
    hasFilter: false,
    filterField: "",
    filterValue: [],
    aggregateColumns: [],
    aggregateField: "",
    hasPayloadfilter: false,
    hideDelete: true,
    hideSearch: false,
    enableLocalSearch: true,
    getRowId: (row) => row.membershipNumber,
  };

  return (
    <ThemeProvider theme={globalTheme}>
      <Heading>
        <h2>Firm Member List</h2>
      </Heading>
      <StartPage>
        <Grid container spacing={0}>
          {/* NOTE:layout */}
          {/* <Grid item xs={12} md={2}></Grid> */}
          <Grid item xs={12} md={12}>
            <ListWidget options={configuration} />
          </Grid>
          {/* <Grid item xs={12} md={2}></Grid> */}
        </Grid>
      </StartPage>
    </ThemeProvider>
  );
}

export default FirmDirectory;
