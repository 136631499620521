import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { AuthenticatedTemplate } from "@azure/msal-react";
import { globalTheme } from "style/globalTheme";
import { Grid, Button } from "@material-ui/core";

//Import Material-UI Library
import { ThemeProvider } from "@material-ui/core/styles";
import { useStyles } from "./style";

import ListComponent from "app/components/mvp/listWidget";
import { config } from "utilities/config";
import { getlistPayload } from "./model/payload";
import { getStudentSchema } from "./model/studentSchema";
import { getStudentUiSchema } from "./model/UiSchema";
import {
  CreaterequestPayload,
  CreatedocPayload,
  createDocAttachmentPayload,
} from "./model/createPayload";
import { stubTrue } from "lodash";
import { post } from "app/services/api";
import { usePreLoader } from "app/services/hooks/usePreLoader";

function ContractualRecords(props) {
  const classes = useStyles();
  const getcontactId = useSelector((state) => state.contactid);
  const getcontactName = useSelector((state) => state.user);
  const role = useSelector((state) => state.role);
  const [open, setOpen] = React.useState(false);
  const [updatedKey, setupdatedKey] = React.useState(null);
  //const [employerName, setEmployerName] = useState("test name");
  const employerName="test name2"
  let formData = {};
  const [contactFormData, setContactFormData] = useState({});
  const [permission, setPermission] = useState(true);
  const { enableLoader } = usePreLoader();
 

  const viewRecord = (params) => {
    window.location.href =
      "/cpd/viewrecord?id=" + params.row.ctpt_studenttrainingagreementid;
  };
  useEffect(() => {
    enableLoader(true, "Loading Details");
      const payload={
        "properties": [
            "_ctpt_employerid_value"
        ],
        "formattedFields": ["_ctpt_employerid_value"],
        "recordType": "ctpt_professionalhistories",
         "filters": [
            {
                "field": "_ctpt_employeeid_value",
                "searchvalue": getcontactId,
                "type": "guid"
            },
            {
              "field": "ctpt_typeofemployment",
              "searchvalue": "533910004",
              "type": "guid"
            }
        ],
        "FieldAndOperator": "true",
        "idfield": "contactid"
    }
      let api = config.teleportal.submitForm.getId;
      post(api, payload)
          .then((res) => {
            //debugger;
            if(res.status==204){
              setPermission(false)
            }else{
              setPermission(true)
              formData['_ctpt_employerid_value'] = res.data.data[0].value;
              localStorage.setItem("studentEmployerName", res.data.data[0].value);
              localStorage.setItem("studentEmployerId", res.data.data[0].id)
              setContactFormData(formData)
            }
            
          })
          .catch(console.log)
          .finally(() => {
            enableLoader(false);
          });
  },[getcontactId])

  const columns = [
    { field: "_ctpt_student_value", flex: 1, headerName: "", hide: true },
    {
      field: "ctpt_name",
      flex: 1,
      headerName: "Student Name",
    },
    {
      field: "ctpt_startdate",
      flex: 1,
      headerName: "Start Date",
      renderCell: (params) => (
        <div>
          {params.row.ctpt_startdate !== null
            ? new Date(params.row.ctpt_startdate).toISOString().substring(0, 10)
            : ""}
        </div>
      ),
    },
    {
      field: "ctpt_enddate",
      flex: 1,
      headerName: "End Date",
      renderCell: (params) => (
        <div>
          {params.row.ctpt_enddate !== null
            ? new Date(params.row.ctpt_enddate).toISOString().substring(0, 10)
            : ""}
        </div>
      ),
    },
    { field: "_ctpt_employer_value", flex: 1, headerName: "Employer" },
    { field: "ctpt_duration", flex: 1, headerName: "Duration" },
    {
      field: "ctpt_studenttrainingagreementid",
      flex: 1,
      headerName: " ",
      renderCell: (params) => (
        <div>
          <Button
            variant="outlined"
            color="primary"
            className="primaryBtn"
            onClick={() => {
              viewRecord(params);
            }}
          >
            Update Record
          </Button>
        </div>
      ),
    },
  ];
  const recordOptions = {
    apiURL: config.teleportal.file.list,
    apiUrlDel: config.teleportal.file.delete,
    columns: columns,
    searchPayload: "searchFilter",
    getlistPayload: getlistPayload,
    deleteListPayload: {},
    listHeading: "Students Training Agreement",
    formwidgetOptions: {
      widgetType: "add",
      formSchema: getStudentSchema(getcontactId),
      uiSchema: getStudentUiSchema(employerName),
      formData:contactFormData,
      apiURL: config.teleportal.submitForm.Create,
      entityType: "ctpt_studenttrainingagreements",
      formUischema: "",
      button1Label: "Create",
      button2Label: "Cancel",
      button3Label: "Clear",
      payloadSubmit: CreaterequestPayload(getcontactId),
      payloadSecondform: CreatedocPayload,
      isMemberDeclaration:false,
      columns: [],
      contactId: getcontactId,
      contactName: getcontactName.name,
      historyUrl: "",
      hasMultiplecall: true,
      hasModel: true,
      employerName:employerName
    },
    nonRecord: false,
    role: role,
    rows: "",
    localDataStore: false,
    hideDelete: true,
    hasAggregate: false,
    staticTable: false,
    staticRow: [],
    hiddenColumn: {},
    contactId: getcontactId,
    filteredValue: "",
    hasFilter: false,
    filterField: "",
    filterValue: [],
    aggregateColumns: [],
    aggregateField: "",
    isCustomFetch: false,
    hideSearch: true,
    pageSize: 6,
    hasPayloadfilter: true,
    hideAdd: false,
    controlLists: true,
  };
  const handleClickOpen = (params) => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  return (
    <ThemeProvider theme={globalTheme}>
      <AuthenticatedTemplate>
        <Grid container spacing={0}>
          <Grid item xs={12} md={12}>
            <div className={classes.container}>
            {getcontactId !== "" ? ( permission==false?
              <div><h1>Student Training Agreement</h1><br /><h3>You don't have permission to view this. Kindly contact Admin.</h3></div> :
              <ListComponent options={recordOptions} />
            ) : (
              ""
            )}
            </div>
          </Grid>
        </Grid>
      </AuthenticatedTemplate>
    </ThemeProvider>
   
  );
}

export default ContractualRecords;
