//Import React Library
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { AuthenticatedTemplate } from "@azure/msal-react";

//Import Material-UI Library
import { ThemeProvider } from "@material-ui/core/styles";

//Loading Data and Style..
import { globalTheme } from "style/globalTheme";
import { useStyles } from "./style";
//Loading components
import { Button } from "@material-ui/core";
import { config } from "utilities/config";

import ListComponent from "app/components/mvp/listWidget";
import {
  getEventsPayload,
  getEventRegPayload,
  getRegEventsPayload,
  getTimeZoneDefsPayload,
} from "./model/payload";
import TabWidget from "app/components/molecules/tab/TabWidget.mui";
import { useHistory } from "react-router-dom";
import { post } from "app/services/api";
import { formatData } from "utilities/datatable";
import _ from "lodash";
import { usePreLoader } from "app/services/hooks/usePreLoader";
import moment from "moment";

function Events() {
  const classes = useStyles();
  const role = useSelector((state) => state.role);
  const contactID = useSelector((state) => state.contactid);
  const history = useHistory();
  const { enableLoader } = usePreLoader();

  const [registeredEvents, setRegisteredEvents] = useState({});
  const [timeZoneDefs, setTimeZoneDefs] = useState({});

  const showEventDetails = (params, registrationID) => {
    history.push({
      pathname: "/event/details",
      state: {
        data: params.row,
        registrationID,
      },
    });
  };

  useEffect(() => {
    enableLoader(true);
    const loadRegisteredEvents = async () => {
      post(config.teleportal.file.list, getEventRegPayload(contactID))
        .then((response) => {
          const eventIdMap = {};
          if (response.data) {
            formatData(response.data)?.forEach((reg) => {
              if (
                reg?._msmedia_mediaevent_value &&
                reg.msmedia_registrationstatus !== 766860005 && // Cancelled
                reg.msmedia_registrationstatus !== 766860002 && // Declined
                reg.msmedia_registrationstatus !== 766860006 // Inactive
              ) {
                eventIdMap[reg._msmedia_mediaevent_value] = reg;
              }
            });
          }

          console.log({ eventIdMap });
          setRegisteredEvents(eventIdMap);
        })
        .catch((err) => {
          console.log("Error fetching Registered Events:", err);
        })
        .finally(() => {
          enableLoader(false);
        });
    };
    if (contactID) {
      loadRegisteredEvents().catch(console.log);
    }
  }, [contactID]);

  useEffect(() => {
    const loadTimeZoneDef = async () => {
      post(config.teleportal.file.list, getTimeZoneDefsPayload())
        .then((res) => {
          if (res.data) {
            let timeZoneMap = {};
            formatData(res.data)?.forEach((tObj) => {
              if (tObj.timezonecode && tObj.standardname) {
                timeZoneMap[tObj.timezonecode] = tObj.standardname;
              }
            });
            if (Object.keys(timeZoneMap).length > 0) {
              setTimeZoneDefs(timeZoneMap);
            }
          }
        })
        .catch((err) => {
          console.log("Unable to load the timezone defs", err);
        });
    };

    loadTimeZoneDef().catch(console.log);
  }, []);

  const unRegisteredColumns = [
    { field: "msmedia_mediaeventid", flex: 1, headerName: " ", hide: true },
    { field: "msmedia_eventtype", flex: 1, headerName: " ", hide: true },
    { field: "msmedia_description", flex: 1, headerName: " ", hide: true },
    { field: "msmedia_eventsubtitle", flex: 1, headerName: " ", hide: true },
    { field: "msmedia_eventtopic", flex: 1, headerName: " ", hide: true },
    { field: "ctpt_cancellationduedate", flex: 1, headerName: " ", hide: true },
    {
      field: "msmedia_registrationopenson",
      flex: 1,
      headerName: " ",
      hide: true,
    },
    {
      field: "msmedia_registrationcloseson",
      flex: 1,
      headerName: " ",
      hide: true,
    },
    {
      field: "msmedia_name",
      headerName: <p>Event Name</p>,
      flex: 1.5,
      renderCell: (params) => <p>{params.row.msmedia_name}</p>,
    },
    {
      field: "msmedia_eventtimezone",
      headerName: <p>Timezone</p>,
      flex: 1.8,
      renderCell: (params) => (
        <p>{timeZoneDefs[params.row.msmedia_eventtimezone] || ""}</p>
      ),
    },
    {
      field: "msmedia_startdate",
      headerName: <p>Start Date</p>,
      flex: 1,
      renderCell: (params) => {
        console.log("DDate:", params.row?.msmedia_startdate);
        return (
          <p>
            {params.row.msmedia_startdate !== null
              ? moment
                  .utc(params.row?.msmedia_startdate)
                  .format("DD MMM YYYY, h:mm")
              : ""}
          </p>
        );
      },
    },
    {
      field: "msmedia_enddate",
      headerName: <p>End Date</p>,
      flex: 1,
      renderCell: (params) => (
        <p>
          {params.row.msmedia_enddate !== null
            ? moment
                .utc(params.row?.msmedia_enddate)
                .format("DD MMM YYYY, h:mm")
            : ""}
        </p>
      ),
    },
    {
      field: "msmedia_primaryvenue",
      headerName: <p>Location</p>,
      flex: 1,
      hide: true,
      renderCell: (params) => <p>{params.row.msmedia_primaryvenue}</p>,
    },
    // {
    //   field: "msmedia_totalcost",
    //   headerName: <p>Total Cost</p>,
    //   flex: 1,
    //   renderCell: (params) => <p>{params.row.msmedia_totalcost}</p>,
    // },
    {
      field: "action",
      headerName: <p className={classes.dueListHeader}>Action</p>,
      flex: 1,
      renderCell: (params) => (
        <div className={classes.actionsButtons}>
          <Button
            variant={"contained"}
            color="primary"
            className={classes.btn}
            onClick={() => {
              showEventDetails(
                params,
                registeredEvents[params.row.msmedia_mediaeventid]?.id
              );
            }}
            disabled={false}
          >
            {"View"}
          </Button>
        </div>
      ),
    },
  ];

  const registeredEventColumns = [
    { field: "msmedia_mediaeventid", flex: 1, headerName: " ", hide: true },
    { field: "msmedia_eventtype", flex: 1, headerName: " ", hide: true },
    { field: "msmedia_description", flex: 1, headerName: " ", hide: true },
    { field: "msmedia_eventsubtitle", flex: 1, headerName: " ", hide: true },
    { field: "msmedia_eventtopic", flex: 1, headerName: " ", hide: true },
    { field: "ctpt_cancellationduedate", flex: 1, headerName: " ", hide: true },
    {
      field: "ctpt_discountedpriceuntil",
      flex: 1,
      headerName: " ",
      hide: true,
    },
    {
      field: "msmedia_registrationopenson",
      flex: 1,
      headerName: " ",
      hide: true,
    },
    {
      field: "msmedia_registrationcloseson",
      flex: 1,
      headerName: " ",
      hide: true,
    },
    {
      field: "msmedia_name",
      headerName: <p>Event Name</p>,
      flex: 1.5,
      renderCell: (params) => <p>{params.row.msmedia_name}</p>,
    },
    {
      field: "msmedia_eventtimezone",
      headerName: <p>Timezone</p>,
      flex: 1.8,
      renderCell: (params) => (
        <p>{timeZoneDefs[params.row.msmedia_eventtimezone] || ""}</p>
      ),
    },
    {
      field: "msmedia_startdate",
      headerName: <p>Start Date</p>,
      flex: 1,
      renderCell: (params) => (
        <p>
          {params.row.msmedia_startdate !== null
            ? moment
                .utc(params.row?.msmedia_startdate)
                .format("DD MMM YYYY, h:mm")
            : ""}
        </p>
      ),
    },
    {
      field: "msmedia_enddate",
      headerName: <p>End Date</p>,
      flex: 1,
      renderCell: (params) => (
        <p>
          {params.row.msmedia_enddate !== null
            ? moment.utc(params.row.msmedia_enddate).format("DD MMM YYYY, h:mm")
            : ""}
        </p>
      ),
    },
    {
      field: "msmedia_primaryvenue",
      headerName: <p>Location</p>,
      flex: 1,
      hide: true,
      renderCell: (params) => <p>{params.row.msmedia_primaryvenue}</p>,
    },
    {
      field: "msmedia_totalcost",
      headerName: <p>Total Cost</p>,
      flex: 1,
      renderCell: (params) => <p>{params.row.msmedia_totalcost}</p>,
    },
    {
      field: "action",
      headerName: <p className={classes.dueListHeader}>Action</p>,
      flex: 1,
      renderCell: (params) => (
        <div className={classes.actionsButtons}>
          <Button
            variant="contained"
            color="primary"
            className="primaryBtn"
            onClick={() => {
              showEventDetails(
                params,
                registeredEvents[params.row.msmedia_mediaeventid]?.id
              );
            }}
            disabled={false}
          >
            Cancel
          </Button>
        </div>
      ),
    },
  ];

  const customFilter = (objects) => {
    const newRow = formatData(objects);
    return _.filter(
      newRow.filter(
        (o) => o.msmedia_eventtype !== 533910001 && o.statecode === 0
      )
    );
  };

  const unRegisteredEventOptions = {
    apiURL: config.teleportal.events.getAllEventList,
    apiUrlDel: config.teleportal.file.delete,
    columns: unRegisteredColumns,
    searchPayload: "searchFilter",
    getlistPayload: getEventsPayload,
    deleteListPayload: {},
    listHeading: "",
    formWidget: {},
    nonRecord: false,
    role: role,
    rows: "",
    localDataStore: false,
    hideDelete: true,
    hasAggregate: true,
    staticTable: false,
    staticRow: [],
    hiddenColumn: {},
    contactId: contactID,
    filteredValue: "",
    hasFilter: true,
    hasPayloadfilter: false,
    payloadModifyForbidden: true,
    filterField: "statecode",
    filterValue: [0],
    hideSearch: true,
    pageSize: 10,
    hideAdd: true,
    controlLists: false,
  };

  const registeredEventOptions = {
    apiURL: config.teleportal.file.list,
    apiUrlDel: config.teleportal.file.delete,
    columns: registeredEventColumns,
    searchPayload: "searchFilter",
    getlistPayload: getRegEventsPayload(Object.keys(registeredEvents)),
    deleteListPayload: {},
    listHeading: "",
    formWidget: {},
    nonRecord: false,
    role: role,
    rows: "",
    localDataStore: false,
    hideDelete: true,
    hasAggregate: true,
    staticTable: false,
    staticRow: [],
    hiddenColumn: {},
    contactId: contactID,
    filteredValue: "",
    hasFilter: true,
    customFilter: customFilter,
    hasPayloadfilter: false,
    payloadModifyForbidden: true,
    filterField: "statecode",
    filterValue: [0],
    hideSearch: true,
    pageSize: 10,
    hideAdd: true,
    controlLists: false,
  };

  return (
    <ThemeProvider theme={globalTheme}>
      <AuthenticatedTemplate>
        <div className={classes.background1}></div>
        <div id="cTTP_applicationList" className={classes.container}>
          <TabWidget
            options={{
              defaultTabID: 1,
              tabNavJson: [
                {
                  label: "Upcoming Events",
                  id: 1,
                  entity: (
                    <div className={classes.listSection}>
                      <ListComponent options={unRegisteredEventOptions} />
                    </div>
                  ),
                },
                {
                  label: "Your Events",
                  id: 2,
                  entity: (
                    <div className={classes.paidInvoiceList}>
                      <ListComponent options={registeredEventOptions} />
                    </div>
                  ),
                },
              ],
            }}
          />
        </div>
      </AuthenticatedTemplate>
    </ThemeProvider>
  );
}

export default Events;
