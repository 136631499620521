export const stateCodes = {
    invoice: {
        // 1:"New",
        // 2:"Pending",
        // 100000000: 'Processing Payment',
        // 3: 'In Progress',
        // 4: 'No Money',
        // 100000001: 'Payment Failed',
        // 100001: 'Complete',
        // 100002: 'Partial',
        // 100003: 'Invoiced',
        0:"Active",
        1:'Submitted',
        2:'Cancelled',
        3:'Fulfilled',
        4:'Invoiced',
        100000000:'Partial',
        100000001:'Complete',
        100000002:'Cancelled'
        
    },
    caseTypes:{
        1:"Active",
        2:"In Active",
        727530003:"Cancelled",
        727530000:"In Progress",
        727530001:"On Hold",
        727530002:"Resolved"
        
    }
}

export const typeCodes = {
"acc":1,
"firm":0
}

export const resultCodes={
    "533910000":"Pass",
    "533910001":"Fail",
    "533910002":"Bad Fail",
    "533910003":"Absent"
}
