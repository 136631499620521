export const getcolumns = () => {
  return [
    {
      field: "application",
      width: 120,
      renderHeader: () => {
        <strong>{"Aggregate"}</strong>;
      },
    },
    { field: "status", headerName: "Last Name", width: 120 },
    { field: "", width: 120 },
  ];
};

export const applycolumns = [
  { field: "id", width: 10, headerName: " " },
  { field: "ctpt_name", width: 200, headerName: "Application" },
  { field: "description", width: 440, headerName: "Description" },
  {
    field: "applynow",
    headerName: " ",
    width: 100,
    renderCell: () => {
      return (
        <div>
          <span variant="outlined" color="primary" className="primary-btn">
            Apply Now
          </span>
        </div>
      );
    },
  },
];

export const aggregatecolumns = [
  { field: "invoiceid", width: 10, headerName: " ", hide: true },
  { field: "_salesorderid_value", width: 10, headerName: " ", hide: true },
  { field: "name", width: 250, headerName: "Invoice Name" },
  { field: "totalamount", width: 130, headerName: "Amount" },
  { field: "duedate", headerName: "Due", width: 130 },
  { field: "statuscode", headerName: "Status", width: 130 },
  { field: "ctpt_submissiondate", headerName: " ", width: 230 },
];
