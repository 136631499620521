export const invoiceNewdata={
  "invoiceDate": "2021-11-24T09:57:09Z",
  "dueDate": "0001-01-01T00:00:00",
  "invoiceNumber": "ORD-01008-P1Y1R4",
  "invoiceTotalTax": 12.0,
  "invoiceTotalDiscount": 2.0,
  "invoiceTotalAmmount": 133.0,
  "currencyCode": "GBP",
  "currencySymbol": "£",
  "products": [
      {
          "code": null,
          "description": null,
          "unit": 0.0,
          "quantity": 1.0,
          "vat": 12.0,
          "ammount": 1233.0
      },
      {
        "code": null,
        "description": null,
        "unit": 0.0,
        "quantity": 1.0,
        "vat": 12.0,
        "ammount": 13.0
    }
  ],
  "invoiceTotal": {
      "total": 0.0,
      "valueTax": 12.0,
      "invoideTotal": 123.0
  }
}
