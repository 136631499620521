import React, { useEffect, useState } from "react";
import { Grid } from "@material-ui/core";
import { useSelector, useDispatch } from "react-redux";
import { contactSchema } from "app/components/mvp/formWidget/model/contactJson";
import { contactUiSchema } from "app/components/uiSchema/contactUiSchema";
import { ContactPayload } from "app/services/payloadSubmit/contactPayload";
import { config } from "utilities/config";
import { post } from "app/services/api";
import FormWidget from "app/components/mvp/formWidget";

function PersonalInformation(props) {
  const userEntitlement = useSelector((state) => state.entitleMents);
  const userRole = useSelector((state) => state.role);
  let permissions = props.permissions;
  const contactid = useSelector((state) => state.contactid);
  const [contactFormData, setContactFormData] = useState({});
  const dispatch = useDispatch();

  const contact = {
    widgetType: "updateContact",
    formSchema: contactSchema,
    uiSchema: contactUiSchema,
    formData: contactFormData,
    apiURL: config.teleportal.submitForm.Update,
    payloadSchema: {},
    entityType: "contacts",
    formUischema: "",
    button1Label: userRole && userRole.name && !userRole.name.includes('Individual') ? "Save" : "",
    button2Label: "Go Back",
    payloadSubmit: ContactPayload,
    contactId: contactid,
    goBackPage: "/profile",
    button2GoBack: true,
    historyUrl: "",
  };

  const [contactOptions, setContactOptions] = useState(contact);

  useEffect(() => {
    if (contactid.length > 0) {
      getContactDetails();
    }
  }, [contactid]);

  const updateFormData = (entityType, formData) => {
    if (userRole && userRole.name && userRole.name.includes('Individual') &&
      permissions &&
      permissions.permissionsets != null &&
      permissions.permissionsets.length > 0 &&
      permissions.permissionsets[0] != null &&
      permissions.permissionsets[0].canwrite == "0" &&
      permissions.permissionsets[0].cancreate == "0") {
      contact.formSchema.properties.firstname.disabled = true;
      contact.formSchema.properties.lastname.disabled = true;
      contact.formSchema.properties.birthdate.isReadonly = true;
      contact.formSchema.properties.ctpt_isresidentofbotswana.disabled = true
      contact.formSchema.properties.ctpt_othername.disabled = true
      contact.formSchema.properties.ctpt_profiledetailsconfirmedaccurateon.isReadonly = true
      //contact.formSchema.properties.fax.disabled = true
      contact.formSchema.properties.fullname.disabled = true
      contact.formSchema.properties.salutation.disabled = true
    }
    else if (userRole && userRole.name && userRole.name.includes('Student') &&
      permissions &&
      permissions.permissionsets != null &&
      permissions.permissionsets.length > 0 &&
      permissions.permissionsets[0] != null &&
      permissions.permissionsets[0].canwrite == "0" &&
      permissions.permissionsets[0].cancreate == "0"
    ) {
      contact.formSchema.properties.firstname.disabled = true;
      contact.formSchema.properties.lastname.disabled = true;
      contact.formSchema.properties.birthdate.isReadonly = true;
    }

    contact.formData = formData;
    contact.initialFormData = formData;
    setContactOptions(contact);
    setContactFormData(formData);
  };

  const getContactDetails = () => {
    let api;
    let payloads;
    const formData = {};
    api = config.teleportal.submitForm.getId;

    payloads = {
      properties: [
        "contactid",
        "salutation",
        "firstname",
        "lastname",
        "fullname",
        "ctpt_othername",
        "birthdate",
        //"fax",
        "ctpt_isresidentofbotswana",
        "ctpt_profiledetailsconfirmedaccurateon",
      ],
      formattedFields: ["gendercode"],
      recordType: "contacts",
      id: contactid,
    };
    post(api, payloads)
      .then((res) => {
        res.data.data.forEach((x) => {
          console.log("x.name--" + x.name);
          if (x.name === "ctpt_profiledetailsconfirmedaccurateon") {
            formData["ctpt_profiledetailsconfirmedaccurateon"] = new Date();
          }
          formData[x.name] = x.value;
        });
        updateFormData({}, formData);
      })
      .catch(console.log);
  };

  const getResponse = (res) => {
    if (res.data.id) {
      dispatch({
        type: "SET_STATUS",
        payload: {
          message: "Record updated successfully",
          code: "",
          display: "show",
          severity: "success",
        },
      });
    } else {
      dispatch({
        type: "SET_STATUS",
        payload: {
          message: "Record update failed",
          code: "",
          display: "show",
          severity: "error",
        },
      });
    }
  };

  return (
    <Grid container spacing={0} justify="center">
      <Grid item xs={12} md={9}>
        <FormWidget
          options={contactOptions}
          updateFormData={updateFormData}
          successResponse={getResponse}
        />
      </Grid>
    </Grid>
  );
}

export default PersonalInformation;
