import { Button } from "@material-ui/core";
import { useSelector, useDispatch } from "react-redux";
import React from "react";
import { useStyles } from "../style";
import Upload from "app/components/atoms/upload";
function UploadProofOfPayment({ cancelPayment, finishPayment,contactId }) {
  const getcontactId = useSelector((state) => state.contactid);
  const classes = useStyles();
  const uploadSchema = {
    schema: {
      title: "",
      helpText: "",
      tootltipheading: "head",
      tootltipText: "text",
      type: "string",
      datatype: "upload",
      format: "string",
      requiredCheck: true,
      containerName: "contacts",
      fileName: "id",
      extensions: ".doc,.pdf",
      acceptType: [
        "application/pdf",
        "application/msword",
        "image/png",
        "image/jpeg",
      ],
      acceptExtension: [".pdf", ".doc", ".docx", ".png", ".jpeg"],
      recordPath:`${getcontactId}/`,
      onChange: {},
    },
  };
  return (
    <div style={{ marginLeft: "2rem" }} className={classes.makePay}>
      <p>
        <b style={{ fontWeight: 700 }}>If you have already paid,</b> please
        upload proof of payment using the file upload below. This will be
        reviewed by our membership team before your application is approved.
      </p>
      <Upload
        recordType={uploadSchema.schema.containerName}
        recordPath={uploadSchema.schema.recordPath}
        getProps={uploadSchema}
      ></Upload>
      <div className={classes.buttonSection}>
        <Button
          color="primary"
          variant="outlined"
          onClick={finishPayment}
          className={classes.finishBtn}
        >
          Upload and Continue
        </Button>
        <Button color="primary" variant="outlined" onClick={cancelPayment}>
          Cancel
        </Button>
      </div>
    </div>
  );
}

export default UploadProofOfPayment;
