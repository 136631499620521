export const getRegistrationPayload = (JsonData) => {
  console.log({ JsonData });
  return {
    recordtype: "msmedia_mediaregistrations",
    data: [
      ...JsonData.data,
      {
        name: "msmedia_Contact",
        value: JsonData.contactid,
        type: "lookup",
        relatedentity: "contacts",
      },
      {
        name: "msmedia_MediaEvent",
        value: JsonData.eventID,
        type: "lookup",
        relatedentity: "msmedia_mediaevents",
      },
      {
        name: "msmedia_registrationstatus",
        value: 766860003,
        type: "picklist",
      }, 
      {
        name: "msmedia_registeredon",
        value: new Date().toISOString().slice(0, 10),
        type: 'date',
      }
    ],
  };
};
