import { post } from "app/services/api";
import { config } from "utilities/config";

const getCreateEvidencePayload = (declarationID, JsonData) => {
  return {
    recordtype: "ctpt_evidences",
    data: [
      ...JsonData.data.filter((x) => x.name !== "ctpt_documenturl" && x.name !== "ctpt_cpdhours"),
      {
        name: "ctpt_cpdhours",
        value: JsonData.data[7].value,
        type: "decimal",
      },
      {
        name: "ctpt_MemberDeclaration",
        value: declarationID,
        type: "lookup",
        relatedentity: "ctpt_memberdeclarations",
      },
      {
        name: "ctpt_evidenceurl",
        value: JsonData.docUrl,
        type: "string",
      },
      {
        name: "ctpt_Contact",
        value: JsonData.contactid,
        type: "lookup",
        relatedentity: "contacts",
      },
    ],
  };
};

export const CreaterequestPayload = (declarationID) => (JsonData) => {
  JsonData.data[3].value = !JsonData.data[3].value
    ? new Date().toISOString().slice(0, 10)
    : JsonData.data[3].value;
  const details = JsonData.data;
  console.log("JSON Data:", JsonData);

  return post(
    config.teleportal.submitForm.Create,
    getCreateEvidencePayload(declarationID, JsonData)
  )
    .then((res) => {
      if (res.data?.id) {
        return {
          recordtype: "ctpt_supportingdocuments",
          data: [
            {
              name: "ctpt_name",
              value: details[0].value,
              type: "string",
            },
            {
              name: "ctpt_documenttype",
              value: 100000001,
              type: "picklist",
            },
            {
              name: "ctpt_Evidence",
              value: res.data.id,
              type: "lookup",
              relatedentity: "ctpt_evidences",
            },
            {
              name: "ctpt_documenturl",
              value: JsonData.docUrl,
              type: "string",
            },
          ],
        };
      }
    })
    .catch((err) => {
      console.log("ERROR", err);
      return {};
    });
};

export const createDocAttachmentPayload = (
  _contactName,
  supportingDocID,
  _docUrl,
  JsonData
) => {
  console.log({ supportingDocID, JsonData });
  const base64Doc = JsonData.base64Doc;
  if (!(base64Doc?.data && base64Doc?.fileName && supportingDocID)) {
    return null;
  }
  return {
    recordtype: "annotations",
    data: [
      {
        name: "subject",
        value: JsonData.data?.[0]?.value,
        type: "string",
      },
      {
        name: "filename",
        value: base64Doc.fileName,
        type: "string",
      },
      {
        name: "documentbody",
        value: base64Doc.data,
        type: "string",
      },
      {
        name: "objectid_ctpt_supportingdocument",
        value: supportingDocID,
        type: "lookup",
        relatedentity: "ctpt_supportingdocuments",
      },
      {
        name: "mimetype",
        value: base64Doc.fileType,
        type: "string",
      },
    ],
  };
};
