import React, { useEffect, useState } from "react";
import { AuthenticatedTemplate } from "@azure/msal-react";
import { ThemeProvider } from "@material-ui/core/styles";
import { globalTheme } from "style/globalTheme";
import { Grid } from "@material-ui/core";
import { config } from "utilities/config";
import {
  getFormschemapayload,
  createFormsubmitpayload,
  getFormsubmitpayload,
} from "data/payload";
import Sonicwebform from "app/components/mvp/sonicwebform";
import { uiSchema } from "app/pages/indvmembership/model/uiSchema";
import styled from "styled-components";
import Button from "app/components/atoms/Button/RoundCornerButton";
import { break_addressbar } from "utilities/splitUrl";
import CreateOrder from "brand/components/order";
import Link from "@material-ui/core/Link";
import WorkExperience from 'app/pages/indvmembership/model/WorkExperience.pdf'
import PoliceClearanceSigned from 'app/pages/indvmembership/model/PoliceClearanceSigned.pdf'

const StartPage = styled.section`
  li {
    //margin-top: 1rem;
    p {
      margin: 0;
      padding: 0;
    }
  }
  ${globalTheme.breakpoints.up("sm")} {
    padding: 4rem 4rem 4rem 300px;
  }
  ${globalTheme.breakpoints.down("xs")} {
    padding: 1rem;
  }
`;

function IndvMembership(props) {
  const [isStarted, setStartApplication] = useState(0);
  const [isNew, setisNew] = useState(true);
  const [urlParam, seturlParam] = useState({
    formName: "individualMembership",
    subId: "",
  });
  const options = {
    formTitle: "Individual Membership Application",
    getFormschemaURL: config.sonicwebform.getFormschema,
    createFormsubmitURL: config.sonicwebform.createFormsubmit,
    getFormsubmitURL: config.sonicwebform.getFormsubmit,
    getFormschemapayload: getFormschemapayload,
    createFormsubmitpayload: createFormsubmitpayload,
    getFormsubmitpayload: getFormsubmitpayload,
    WebFormName: urlParam.formName,
    uiSchema: uiSchema,
    submissionId: urlParam.subId,
    stepCount: 0,
    formschema: {},
    swfdata: {},
    swfId: "",
    createForm: isNew,
    unAuthenticate: false,
    handleChange: props.handleChange,
    emptyOrder: false,
    hasPayment: true,
  };

  const startApp = () => {
    setStartApplication(1);
  };
  useEffect(() => {
    localStorage.setItem("oid", "");
    console.log("onload " + window.location.href.indexOf("?"));
    if (window.location.href.indexOf("?") > -1) {
      seturlParam(break_addressbar(window.location.href));
      console.log(break_addressbar(window.location.href));
      setisNew(false);
    } else {
      setisNew(true);
    }
  }, []);

  useEffect(() => {
    //console.log("url parameters"+JSON.stringify(urlParam))
  }, [urlParam]);

  return (
    <ThemeProvider theme={globalTheme}>
      <AuthenticatedTemplate>
        <div id="cTTP_sonicForm">
          <Grid container spacing={0}>
            <Grid item xs={12} md={12}>
              {/* <Sonicwebform options={options} /> */}
              {isStarted === 0 ? (
                <StartPage>
                  <h1>Individual membership Application</h1>
                  <p style={{ fontWeight: "700", marginTop: "2rem" }}>
                    Kindly NOTE that you will be required to attach the
                    following documents during the application.
                  </p>
                  <ul>
                    <li>
                      Certified Copies of Certificates evidencing:
                      <p style={{ marginTop: "1rem" }}>
                        (a)Examination passed
                      </p>{" "}
                      <p>(b) Academic qualification</p>
                      <p>(c) University degree</p>{" "}
                      <p style={{ marginBottom: "1rem" }}>
                        (d) Professional qualifications
                      </p>
                    </li>
                    <li>
                      Evidence of Membership of Professional Accountancy Bodies:
                      <p style={{ marginTop: "1rem" }}>
                        (a) Membership Certificate
                      </p>{" "}
                      <p style={{ marginBottom: "1rem" }}>
                        (b) Proof of Continuing Membership in Good Standing
                      </p>
                    </li>
                    <li>Two Reference Letters from BICA active members</li>
                    <li>Confirmation letter of employment</li>
                    <li>Certified copy of Identity Document</li>
                    <li>
                      Certified Copy of Proof of Change of Name (e.g. marriage
                      certificate)
                    </li>
                    <li>
                      Work Experience Checklist (For Accounting Technicians
                      only - please click <a href={WorkExperience} download="workExperience">here</a> to download the form)
                    </li>
                    <li>
                      Police Clearance Certificate (please click <a href={PoliceClearanceSigned} download="policeClearance">here</a> to download the form)
                    </li>
                  </ul>

                  <br />
                  <p style={{ marginLeft: "1.5rem" }}>
                    Supported file types:- PDF, JPG, JPEG, PNG{" "}
                  </p>
                  <br />
                  <br />
                  <Button
                    color="primary"
                    variant="contained"
                    onClick={startApp}
                    style={{ marginLeft: "1.5rem" }}
                  >
                    {isNew ? "Start Application" : "Resume Application"}
                  </Button>
                </StartPage>
              ) : (
                <div>
                  <Sonicwebform options={options} />
                </div>
              )}
            </Grid>
          </Grid>
        </div>
      </AuthenticatedTemplate>
    </ThemeProvider>
  );
}

export default CreateOrder(IndvMembership);
