import CustomTextBox from "app/components/atoms/customTextBox";
import CustomDate from "app/components/atoms/customDate";
import Upload from "app/components/atoms/upload";
const componentType = {
  upload: {
    "ui:widget": (props) => {
      return (
        <Upload
          recordType={props.schema.containerName}
          recordPath={props.schema.recordPath}
          getProps={props}
        />
      );
    },
  },
};

export const getStudentUiSchema =(employerName)=> {return {
"ui:title": "",
   
ctpt_name: {
    "ui:widget": props => {
      return <CustomTextBox getProps={props} />;
    }
},
_ctpt_employerid_value: {
    "ui:widget": props => {
      return <CustomTextBox getProps={props} />;
    }
},
ctpt_startdate: {
    "ui:widget": props => {
      return <CustomDate getProps={props} />;
    }
},
ctpt_duration: {
    "ui:widget": props => {
      return <CustomTextBox getProps={props} />;
    }
},
ctpt_enddate: {
  "ui:widget": props => {
    return <CustomDate getProps={props} />;
  }
},
ctpt_documenturl:componentType.upload

}};

export const getDocumentSchema = {
  title: " ",
  logicalname: "Contact2",
  type: "object",
  properties: {
    ctpt_name: {
      title: "Name",
      type: "string",
      schemaname: "ctpt_customerid",
      datatype: "string",
      isDynamic: false,
    },
    ctpt_documenttype: {
      title: "Document Type",
      type: "string",
      schemaname: "ctpt_documenttype",
      datatype: "string",
    },
    ctpt_case: {
      title: "Case",
      type: "string",
      schemaname: "ctpt_case",
      datatype: "string",
    },
    ctpt_documenturl: {
      title: "Student attendance register",
      type: "string",
      schemaname: "ctpt_documenturl",
      datatype: "string",
      requireBoburl: true,
    },
  },
};


