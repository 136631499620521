import React, { useEffect, useState } from "react";
import { AuthenticatedTemplate } from "@azure/msal-react";
import { ThemeProvider } from "@material-ui/core/styles";
import { globalTheme } from "style/globalTheme";
import { Grid } from "@material-ui/core";
import { config } from "utilities/config";
import {
  getFormschemapayload,
  createFormsubmitpayload,
  getFormsubmitpayload,
} from "data/payload";
import Sonicwebform from "app/components/mvp/sonicwebform";
import { uiSchema } from "app/pages/tutionprovider/model/uiSchema";
import styled from "styled-components";
import Button from "app/components/atoms/Button/RoundCornerButton";
import { break_addressbar } from "utilities/splitUrl";
import CreateOrder from "brand/components/order";
import "./style.css";

const StartPage = styled.section`
  ${globalTheme.breakpoints.up("sm")} {
    padding: 4rem 4rem 4rem 300px;
  }
  ${globalTheme.breakpoints.down("xs")} {
    padding: 1rem;
  }
`;

function TutionProvider(props) {
  const [isStarted, setStartApplication] = useState(0);
  const [isNew, setIsNew] = useState(true);
  const [urlParam, setUrlParam] = useState({
    formName: "tutionProvider",
    subId: "",
  });
  const options = {
    formTitle: "Tuition Provider Application",
    getFormschemaURL: config.sonicwebform.getFormschema,
    createFormsubmitURL: config.sonicwebform.createFormsubmit,
    getFormsubmitURL: config.sonicwebform.getFormsubmit,
    getFormschemapayload: getFormschemapayload,
    createFormsubmitpayload: createFormsubmitpayload,
    getFormsubmitpayload: getFormsubmitpayload,
    WebFormName: urlParam.formName,
    uiSchema: uiSchema,
    submissionId: urlParam.subId,
    stepCount: 0,
    formschema: {},
    swfdata: {},
    swfId: "",
    createForm: isNew,
    unAuthenticate: false,
    handleChange: props.handleChange,
    emptyOrder: false,
    hasPayment: false,
  };

  const startApp = () => {
    setStartApplication(1);
  };
  useEffect(() => {
    localStorage.setItem("oid", "");
    console.log("onload " + window.location.href.indexOf("?"));
    if (window.location.href.indexOf("?") > -1) {
      setUrlParam(break_addressbar(window.location.href));
      console.log(break_addressbar(window.location.href));
      setIsNew(false);
    } else {
      setIsNew(true);
    }
  }, []);

  useEffect(() => {
    //console.log("url parameters"+JSON.stringify(urlParam))
  }, [urlParam]);

  return (
    <ThemeProvider theme={globalTheme}>
      <AuthenticatedTemplate>
        <div id="cTTP_sonicForm">
          <Grid container spacing={0}>
            <Grid item xs={12} md={12}>
              {/* <Sonicwebform options={options} /> */}
              {isStarted === 0 ? (
                <StartPage>
                  <h1>Tuition Provider Application</h1>
                  <p>
                    The Tuition Provider form addresses the general and institutional accreditation standards required by BICA from the institutions which want to offer BICA curriculum. The institution should be able to demonstrate evidence of each item by providing copies where applicable.
                  </p>
                  <p style={{ fontWeight: "700", marginTop: "2rem" }}>
                    Kindly NOTE that you will be required to attach the
                    following documents during the application.
                  </p>
                  <ul>
                    <li>Copy of Registration Certificate</li>
                    <li>Copy of Organizational Structure</li>
                    <li>Employees' Qualification Experience List</li>
                    <li>Audited Financial Statements</li>
                    <li>Copy of Recruitment Procedures</li>
                    <li>Copy of Instructor Evaluation Form</li>
                    <li>Copy of Staff Evaluation Form</li>
                    <li>Copy of Rental/Lease Agreement</li>
                  </ul>

                  <br />
                  <br />
                  <Button
                    color="primary"
                    variant="contained"
                    onClick={startApp}
                  >
                    {isNew ? "Start Application" : "Resume Application"}
                  </Button>
                </StartPage>
              ) : (
                  <div>
                    <Sonicwebform options={options} />
                  </div>
                )}
            </Grid>
          </Grid>
        </div>
      </AuthenticatedTemplate>
    </ThemeProvider>
  );
}

export default CreateOrder(TutionProvider);
