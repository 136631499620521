//Import React Library
import React, { useState } from 'react';
import { useSelector } from "react-redux";
import { settings } from "constants/settings"

async function loadComponent() {
    return import('./Upload.' + settings.UI + '.js')
}

function UploadComponent(props) {
    const getcontactId = useSelector((state) => state.contactid);
    const { children, data, recordType, recordPath, getProps } = props;

    const [component, setComponent] = useState(null)

    loadComponent().then(x => setComponent(x))

    if (!component) return null;
    return (
        <component.default data={data} recordType={recordType} recordPath={`${getcontactId}/`} getProps={getProps}>{children}</component.default>
    )
}

export default UploadComponent;