import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    "& > *": {
      margin: theme.spacing(1),
    },
    "& .statusTblheader": {
      backgroundColor: "rgba(255, 7, 0, 0.55)",
    },
  },
  snackbarSuccess: {
    backgroundColor: theme.palette.success.main,
  },
  statusTblheader: {
    backgroundColor: "rgba(255, 7, 0, 0.55)",
  },
  snackbarError: {
    backgroundColor: theme.palette.error.main,
  },
  listSection: {
    marginTop: "20px",
  },
  formList: {
    marginTop: "20px",
  },
  formLink: {
    textDecoration: "none",
    color: theme.palette.primary.dark,
  },
  h2heading: {
    fontSize: "15px",
    fontWeight: "bold",
  },
  primaryBtn: {
    display: "inline-block",
    border: "1px solid #000",
  },
  listContent: {
    paddingBottom: "50px",
  },
  container: {
    minHeight: "50rem",
    padding: "3rem 0rem",
    "& div[class^=MuiTabs-flexContainer]": {
      margin: "0 5rem 3rem 5rem",
    },
  },
}));
