import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";

export const usePreLoader = () => {
  const loaderEnabled = useSelector((state) => state.showLoader);
  const dispatch = useDispatch();

  const enableLoader = useCallback(
    (status, message = "") => {
      dispatch({
        type: "SET_SHOW_LOADER",
        payload: { status: !!status, message },
      });
    },
    [dispatch]
  );

  return {
    loaderEnabled,
    enableLoader,
  };
};
