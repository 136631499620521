export const upgradeTilesData = [
  {
    title: "Accounting Technician to Associate Level",
    linkText: "Upgrade Now",
    linkURI: "/application/upgradeTechnician",
    allowmemberType: "Accounting",
  },
  {
    title: "Associate to Fellow",
    linkText: "Upgrade Now",
    linkURI: "/application/upgradeAssociate",
    allowmemberType: "Associate",
  },
  {
    title: "Accounting Technician to Fellow",
    linkText: "Upgrade Now",
    linkURI: "/application/upgradeFellow",
    allowmemberType: "Accounting",
  },
];
