import React, { useEffect, useState } from "react";
import { Grid } from "@material-ui/core";
import { useSelector, useDispatch } from "react-redux";
import { contactSchema } from "./model/emailUpdateSchema";
import { contactUiSchema } from "./model/emailUpdateUISchema";
import { ContactPayload } from "./model/payload.js";
import { config } from "utilities/config";
import { post } from "app/services/api";
import FormWidget from "app/components/mvp/formWidget";
import styled, { css } from "styled-components";

const StyledFormWidget = styled(FormWidget)(
  () => css`
    div[class="button-group"] {
      margin-top: 2rem;
    }
  `
);

function EmailUpdate(props) {
  const contactid = useSelector((state) => state.contactid);
  const [contactFormData, setContactFormData] = useState({});
  const dispatch = useDispatch();
  const userEntitlement = useSelector((state) => state.entitleMents);
  const userRole = useSelector((state) => state.role);
  let permissions =
    userEntitlement && userEntitlement.find((x) => x.route == props.match.path);

  const contact = {
    widgetType: "updateContact",
    formSchema: contactSchema,
    uiSchema: contactUiSchema,
    formData: contactFormData,
    apiURL: config.teleportal.submitForm.Update,
    payloadSchema: {},
    entityType: "contacts",
    formUischema: "",
    button1Label: userRole && userRole.name && !userRole.name.includes('Individual') ? "Save" : "",
    button2Label: "Go Back",
    payloadSubmit: ContactPayload,
    contactId: contactid,
    goBackPage: "/profile",
    button2GoBack: true,
    historyUrl: "",
  };

  const [contactOptions, setContactOptions] = useState(contact);

  useEffect(() => {
    if (contactid.length > 0) {
      getContactDetails();
    }
  }, [contactid]);

  const updateFormData = (entityType, formData) => {
    if (userRole && userRole.name && userRole.name.includes('Individual') &&
      permissions &&
      permissions.permissionsets != null &&
      permissions.permissionsets.length > 0 &&
      permissions.permissionsets[0] != null &&
      permissions.permissionsets[0].canwrite == "0" &&
      permissions.permissionsets[0].cancreate == "0") {
      contact.formSchema.properties.emailaddress1.disabled = true;
    }
    contact.formData = formData;
    contact.initialFormData = formData;
    setContactOptions(contact);
    setContactFormData(formData);
  };

  const getContactDetails = () => {
    let api;
    let payloads;
    const formData = {};
    api = config.teleportal.submitForm.getId;

    payloads = {
      properties: ["emailaddress1"],
      recordType: "contacts",
      id: contactid,
    };
    post(api, payloads)
      .then((res) => {
        res.data.data.forEach((x) => {
          formData[x.name] = x.value;
        });
        updateFormData({}, formData);
      })
      .catch(console.log);
  };

  const getResponse = (res) => {
    if (res.data.id) {
      dispatch({
        type: "SET_STATUS",
        payload: {
          message: "Your Email Updated successfully",
          code: "",
          display: "show",
          severity: "success",
        },
      });
    } else {
      dispatch({
        type: "SET_STATUS",
        payload: {
          message: "Email update failed",
          code: "",
          display: "show",
          severity: "error",
        },
      });
    }
  };

  return (
    <Grid container spacing={0} justify="center">
      <Grid item xs={12} md={9} spacing={10}>
        <StyledFormWidget
          options={contactOptions}
          updateFormData={updateFormData}
          successResponse={getResponse}
        />
      </Grid>
    </Grid>
  );
}

export default EmailUpdate;
