//Import React Library
import React, { useState } from 'react';

import { settings } from "constants/settings"

async function loadComponent() {
    return import('./MultipleSelect.' + settings.UI + '.js')
}

function MultiDropComponent(props) {

    const { children, getProps } = props;

    const [component, setComponent] = useState(null)

    loadComponent().then(x => setComponent(x))

    if (!component) return null;
    return (
        <component.default getProps={getProps}>{children}</component.default>
    )
}

export default MultiDropComponent;