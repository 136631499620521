import { post } from "app/services/api";
import { config } from "utilities/config";

export const CreaterequestPayload = (JsonData) => {
  const details = JsonData.data;
  return {
    recordtype: "ctpt_cases",
    data: [
      {
        name: "ctpt_CaseTypeId",
        value: details[0].value,
        type: "lookup",
        relatedentity: "ctpt_casetypes",
      },
      {
        name: "ctpt_title",
        value: details[1].value,
        type: "string",
      },
      {
        name: "ctpt_details",
        value: details[2].value,
        type: "string",
      },
      {
        name: "ctpt_documenturl",
        value: JsonData.docUrl,
        type: "string",
      },
      {
        name: "ctpt_CustomerId_contact",
        value: JsonData.contactid,
        type: "lookup",
        relatedentity: "contacts",
      },
    ],
  };
  //return payload
};

export const CreatedocumentPayload = {
  recordtype: "ctpt_supportingdocuments",
  data: [
    {
      name: "ctpt_name",
      value: "",
      type: "string",
    },
    {
      name: "ctpt_documenttype",
      value: 100000001,
      type: "picklist",
    },
    {
      name: "ctpt_case",
      value: "",
      type: "lookup",
      relatedentity: "ctpt_cases",
    },
    {
      name: "ctpt_documenturl",
      value: "",
      type: "string",
    },
  ],
};

const getCaseDetailPayload = (caseID) => ({
  properties: ["ctpt_caseid", "ctpt_casenumber"],
  formattedFields: [],
  recordType: "ctpt_cases",
  id: caseID,
});

export const CreatedocPayload = (contactName, caseId, docUrl, JsonData) => {
  return post(config.teleportal.submitForm.getId, getCaseDetailPayload(caseId))
    .then((res) => {
      if (res.data) {
        const caseNum = res.data?.data?.find(
          (obj) => obj.name === "ctpt_casenumber"
        )?.value;

        const caseTypeMap = JSON.parse(
          localStorage.getItem("dynamicPickList") || "{}"
        );
        const caseType =
          Object.keys(caseTypeMap)?.length > 0
            ? caseTypeMap[JsonData.data?.[0]?.value] || ""
            : "";

        return {
          recordtype: "ctpt_supportingdocuments",
          data: [
            {
              name: "ctpt_name",
              value: caseNum
                ? `SD-${caseNum?.toString()?.trim()}${
                    caseType ? "-" + caseType : ""
                  }`
                : contactName,
              type: "string",
            },
            {
              name: "ctpt_documenttype",
              value: 100000001,
              type: "picklist",
            },
            {
              name: "ctpt_case",
              value: caseId,
              type: "lookup",
              relatedentity: "ctpt_cases",
            },
            {
              name: "ctpt_documenturl",
              value: docUrl,
              type: "string",
            },
          ],
        };
      }
    })
    .catch((err) => {
      console.log("Error fetching case details", err);
      return {};
    });
};

export const createDocAttachmentPayload = (base64Doc, supportingDocRes) => {
  if (!(base64Doc?.data && base64Doc?.fileName && supportingDocRes?.id)) {
    return null;
  }
  return {
    recordtype: "annotations",
    data: [
      {
        name: "subject",
        value: "Case supporting doc attachment",
        type: "string",
      },
      {
        name: "filename",
        value: base64Doc.fileName,
        type: "string",
      },
      {
        name: "documentbody",
        value: base64Doc.data,
        type: "string",
      },
      {
        name: "objectid_ctpt_supportingdocument",
        value: supportingDocRes.id,
        type: "lookup",
        relatedentity: "ctpt_supportingdocuments",
      },
      {
        name: "mimetype",
        value: base64Doc.fileType,
        type: "string",
      },
    ],
  };
};
