export const ContactPayload = (JsonData) => {
  const details = JsonData.data;
  var payload;
  payload = {
    id: JsonData.id,
    recordtype: JsonData.recordtype,
    data: [
      {
        name: "emailaddress1",
        value: details[0].value,
        type: "string",
      },
    ],
  };
  return payload;
};

export const getSupportingDocPayload = (contactID) => ({
  properties: [
    "ctpt_name",
    "ctpt_supportingdocumentid",
    "ctpt_documenturl",
    "ctpt_documenttype",
    "ctpt_documentdate",
    "ctpt_description",
    "ctpt_type",
  ],
  formattedFields: [],
  filter: {
    globalfilter: "",
    globalAndOperator: false,
    fieldAndOperator: true,
    filterfields: [
      {
        field: "ctpt_type",
        searchvalue: 2,
        type: "picklist",
      },
      {
        field: "_ctpt_customer_value",
        searchvalue: contactID,
        type: "guid",
      },
    ],
  },
  Aggregate: {
    AggregateFields: [],
  },
  recordtype: "ctpt_supportingdocuments",
  idfield: "ctpt_supportingdocumentid",
  continuationToken: "",
  pazesize: "10",
});

export const uploadSupportingDocPayload = (docURL, docName, contactID) => ({
  recordtype: "ctpt_supportingdocuments",
  data: [
    {
      name: "ctpt_name",
      value: "SD-CV-" + (docName || "File"),
      type: "string",
    },
    {
      name: "ctpt_documenttype",
      value: 100000001,
      type: "picklist",
    },
    {
      name: "ctpt_type",
      value: 2,
      type: "picklist",
    },
    {
      name: "ctpt_customer",
      value: contactID,
      type: "lookup",
      relatedentity: "contacts",
    },
    {
      name: "ctpt_CustomerId_contact",
      value: contactID,
      type: "lookup",
      relatedentity: "contacts",
    },
    {
      name: "ctpt_documenturl",
      value: docURL,
      type: "string",
    },
  ],
});

export const updateSupportingDocPayload = (docID, docName, docURL) => {
  return {
    id: docID,
    recordtype: "ctpt_supportingdocuments",
    data: [
      {
        name: "ctpt_name",
        value: docName || "file",
        type: "string",
      },
      {
        name: "ctpt_documenturl",
        value: docURL,
        type: "string",
      },
    ],
  };
};

export const deleteSupportingDocPayload = (docID) => ({
  recordtype: "ctpt_supportingdocuments",
  id: docID,
});

export const getqualificationlistPayload = (getcontactId) => ({
  properties: [
    "mshied_name",
    "_mshied_studentid_value",
    "_mshied_schoolnameid_value",
    "ctpt_startdate",
    "ctpt_enddate",
    "_mshied_educationlevelid_value",
    "ctpt_grade",
    //"ctpt_level",
    "ctpt_type",
    "ctpt_yearofaward",
    "mshied_previouseducationid"
  ],
  formattedFields: [],
  recordType: "mshied_previouseducations",
  // id: contactid,
  // filters: [
  //   {
  //     field: "_mshied_studentid_value",
  //     searchvalue: contactid,
  //     type: "guid"
  //   }
  // ],
  filter: {
    globalfilter: "",
    globalAndOperator: false,
    fieldAndOperator: false,
    filterfields: [{
      field: "_mshied_studentid_value",
      searchvalue: getcontactId,
      type: "guid"
    }
    ]
  },
  Aggregate: {
    AggregateFields: [
    ]
  },
  "idfield": "mshied_previouseducationid",
  //FieldAndOperator: "true",
  // idfield: "contactid",
  continuationToken: "",
  pagesize: "10"
});

export const getQualificationDetailsPayload = {
  properties: [
    "mshied_name",
    "ctpt_schoolname",
    "ctpt_startdate",
    "ctpt_enddate",
    "_mshied_educationlevelid_value",
    "ctpt_grade",
    //"ctpt_level",
    "ctpt_type",
    //"ctpt_yearofaward",
    "mshied_graduationdate",
    "mshied_previouseducationid"
  ],
  formattedFields: ["_mshied_schoolnameid_value","_mshied_educationlevelid_value"],
  recordType: "mshied_previouseducations",
  // id: contactid,

  FieldAndOperator: "true",
  idfield: "contactid"

};

export const qualificationDetailsPayload = (JsonData) => {
  const details = JsonData.data;
  var payload;
  payload = {
    id: JsonData.recordId,
    recordtype: JsonData.recordtype,
    data: [
      {
        name: "mshied_name",
        value: details[0].value,
        type: "string",
      },
      {
        name: "ctpt_schoolname",
        value: details[1].value,
        type: "string"
      },
      {
        name: "ctpt_startdate",
        value: details[2].value,
        type: "string",
      },
      {
        name: "ctpt_enddate",
        value: details[3].value,
        type: "string",
      },
      {
        name: "mshied_EducationLevelId",
        value: details[4].value,
        type: "lookup",
        relatedentity: "mshied_educationlevels",
      },
      {
        name: "ctpt_grade",
        value: details[5].value,
        type: "string",
      },
      // {
      //   name: "ctpt_level",
      //   value: details[6].value,
      //   type: "string",
      // },
      {
        name: "ctpt_type",
        value: details[6].value,
        type: "picklist",
      },
      {
        name: "mshied_graduationdate",
        value: details[7].value ? new Date(details[7].value).toISOString().substring(0, 10): details[7].value,
        type: "string",
      },
    ],
  };
  return payload;
};

export const addNewQualificationDetailsPayload = (contactID) => (JsonData) => {
  const details = JsonData.data;
  var payload;
  payload = {
    id: JsonData.recordId,
    recordtype: JsonData.recordtype,
    data: [
      {
        name: "mshied_StudentId",
        value: contactID,
        type: "lookup",
        relatedentity: "contacts",
      },
      {
        name: "mshied_name",
        value: details[0].value,
        type: "string",
      },
      {
        name: "ctpt_schoolname",
        value: details[1].value,
        type: "string"
      },
      {
        name: "ctpt_startdate",
        value: details[2].value,
        type: "string",
      },
      {
        name: "ctpt_enddate",
        value: details[3].value,
        type: "string",
      },
      {
        name: "mshied_EducationLevelId",
        value: details[4].value,
        type: "lookup",
        relatedentity: "mshied_educationlevels",
      },
      {
        name: "ctpt_grade",
        value: details[5].value,
        type: "string",
      },
      // {
      //   name: "ctpt_level",
      //   value: details[6].value,
      //   type: "string",
      // },
      {
        name: "ctpt_type",
        value: details[6].value,
        type: "picklist",
      },
      {
        name: "mshied_graduationdate",
        value: details[7].value ? new Date(details[7].value).toISOString().substring(0, 10): details[7].value,
        type: "string",
      },
    ],
  };
  return payload;
};

export const getemploymentlistPayload = (getcontactId) => ({
  Parameters: {
    ctpt_GetEmploymentHistories_ContactId: getcontactId
  },
  functionName: "ctpt_GetEmploymentHistories(ctpt_GetEmploymentHistories_ContactId=@ctpt_GetEmploymentHistories_ContactId)",
});

export const getEmploymentDetailsPayload = {
  properties: [
    "ctpt_typeofemployment",
    "ctpt_startdate",
    "ctpt_enddate",
    "ctpt_jobtitle",
    "ctpt_jobdescription",
    //"ctpt_businessnature",
    "ctpt_professionalhistoryid",
    "ctpt_professionalbody"
  ],
  formattedFields: ["_ctpt_employeeid_value","_ctpt_employerid_value"],
  recordType: "ctpt_professionalhistories",
  // id: contactid,

  FieldAndOperator: "true",
  idfield: "contactid"

};

export const employmentDetailsPayload = (JsonData) => {
  const details = JsonData.data;
  var payload;
  payload = {
    id: JsonData.recordId,
    recordtype: JsonData.recordtype,
    data: [
      {
        name: "ctpt_professionalbody",
        value: details[0].value,
        type: "string",
      },
      {
        name: "ctpt_jobtitle",
        value: details[1].value,
        type: "string",
      },
      {
        name: "ctpt_jobdescription",
        value: details[2].value,
        type: "string",
      },
      
      {
        name: "ctpt_typeofemployment",
        value: details[3].value,
        type: "string",
      },
      {
        name: "ctpt_startdate",
        value: details[4].value,
        type: "string",
      },
      {
        name: "ctpt_enddate",
        value: details[5].value,
        type: "string",
      }
      // {
      //   name: "ctpt_businessnature",
      //   value: details[6].value,
      //   type: "string",
      // },
      
    ],
  };
  return payload;
};

export const addNewEmploymentDetailsPayload = (contactID) => (JsonData) => {
  const details = JsonData.data;
  var payload;
  payload = {
    id: JsonData.recordId,
    recordtype: JsonData.recordtype,
    data: [
      {
        name: "ctpt_EmployeeId",
        value: contactID,
        type: "lookup",
        relatedentity: "contacts",
      },
      {
        name: "ctpt_professionalbody",
        value: details[0].value,
        type: "string",
      },
      {
        name: "ctpt_jobtitle",
        value: details[1].value,
        type: "string",
      },
      {
        name: "ctpt_jobdescription",
        value: details[2].value,
        type: "string",
      },
      {
        name: "ctpt_typeofemployment",
        value: details[3].value,
        type: "string",
      },
      {
        name: "ctpt_startdate",
        value: details[4].value,
        type: "string",
      },
      {
        name: "ctpt_enddate",
        value: details[5].value,
        type: "string",
      }
      
    ],
  };
  return payload;
};

export const getprofessionallistPayload = (getcontactId) => ({
  properties: [
    // "ctpt_typeofemployment",
    // "ctpt_startdate",
    // "ctpt_enddate",
    // "ctpt_jobtitle",
    // "ctpt_jobdescription",
    // "ctpt_businessnature",
    "ctpt_professionalbody",
    "ctpt_professionalhistoryid"
  ],
  formattedFields: ["_ctpt_employeeid_value"],
  recordType: "ctpt_professionalhistories",
  // id: contactid,
  // filters: [
  //   {
  //     field: "_mshied_studentid_value",
  //     searchvalue: contactid,
  //     type: "guid"
  //   }
  // ],
  filter: {
    globalfilter: "",
    globalAndOperator: false,
    fieldAndOperator: true,
    filterfields: [{
      field: "_ctpt_employeeid_value",
      searchvalue: getcontactId,
      type: "guid"
    },
    {
      field: "ctpt_typeofemployment",
      searchvalue: "533910004",
      type: "guid"
    }
    ]
  },
  Aggregate: {
    AggregateFields: [
    ]
  },
  "idfield": "ctpt_professionalhistoryid",
  //FieldAndOperator: "true",
  // idfield: "contactid",
  continuationToken: "",
  pagesize: "10"
});

export const getProfessionalDetailsPayload = {
  properties: [
    "ctpt_startdate",
    "ctpt_enddate",
    "ctpt_jobtitle",
    "ctpt_jobdescription",
    "ctpt_professionalbody",
    "ctpt_professionalhistoryid"
  ],
  formattedFields: ["_ctpt_employeeid_value"],
  recordType: "ctpt_professionalhistories",
  FieldAndOperator: "true",
  idfield: "contactid"

};

export const professionalDetailsPayload = (JsonData) => {
  const details = JsonData.data;
  var payload;
  payload = {
    id: JsonData.recordId,
    recordtype: JsonData.recordtype,
    data: [
      {
        name: "ctpt_professionalbody",
        value: details[0].value,
        type: "string",
      },
      {
        name: "ctpt_jobtitle",
        value: details[1].value,
        type: "string",
      },
      {
        name: "ctpt_jobdescription",
        value: details[2].value,
        type: "string",
      },
      {
        name: "ctpt_startdate",
        value: details[3].value,
        type: "string",
      },
      {
        name: "ctpt_enddate",
        value: details[4].value,
        type: "string",
      }
    ],
  };
  return payload;
};

export const addNewProfessionalDetailsPayload = (contactID) => (JsonData) => {
  const details = JsonData.data;
  var payload;
  payload = {
    id: JsonData.recordId,
    recordtype: JsonData.recordtype,
    data: [
      {
        name: "ctpt_EmployeeId",
        value: contactID,
        type: "lookup",
        relatedentity: "contacts",
      },
      
      {
        name: "ctpt_typeofemployment",
        value: "533910004",
        type: "string",
      },
      {
        name: "ctpt_professionalbody",
        value: details[0].value,
        type: "string",
      },
      {
        name: "ctpt_jobtitle",
        value: details[1].value,
        type: "string",
      },
      {
        name: "ctpt_jobdescription",
        value: details[2].value,
        type: "string",
      },
      {
        name: "ctpt_startdate",
        value: details[3].value,
        type: "string",
      },
      {
        name: "ctpt_enddate",
        value: details[4].value,
        type: "string",
      }
      
    ],
  };
  return payload;
};