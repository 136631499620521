import React from "react";
import PropTypes from "prop-types";
import styled, { css } from "styled-components";
import Box from "../LayoutBox";
import { globalSizes, justifyMap, alignMap } from "../../theme/units";

const Container = styled(Box)(
  (props) => css`
    display: flex;
    flex-direction: ${props.reverse ? "row-reverse" : "row"};
    flex-wrap: nowrap;
    justify-content: ${justifyMap[props.justify]};
    align-items: ${alignMap[props.align]};

    > * + * {
      margin-left: ${props.reverse ? 0 : globalSizes[props.gap]};
      margin-right: ${props.reverse ? globalSizes[props.gap] : 0};
    }
  `
);

/**
 * ### Layout primitive component
 *
 * The **Row** is a container component that displays its children along a single (horizontal) dimension.
 *
 * `Row`s **DO NOT WRAP** - if you want a row to wrap, then you want a `Cluster`
 *
 * The `align` and `justify` props can be used to position child contents relative to the **Row** direction.
 */

const Row = React.forwardRef(function Row(
  {
    children,
    align = "stretch",
    className,
    element = "div",
    gap = "none",
    height = "auto",
    justify = "start",
    padding = "none",
    reverse = false,
    width = "auto",
    ...rest
  },
  ref
) {
  return (
    <Container
      align={align}
      className={className}
      element={element}
      gap={gap}
      height={height}
      justify={justify}
      padding={padding}
      reverse={reverse}
      width={width}
      ref={ref}
      {...rest}
    >
      {children}
    </Container>
  );
});

Row.propTypes = {
  /** Any components to subscribe to the flex styling set by Row. */
  children: PropTypes.node,
  /** Will align the contents on the vertical axis.
   *
   *  choose from : [  start | end |center | stretch ] */
  align: PropTypes.oneOf(Object.keys(alignMap)),
  /** To be used to override or extend the styles on this component. */
  className: PropTypes.string,
  /** Defines the type of container element rendered to the dom. */
  element: PropTypes.string,
  /** gap between elements should take one of our globalSize constants
   *
   *  choose from : [ none | smallest | tiny | xxs | xs | small | base | medium | large | xl | xxl | largest ] */
  gap: PropTypes.oneOf(Object.keys(globalSizes)),
  /** height of the Row - arbitrary (Rems / px / %) */
  height: PropTypes.string,
  /** Will justify the contents along the axis of the direction.
   *
   * choose from : [  start | end | center  | between | around | evenly ]*/
  justify: PropTypes.oneOf(Object.keys(justifyMap)),
  /** the padding of the Row should take one of our globalSize constants
   *
   *  choose from : [ none | smallest | tiny | xxs | xs | small | base | medium | large | xl | xxl | largest ] */
  padding: PropTypes.oneOf(Object.keys(globalSizes)),
  /** reverse the direction of the Row's contents */
  reverse: PropTypes.bool,
  /** width of the Row - arbitrary (Rems / px / %) */
  width: PropTypes.string,
};

export default Row;
