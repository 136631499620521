//Import React Library
import React, { useEffect, useState, useContext } from "react";
import { useSelector, useDispatch } from "react-redux";
import { AuthenticatedTemplate } from "@azure/msal-react";

//Import Material-UI Library
import { ThemeProvider } from "@material-ui/core/styles";

//Loading Data and Style..
import { globalTheme } from "style/globalTheme";
import { useStyles } from "./style";
//Loading components
import { Button } from "@material-ui/core";
import { config } from "utilities/config";
import TabWidget from "app/components/molecules/tab/TabWidget.mui";
import PersonalInformation from "./personalinformation";
import ContactInformation from "./contactinformation";
import IdentityInformation from "./identityinformation";


function AboutYou(props) {
  //const userEntitlement = useContext(UserContext)
  const userEntitlement = useSelector((state) => state.entitleMents);
  const currencySymbols = useSelector((state) => state.currency);
  const permissions = userEntitlement.find((x) => x.route == props.match.path);
  const classes = useStyles();
  // const role = useSelector((state) => state.role);
  // const getcontactId = useSelector((state) => state.contactid);
  // const dispatch = useDispatch();
  // const [payDetails, setpayDetails] = useState(null);
  // const downloadFunc = React.useRef(null);

  

 

 

  return (
    <ThemeProvider theme={globalTheme}>
      <AuthenticatedTemplate>
        <div class={classes.background1}></div>
        <div id="cTTP_applicationList" class={classes.container}>
          <TabWidget
            options={{
              defaultTabID: 1,
              tabNavJson: [
                {
                  label: "Personal Information",
                  id: 1,
                  entity: (
                    <div class={classes.listSection}>
                      <PersonalInformation permissions={permissions} />
                    </div>
                  ),
                },
                {
                  label: "Contact Information",
                  id: 2,
                  entity: (
                    <div class={classes.paidInvoiceList}>
                      <ContactInformation permissions={permissions}/>
                    </div>
                  ),
                },
                {
                  label: "Identity Information",
                  id: 3,
                  entity: (
                    <div class={classes.paidInvoiceList}>
                      <IdentityInformation permissions={permissions}/>
                    </div>
                  ),
                },
              ],
            }}
          />
        </div>
      </AuthenticatedTemplate>
    </ThemeProvider>
  );
}

export default AboutYou;
