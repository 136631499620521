export default function break_address(url_add,pos) 
{ 
var data = url_add.split("://");
data = data[1].split("/"); 
return data[pos];
}

export function break_addressbar(url_add) 
{ 
  console.log("url_add--"+url_add)
  let urlParam={
    formName:'',
    subId:''
  }
  let fName = url_add.substring(
    url_add.indexOf("/") + 1, 
    url_add.lastIndexOf("?")
).split("/");
urlParam['formName']=fName[3]

let sId = url_add.substring(url_add.indexOf("?") + 1,url_add.length)
urlParam['subId']=sId
console.log("split url--"+JSON.stringify(urlParam))
return urlParam;
}