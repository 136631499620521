import { postSwf, post } from "app/services/api";
import { config } from "utilities/config";

export const getSWFID = async (swfName) => {
  try {
    const response = await postSwf(config.sonicwebform.getFormschema, {
      WebFormName: swfName,
    });
    return response.data?.id;
  } catch (err) {
    console.log("error", err);
    return "";
  }
};

export const getSWFSubmissionID = async (orderID) => {
  try {
    const response = await post(config.teleportal.submitForm.getId, {
      properties: ["_ctpt_sonicwebformsubmission_value"],
      recordType: "salesorders",
      id: orderID,
    });
    return response.data?.data?.[0]?.value;
  } catch (err) {
    console.log("error", err);
    return "";
  }
};

export const showMembershipFeeHeading = (payDetails) => {
  if (
    (payDetails &&
      payDetails.products[0] &&
      payDetails.products[0].code &&
      payDetails.products[0].code.includes("FIRM") == true) ||
    (payDetails &&
      payDetails.products[1] &&
      payDetails.products[1].code &&
      payDetails.products[1].code.includes("IND") == true)
  ) {
    return <h4>Membership Fees</h4>;
  } else {
    return null;
  }
};
