export const getrecordPayload =
{
  "properties": [
      "ctpt_caseid",
      "ctpt_casenumber",
      "ctpt_title",
      "ctpt_details",
      "createdon",
       "statuscode", 
       "modifiedon" , 
       "statecode",
       "ctpt_caseresolutiondetails",
       "ctpt_documenturl"
  ],
  "formattedFields": [],
"recordType": "ctpt_cases",
"id": ""

}

export const getdocPayload =
{
  "properties": [
      "ctpt_caseid",
      "ctpt_casenumber",
      "ctpt_title",
      "ctpt_details",
      "createdon",
       "statuscode", 
       "modifiedon" , 
       "statecode",
       "ctpt_caseresolutiondetails"
  ],
  "formattedFields": [],
"recordType": "ctpt_cases",
"id": ""

}


