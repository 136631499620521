export const postalAddressSchema = {
    title: "Edit Postal Address",
    logicalname: "Channel",
    type: "object",
    //required: ["name", "address", "primaryemail", "primarycontact"],
    properties: {
        ctpt_line1: {
            title: "Plot Number",
            type: "string",
            datatype: "string",
            requiredCheck: true,
        },
        ctpt_line2: {
            title: "Ward/Area",
            type: "string",
            datatype: "string",
            requiredCheck: true,
        },
        ctpt_city: {
            title: "City/Town/Village",
            type: "string",
            datatype: "string",
            requiredCheck: true,
          },
          ctpt_county: {
            title: "District",
            type: "string",
            datatype: "array",
            requiredCheck: true,
            enum: [
              "Central",
              "Ghanzi",
              "Kgalagadi",
              "Kgatleng",
              "Kweneng",
              "North East",
              "North West",
              "South East",
              "Chobe",
              "Ngamiland",
              "Southern",
              "Not Applicable"
            ],
            enumNames: [
              "Central",
              "Ghanzi",
              "Kgalagadi",
              "Kgatleng",
              "Kweneng",
              "North East",
              "North West",
              "South East",
              "Chobe",
              "Ngamiland",
              "Southern",
              "Not Applicable"
            ]
          },
          ctpt_postalcode: {
            title: "Postal Code",
            type: "string",
            datatype: "string",
          },
        ctpt_country: {
            title: "Country",
            type: "string",
            datatype: "string",
            requiredCheck: true,
        }
    }
};
