export const getlistPayload =
  // {
  //     "properties": [
  //         "ctpt_name",
  //         "_ctpt_sonicwebformid_value",
  //         "ctpt_submissionstatus",
  //         "ctpt_sonicwebformsubmissionid",
  //         "ctpt_submissiondate"
  //     ],
  //     "filter": {
  //         "globalfilter": "",
  //         "globalAndOperator": false,
  //         "fieldAndOperator": false,
  //         "filterfields": [
  //             {
  //                 "field": "_ctpt_contactid_value",
  //                 "searchvalue": "",
  //                 "type": "guid"
  //             }
  //         ]
  //     },
  //     "Aggregate": {
  //         "AggregateFields": []
  //     },
  //     "recordtype": "ctpt_sonicwebformsubmissions",
  //     "idfield": "ctpt_sonicwebformsubmissionid",
  //     "continuationToken": "",
  //     "pazesize": "10"
  // }

  {
    recordType: "ctpt_sonicwebformsubmissions",
  };
