export const searchPayload = {
    "properties": [
        "firstname",
        "lastname",
        "fullname"
    ],
    "filter": {
        "globalfilter": "",
        "globalAndOperator": false,
        "fieldAndOperator": true,
        "filterfields": [{
            "field": "lastname",
            "searchvalue": '',
            "type": "string"
        }
        ]
    },
    "Aggregate": {
        "AggregateFields":[]
    },
    "sortfield": {
        "field": "firstname",
        "sorttype": "asc"
    },
    "recordtype": "contacts",
    "idfield": "contactid",
    "continuationToken": "",
    "pazesize": "1000"
}

export const getlistPayload = {
            properties: ["_ctpt_nationalityid_value"],
            formattedFields: [
                "_ctpt_nationalityid_value"
            ],
            recordType: "ctpt_contactnationalities",
            // filters: [
            //   {
            //     field: "_ctpt_contactid_value",
            //     searchvalue: contactid,
            //     type: "guid"
            //   }
            // ],
            filter: {
                globalfilter: "",
                globalAndOperator: false,
                fieldAndOperator: false,
                filterfields: [
                    {
                        field: "_ctpt_contactid_value",
                        searchvalue: "contactid",
                        type: "guid"
                    }
                ]
            },
            Aggregate: {
                AggregateFields: []
            },
            idfield: "ctpt_contactnationalityid",
            continuationToken: "",
            pazesize: "10"
        };

export const deleteListPayload = {
    "recordtype": "ctpt_contactnationalities"
}

export const createRecordPayload = {
    "id": "",
    "recordtype": "contacts",
    "data": [
        {
            "name": "firstname",
            "value": "",
            "type": "string"
        },
        {
            "name": "lastname",
            "value": "",
            "type": "string"
        },
        {
            "name": "fullname",
            "value": "",
            "type": "string"
        },
        {
            "name": "gendercode",
            "value": '',
            "type": "picklist"
        },
        {
            "name": "donotemail",
            "value": '',
            "type": "boolean"
        },
        {
            "name": "valid_lookup",
            "value": " ",
            "type": "lookup",
            "relatedentity": "account"
        }
    ]
}

export const createContactPayload = {
    "recordtype": "contacts",
    "data": [
        {
            "name": "address1_name",
            "value": "",
            "type": "string"
        },
        {
            "name": "address1_line1",
            "value": "",
            "type": "string"
        },
        {
            "name": "emailaddress1",
            "value": "",
            "type": "string"
        },
        {
            "name": "address1_telephone1",
            "value": "",
            "type": "number"
        }
    ]
}

export const getcontactPayload = {
    "properties": [
        "emailaddress1",
        "address1_telephone1",
        "address1_city"
    ],
    "filter": {
        "globalfilter": "",
        "filterfields": [
            {
                "field": "emailaddress1",
                "searchvalue": "",
                "type": "string"
            }
        ]
    },
    "sortfield": {
        "field": "",
        "sorttype": "asc"
    },
    "recordtype": "contacts",
    "idfield": "",
    "continuationToken": "",
    "pagesize": "1000"
}

export const createAddressPayload = {
    "recordtype": "contacts",
    "data": [
        {
            "name": "ctpt_methodtype",
            "value": "727530000",
            "type": "string"
        },
        {
            "name": "emailaddress2",
            "value": "",
            "type": "string"
        },
        {
            "name": "address1_telephone2",
            "value": "",
            "type": "number"
        },
        {
            "name": "address1_line1",
            "value": "",
            "type": "string"
        },
        {
            "name": "address1_line2",
            "value": "",
            "type": "string"
        },
        {
            "name": "address1_line3",
            "value": "",
            "type": "string"
        },
        {
            "name": "address1_city",
            "value": '',
            "type": "string"
        },
        {
            "name": "address1_stateorprovince",
            "value": '',
            "type": "string"
        },
        {
            "name": "address1_postalcode",
            "value": '',
            "type": "number"
        },
        {
            "name": "address1_country",
            "value": '',
            "type": "string"
        },
        {
            "value": "",
            "type": "lookup",
            "relatedentity": "contacts"
        }
    ]
}

export const getRecordByIdPayload = {
    "properties": [
        "contactid"
    ],
    "recordType": "contacts",
    "idfield": "contactid",
    "id": ''
}

export const getAddressByIdPayload = {
    "properties": [
        "ctpt_city"
    ],
    "recordType": "ctpt_channels",
    "filters": [
        {
            "field": "_ctpt_customerid_value",
            "searchvalue": "",
            "type": "string"
        }
    ],
    "idfield": "ctpt_channelid"
}

export const getAddressByAllPayload = {
    "properties": [
        "ctpt_city",
        "ctpt_county",
        "ctpt_country"
    ],
    "filter": {
        "globalfilter": "",
        "filterfields": [
            {
                "field": "_ctpt_customerid_value",
                "searchvalue": "",
                "type": "guid"
            }
        ]
    },
    "sortfield": {
        "field": "ctpt_city",
        "sorttype": "asc"
    },
    "recordtype": "ctpt_channels",
    "idfield": "ctpt_channelid",
    "continuationToken": "",
    "pagesize": "1000"
}


export const searchAddressPayload = {
    "properties": [
        "ctpt_city",
        "ctpt_county",
        "ctpt_country"
    ],
    "filter": {
        "globalfilter": "",
        "filterfields": [{
            "field": "_ctpt_customerid_value",
            "searchvalue": '',
            "type": "guid"
        }
        ]
    },
    "sortfield": {
        "field": "ctpt_city",
        "sorttype": "asc"
    },
    "recordtype": "ctpt_channels",
    "idfield": "ctpt_channelid",
    "continuationToken": "",
    "pagesize": "1000"
}

export const deleteAddressPayload = {
    "recordtype": "ctpt_channels"
}

//Entitlement Payload
export const getContactEntitlePayload = {
    "contactid": ""
}

export const createFormsubmitpayload = {
    "submissionId": "",
    "formId": "",
    "contactId": '',
    "webFormName": '',
    "isSubmit": false,
    "isCancel": false,
    "data": {}
}

export const getFormsubmitpayload = {
    "submissionId": "",
    "WebFormName": ""
}

export const getFormschemapayload = {
    "WebFormName": ""
}